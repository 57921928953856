import './App.scss';
import { FluentProvider, Spinner, webLightTheme } from '@fluentui/react-components';
import { Navigate, Route, Router, Routes } from 'react-router-dom';
import Login from './_pages/Login/Login';
import Main from './_pages/Main/Main';
import { withTranslation } from 'react-i18next';
import { history } from './_helpers';
import { useEffect, useState } from 'react';
import ForgotPassword from './_pages/ForgotPassword/ForgotPassword';
import { userService } from './_services/user.services';
import CreatePassword from './_pages/CreatePassword/CreatePassword';
import ActivateAccount from './_pages/ActivateAccount/ActivateAccount';
import MainPartner from './_pages/_partner/Main/MainPartner';

function App(props:any) {

  const [currentURL, setCurrentURL] = useState(true)
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(!getStoredData("token"));

  var checkingToken = false;

  var _tokenSchedule = false;

  history.listen(() => {
    setCurrentURL(!currentURL)
  });

  const getUserData = () => {
    let pathname = window.location.pathname;
    if (pathname.includes('/partner')) localStorage.removeItem('token')
    if (!checkingToken && pathname !== '/forgot-password' && pathname !== '/set-password' && pathname !== '/activation-and-set-password' && pathname !== '/super/login') {
      checkingToken = true
      checkTokenExpirationDate(true).then(()=>{
        if (_tokenSchedule === false) setInterval(checkTokenExpirationDate,600000)
        _tokenSchedule = true;
      })
    } else {
      setLoading(false)
    }

  }

  async function checkTokenExpirationDate(firstTime = false) {
    console.log('Checking token expiration date')
    let tokenttl = Number(getStoredData("tokenttl") || 0);
    if (localStorage.PARTNER_TOKEN) {
      tokenttl = Number(getStoredData("PARTNER_TTL") || 0);
    }
    let currentDate = new Date().getTime();
        if ((tokenttl - currentDate) < (24*60*60*1000)) { 
            console.log('Trying to refresh token')
            userService.refreshToken(firstTime).then((response:any) => {
              checkingToken = false;
              if (firstTime) {
                var user = response.data
                setUserData(user)
                setLoading(false)
                if (window.location.pathname.includes("/login")) {
                  history.push('/company/inbox')
                }
              }
            }).catch(error => {
              console.log(error)
              checkingToken = false;
              setLoading(false)
            })
        } else {
          console.log("Token expiration date is valid")
          if (firstTime) {
            if (localStorage.PARTNER_TOKEN && localStorage.token) {
              userService.getUserDataPartner().then((response:any) => {
                checkingToken = false;
                var user = response.data
                setUserData(user)
                setLoading(false)
                if (window.location.pathname.includes("/login")) {
                  history.push('/partner/companies')
                }
              }).catch(error => {
                console.log(error)
                checkingToken = false;
                setLoading(false)
              })
            } else {
              userService.getUserData().then((response:any) => {
                checkingToken = false;
                var user = response.data
                setUserData(user)
                setLoading(false)
                if (window.location.pathname.includes("/login")) {
                  if (user.organization.type === 'PARTNER') {
                    history.push('/partner/companies')
                  } else {
                    history.push('/company/inbox')
                  }
                }
              }).catch(error => {
                console.log(error)
                checkingToken = false;
                setLoading(false)
              })
            }
          }
        }
  }

  useEffect(()=>{
    getUserData()
    // eslint-disable-next-line
  }, [])

  return (
    <FluentProvider style={{width: '100vw', height: '100vh'}} theme={webLightTheme}>
      {loading ? <div className='w-100 h-100 d-flex'><Spinner className='m-auto'/></div> : <Router location={history.location} navigator={history}>
        <Routes>
          <Route path="/company/inbox" element={<Main page="inbox" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/reports" element={<Main page="reports" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/reports/:id" element={<Main page="reports" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/dashboard" element={<Main page="dashboard" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/team" element={<Main page="team" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/activity" element={<Main page="activity" userData={userData} setUserData={setUserData}/>} />
          <Route path="/company/settings" element={<Main page="settings" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner/companies" element={<MainPartner page="companies" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner/dashboard" element={<MainPartner page="dashboard" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner/reports" element={<MainPartner page="reports" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner/team" element={<MainPartner page="team" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner/settings" element={<MainPartner page="settings" userData={userData} setUserData={setUserData}/>} />
          <Route path="/partner" element={<Navigate replace to="/partner/companies" />} />
          <Route path="/login" element={<Login getUserData={getUserData}/>} />
          <Route path="/forgot-password" element={<ForgotPassword/>} />
          <Route path="/set-password" element={<CreatePassword/>} />
          <Route path="/activation-and-set-password" element={<ActivateAccount/>} />
          <Route path="/" element={<Navigate replace to={getStoredData("PARTNER_TOKEN") ? "/partner/companies" : "/company/inbox"} />} />
          <Route path="/company" element={<Navigate replace to="/company/inbox" />} />
        </Routes>
      </Router>}
    </FluentProvider>
  );
}

const connectedApp = withTranslation()(App);
export { connectedApp as App }; 

const getStoredData = (name: string) => {
  return localStorage.getItem(name)
}