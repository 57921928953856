import { Link, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import './Titlebar.scss';
import i18n from "i18next";
import { GlobeRegular } from '@fluentui/react-icons';

function Titlebar() {

    const setLanguage = (lng:string) => {
        i18n.changeLanguage(lng)
    }

    const languages = {
        en: 'English',
        es: 'Español'
    }

    return (
        <div className="Titlebar d-flex">
            <div className="d-flex title align-items-center me-3" style={{minWidth: '25%'}}>
                <img src={ process.env.PUBLIC_URL + "/logo192.png" } className="img-fluid mx-3" alt="Synergy Home logo" />
                <span>
                <Link href='/' style={{textDecoration: 'none', color: 'white', fontWeight: 600}}>
                    Compliance Channel
                </Link>
                </span>
            </div>
            <div className='d-flex align-items-center ms-auto pe-2'>
                <Menu>
                    <MenuTrigger disableButtonEnhancement>
                    <MenuButton className='language-button' icon={<GlobeRegular/>} appearance='transparent'>{languages[i18n.language]}</MenuButton>
                    </MenuTrigger>

                    <MenuPopover>
                    <MenuList>
                        <MenuItem onClick={()=>{setLanguage('en')}}>English</MenuItem>
                        <MenuItem onClick={()=>{setLanguage('es')}}>Español</MenuItem>
                    </MenuList>
                    </MenuPopover>
                </Menu>
                <a className='d-flex align-items-center ms-3' href='https://www.synergyos.com'><img src="/img/synergy_logo_white.png" height="14px" alt="Synergy Inc. logo" /></a>
            </div>
        </div>
    );
}

export default Titlebar;