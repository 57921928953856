import { useEffect, useState } from 'react';
import { Selection, ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, Sticky, StickyPositionType, IColumn, ScrollablePane, ScrollbarVisibility } from '@fluentui/react';
import './Companies.scss';
import {
  Link,
  Persona,
    Spinner,
} from '@fluentui/react-components';
import i18n from "i18next";
import { history } from '../../../_helpers';
import { userService } from '../../../_services/user.services';

function Companies(props:any) {
  
  const [items, setItems] = useState(props.items)

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
      props.setSelection(_selection.getSelection())
    },
  });

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, items): void => {
    queryParams.set('sort',`${column.fieldName},${column.isSortedDescending ? 'ASC' : 'DESC'}`)
    history.push('?'+queryParams.toString())
  }

  const columns:any = (items, sort) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    let columns = [{
      key: 'sequentialNumber',
      name: '#',
      fieldName: 'sequentialNumber',
      minWidth: 24,
      maxWidth: 24,
      isRowHeader: true,
      data: 'string',
      isSorted: sortBy === 'sequentialNumber',
      isSortedDescending: sortBy === 'sequentialNumber' ? sortOrder === 'DESC' : undefined,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return <Persona className='' name={item.name} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
      }
    },
    {
      key: 'companyName',
      name: i18n.t('app:companyName'),
      fieldName: 'companyName',
      isSorted: sortBy === 'companyName' || !sort,
      isSortedDescending: sortBy === 'companyName' ? sortOrder === 'DESC' : undefined,
      minWidth: 150,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: any) => {
        return (
          <span title={ item.name }>{ item.name }</span>
        )
      }
    },
    {
      key: 'companyDomain',
      name: i18n.t('app:companyDomain'),
      fieldName: 'companyDomain',
      isSorted: sortBy === 'companyDomain',
      isSortedDescending: sortBy === 'companyDomain' ? sortOrder === 'DESC' : undefined,
      minWidth: 150,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <Link href={'https://'+item.domain} title={ item.domain }>{ item.domain }</Link>
        )
      }
    },
    {
      key: 'code',
      name: i18n.t('app:code'),
      fieldName: 'code',
      isSorted: sortBy === 'code',
      isSortedDescending: sortBy === 'code' ? sortOrder === 'DESC' : undefined,
      minWidth: 200,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span style={{userSelect: 'text'}} title={ item.code }>{ item.code }</span>
        )
      }
    },
    ]

    return columns
  }

  const _invokeItem = (item:any) => {
    const data = {
      organizationId: item.id
    }
    userService.exchangeToken(data).then((response)=>{
      localStorage.setItem('token', response.data.accessToken)
      userService.getUserDataPartner().then((response)=>{
        var user = response.data
        props.setUserData(user)
        history.push('/company/inbox')
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    setItems(props.items)
  }, [props.items])

  let queryParams = new URLSearchParams(window.location.search)
  let sort = queryParams.get('sort')

  return (
    <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)', position: 'relative'}}>
      <div style={{overflow: 'auto', height: 'calc(100%)', position: 'relative'}}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          className="reports"
          setKey="set" 
          items={[...items]} 
          columns={columns(items, sort)}
          onRenderDetailsHeader={onRenderDetailsHeader}
          compact={true}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          enterModalSelectionOnTouch={true}
          isHeaderVisible={true}
          constrainMode={ConstrainMode.unconstrained}
          selection={_selection}
          onItemInvoked={_invokeItem}
          onRenderMissingItem={()=>{props.loadMore(); return undefined}}
        />
      </ScrollablePane>
      { props.isLoading ? <Spinner className='m-auto mt-5' size='tiny'/>
      : items && items.length === 0 ? <div className='text-secondary mt-5 text-center w-100'>{i18n.t('app:noCompaniesFound')}</div>
      : null }
      </div>

    </div>
  );
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default Companies;