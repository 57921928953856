import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { Selection, ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, Sticky, StickyPositionType, Icon, ScrollablePane, ScrollbarVisibility, IColumn } from '@fluentui/react';
import { 
    AvatarGroup, 
    AvatarGroupItem, 
    AvatarGroupPopover, 
    Label, 
    Persona,
    Spinner,
    Tooltip, 
} from '@fluentui/react-components';
import i18n from "i18next";
import { history } from '../../../_helpers';
import Moment from 'moment';
import { Circle12Filled } from '@fluentui/react-icons';
import priorities from '../../../_constants/priorities.json'
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  Tooltip as TooltipChart
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line, Pie } from 'react-chartjs-2';
import { userService } from '../../../_services/user.services';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  TooltipChart
);

function Dashboard(props:any) {
  
  const [items, setItems] = useState(props.items)
  const [newItems, setNewItems] = useState([])
  const [dateStart, setDateStart] = useState<Date | null | undefined>(Moment(new Date()).subtract(6,'months').toDate())
  const [dateEnd, setDateEnd] = useState<Date | null | undefined>(new Date())

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
      props.setSelection(_selection.getSelection())
    },
  });

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, items): void => {
    queryParams.set('sort',`${column.fieldName},${column.isSortedDescending ? 'ASC' : 'DESC'}`)
    history.push('?'+queryParams.toString())
  }

  const columns:any = (items, sort) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    let columns = [{
      key: 'sequentialNumber',
      name: '#',
      fieldName: 'sequentialNumber',
      minWidth: 24,
      maxWidth: 24,
      isRowHeader: true,
      data: 'string',
      isSorted: sortBy === 'sequentialNumber',
      isSortedDescending: sortBy === 'sequentialNumber' ? sortOrder === 'DESC' : undefined,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return <span title={ item.id }>{ item.sequentialNumber }</span>
      }
    },
    {
      key: 'icon',
      name: <div><Icon className='me-2' {...getFileTypeIconProps({ extension: 'markdownasd', size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '40px', width: '24px', height: '40px'}}/></div>,
      fieldName: 'icon',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      data: 'string',
      onRender: (item: any) => {
        return <>
          <Icon className='me-2' {...getFileTypeIconProps({ extension: 'md', size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
          {item.newMessages > 0 && <div className='notification-circle my-auto' style={{position: 'absolute', right: '6px', bottom: '4px'}}><span className='m-auto'>{(item.newMessages || '')}</span></div>}
        </>
      }
    },
    {
      key: 'subject',
      name: i18n.t('app:subject'),
      fieldName: 'subject',
      minWidth: 100,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: any) => {
        return (<div className='d-flex'>
          {item.newMessages > 0 && <div className='notification-circle my-auto'><span className='m-auto'>{(item.newMessages || '')}</span></div>}
          <span title={ item.subject }>{ item.subject }</span>
        </div>
        )
      }
    },
    {
      key: 'company',
      name: i18n.t('app:company'),
      fieldName: 'company',
      minWidth: 100,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: any) => {
        return (
          <span title={ item.company }>{ item.company }</span>
        )
      }
    },
    {
      key: 'createdDateTime',
      name: i18n.t('app:creationDate'),
      fieldName: 'createdDateTime',
      isSorted: sortBy === 'createdDateTime' || !sort,
      isSortedDescending: sortBy === 'createdDateTime' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ Moment(item.createdDateTime).format('Y-MMM-D') }>{ Moment(item.createdDateTime).format('Y-MMM-D') }</span>
        )
      }
    },
    {
      key: 'dueDate',
      name: i18n.t('app:dueDate'),
      fieldName: 'dueDate',
      isSorted: sortBy === 'dueDate',
      isSortedDescending: sortBy === 'dueDate' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ item.dueDate ? Moment(item.dueDate).format('Y-MMM-D') : '' }>{ item.dueDate ? Moment(item.dueDate).format('Y-MMM-D') : '' }</span>
        )
      }
    },
    {
      key: 'importance',
      name: i18n.t('app:priority'),
      fieldName: 'importance',
      isSorted: sortBy === 'importance',
      isSortedDescending: sortBy === 'importance' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <div title={ item.importance ? i18n.t('app:'+item.importance) || '' : '' }>{ item.importance ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.importance], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.importance)}</div> : '' }</div>
        )
      }
    },
    {
      key: 'assignedTo',
      name: i18n.t('app:assignedTo'),
      fieldName: 'assignedTo',
      isSorted: sortBy === 'assignedTo',
      isSortedDescending: sortBy === 'assignedTo' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      //onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        let assignedInline:any[] = [];
        let assignedOverflow:any[] = [];

        if (item && item.assignedUsers) {
          let assigned: any[] = props.users.filter((user)=>{
            return item.assignedUsers.includes(user.id)
          });
          if (assigned.length > 4) {
            assignedInline = assigned.slice(0,4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
            assignedOverflow = assigned.slice(4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          } else {
            assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          }
        }

        return (
          <AvatarGroup layout="stack" size={20} style={{height: '20px'}}>
          {assignedInline.map((item, i) => (
            <Tooltip key={i} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='small' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
              <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')}/>
            </Tooltip>
          ))}
          { assignedOverflow && assignedOverflow.length > 0 && <AvatarGroupPopover>
            {assignedOverflow.map((item, i) => (
              <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} key={i} />
            ))}
          </AvatarGroupPopover> }
        </AvatarGroup>
        )
      },
    },
    {
      key: 'entityStatus',
      name: i18n.t('app:status'),
      fieldName: 'entityStatus',
      isSorted: sortBy === 'entityStatus',
      isSortedDescending: sortBy === 'entityStatus' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <div title={ item.entityStatus ? i18n.t('app:'+item.entityStatus) || '' : '' }>{ item.entityStatus ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.entityStatus], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.entityStatus)}</div> : '' }</div>
        )
      }
    },
    {
      key: 'modifiedDateTime',
      name: i18n.t('app:lastUpdated'),
      fieldName: 'modifiedDateTime',
      isSorted: sortBy === 'modifiedDateTime',
      isSortedDescending: sortBy === 'modifiedDateTime' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ Moment(item.modifiedDateTime).format('Y-MMM-D') }>{ Moment(item.modifiedDateTime).format('Y-MMM-D') }</span>
        )
      }
    }]
    let hasCategory = false;
    for (let i in items) {
      if (items[i].category && items[i].category.length > 0) {
        hasCategory = true;
      }
    }
    if (hasCategory) columns.splice(5, 0, {
      key: 'category',
      name: i18n.t('app:category') || '',
      fieldName: 'category',
      isSorted: sortBy === 'category',
      isSortedDescending: sortBy === 'category' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ item.category }>{ item.category }</span>
        )
      }
    })
    return columns
  }

  const _invokeItem = (item:any) => {
    const data = {
      organizationId: item.organizationId
    }
    userService.exchangeToken(data).then((response)=>{
      localStorage.setItem('token', response.data.accessToken)
      userService.getUserDataPartner().then((response)=>{
        var user = response.data
        props.setUserData(user)
        history.push('/company/reports/'+item.id)
      })
    }).catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    setItems(props.items)
    setNewItems(props.items.filter((item)=>{return item.entityStatus === 'NEW'}))
  }, [props.items])

  let queryParams = new URLSearchParams(window.location.search)
  let sort = queryParams.get('sort')

  let filteredItems = items.filter((item) => {
    return item.createdDateTime >= dateStart!.getTime() && item.createdDateTime <= dateEnd!.getTime()
  })

  var startDateMoment = Moment(dateStart);
  var endDateMoment = Moment(dateEnd);

  var labels:any[] = [];
  var months:any[] = [];
  if (endDateMoment.isBefore(startDateMoment)) {
      // eslint-disable-next-line
      throw "End date must be greated than start date."
  }

  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    months.push(startDateMoment.format("YYYY-MM"));
    startDateMoment.add(1, 'month').startOf('month');
  } 
  labels = months

  var newReportsList:any[] =  new Array(months.length).fill(0);
  var totalReportsList:any[] = new Array(months.length).fill(0);
  if (filteredItems && filteredItems.length > 0) {
    for (let j in months) {
      newReportsList[j] = filteredItems.filter((item)=>{return Moment(item.createdDateTime).format("YYYY-MM") === months[j]}).length
      totalReportsList[j] = filteredItems.filter((item)=>{return Moment(item.createdDateTime).format("YYYY-MM") === months[j] || Moment(item.createdDateTime).isSameOrBefore(months[j])}).length
    }
  }

  var statusList:any[] = []
  if (filteredItems && filteredItems.length > 0) {
    statusList = [
      filteredItems.filter((item=>{return item.entityStatus === 'NEW'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'OPEN'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'CLOSE'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'SPAM'})).length
    ]
  }

  var priorityList:any[] = []
  if (filteredItems && filteredItems.length > 0) {
    priorityList = [
      filteredItems.filter((item=>{return item.importance === 'URGENT'})).length,
      filteredItems.filter((item=>{return item.importance === 'HIGH'})).length,
      filteredItems.filter((item=>{return item.importance === 'MEDIUM'})).length,
      filteredItems.filter((item=>{return !item.importance})).length,
    ]
  }

  const options:any = {
    responsive: true,
    data: {
      labels,
      datasets: [
        {
          label: i18n.t('app:total'),
          data: totalReportsList,
          borderColor: '#0f6cbd',
          backgroundColor: '#0f6cbd',
        },
        {
          label: i18n.t('app:NEW'),
          data: newReportsList,
          borderColor: '#a6e9ed',
          backgroundColor: '#a6e9ed',
        },
      ],
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, i) => ({
              text: `${dataset.label} (${datasets[1].data.reduce((partialSum, a) => partialSum + a, 0)})`,
              fillStyle: dataset.backgroundColor,
              borderStyle: dataset.borderColor,
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:reportsPerMonth'),
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: "month"
        }
      },
      y: {
        ticks: {
          beginAtZero: true,
        }
      }
    }
  };

  const optionsPie:any = {
    responsive: true,
    aspectRatio: 1.6,
    data: {
      labels: [i18n.t('app:NEW'), i18n.t('app:OPEN'), i18n.t('app:CLOSE'), i18n.t('app:SPAM'), i18n.t('app:DELETE')],
      datasets: [
        {
          label: i18n.t('app:reports'),
          data: statusList,
          backgroundColor: [
            '#a6e9ed',
            '#bdd99b',
            '#294903',
            'grey',
            'firebrick'
          ],
          borderColor: [
            '#a6e9ed',
            '#bdd99b',
            '#294903',
            'grey',
            'firebrick'
          ],
          borderWidth: 1,
        },
      ],
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              borderStyle: datasets[0].backgroundColor[i],
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:status') || ''
      }
    }
  };

  const optionsPie2:any = {
    responsive: true,
    aspectRatio: 1.6,
    data: {
      labels: [i18n.t('app:URGENT'), i18n.t('app:HIGH'), i18n.t('app:MEDIUM'), i18n.t('app:none')],
      datasets: [
        {
          label: i18n.t('app:reports'),
          data: priorityList,
          backgroundColor: [
            '#d69ca5',
            '#f4bfab',
            '#f9e2ae',
            'lightgrey'
          ],
          borderColor: [
            '#d69ca5',
            '#f4bfab',
            '#f9e2ae',
            'lightgrey'
          ],
          borderWidth: 1,
        },
      ],
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              borderStyle: datasets[0].backgroundColor[i],
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:status') || ''
      }
    }
  };
  
  return (
    <div className='h-100 d-flex flex-column' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)'}}>
      <div className='d-flex mx-3 my-2 align-items-center'>
        <Label className='' weight='semibold'>{i18n.t('app:overview')}</Label>
        <DatePicker className='ms-auto' value={dateStart || undefined} onSelectDate={(date)=>{if (Moment(date).isBefore(Moment(dateEnd))) setDateStart(date)}}/>
        <DatePicker className='ms-3' value={dateEnd || undefined} onSelectDate={(date)=>{if (Moment(date).isAfter(Moment(dateStart))) setDateEnd(date)}}/>
      </div>
      <div className='d-flex w-100 mx-0' style={{height: 'calc(100% - 60px)', minHeight: '400px'}}>
        <div className='flex-grow-1 p-0 h-100' style={{maxWidth: '70%'}}>
          <div className='mx-3 h-100 d-flex justify-content-center flex-column' style={{border: '1px solid #f2f2f2', minHeight: '100%', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
            <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:reportsPerMonth')}</Label>
            <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
            <Line className='p-3' options={options} data={options.data} />
            </div>
          </div>
        </div>
        <div className='p-0 d-flex flex-column pe-3 h-100' style={{minWidth: '30%', maxWidth : '300px'}}>
          <div className='h-50 pb-2' style={{maxHeight: '50%'}}>
            <div className='h-100 d-flex flex-column' style={{border: '1px solid #f2f2f2', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
              <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:status')}</Label>
              <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
                <Pie className='p-3' options={optionsPie} data={optionsPie.data}/>
              </div>
            </div>
          </div>
          <div className='h-50 pt-2' style={{maxHeight: '50%'}}>
            <div className='h-100 d-flex flex-column' style={{border: '1px solid #f2f2f2', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
              <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:priority')}</Label>
              <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
                <Pie className='p-3' options={optionsPie2} data={optionsPie2.data}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Label className='p-3' weight='semibold'>{i18n.t('app:inbox')}</Label>
      <div className='flex-grow-1' style={{overflow: 'auto', minHeight: newItems.length === 0 ? '136px' : (40 * newItems.length + 56) + 'px', height: (43 * newItems.length + 46) + 'px', position: 'relative'}}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          className="reports"
          setKey="set" 
          items={newItems} 
          columns={columns(items, sort)}
          onRenderDetailsHeader={onRenderDetailsHeader}
          compact={true}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          enterModalSelectionOnTouch={true}
          isHeaderVisible={true}
          constrainMode={ConstrainMode.unconstrained}
          selection={_selection}
          onItemInvoked={_invokeItem}
          onRenderMissingItem={()=>{props.loadMore(); return undefined}}
        />
      </ScrollablePane>
      { props.isLoading ? <Spinner className='m-auto mt-5' size='tiny'/>
      : items && items.length === 0 ? <div className='text-secondary mt-5 text-center w-100'>{i18n.t('app:noResultsFound')}</div>
      : null }
      </div>
    </div>
  );
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default Dashboard;