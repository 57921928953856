import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import appEN from './locales/en/app.json';
import appES from './locales/es/app.json';

// the translations
const resources = {
  en: {
    app: appEN
  },
  es: {
    app: appES
  }
};

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    detection: {
      order: ["querystring", "localStorage"],
      lookupQuerystring: "lng",
      caches: ["localStorage"]
    },
    fallbackLng: "en", // use en if detected lng is not available
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;