import { DefaultButton, Modal} from '@fluentui/react';
import Titlebar from '../../_components/Titlebar/Titlebar';
import './ActivateAccount.scss';
import i18n from "i18next";
import { useState } from 'react';
import { Button, FluentProvider, Image, Input, Spinner, Text, webLightTheme} from '@fluentui/react-components';
import { history } from '../../_helpers';
import { userService } from '../../_services/user.services';

function ActivateAccount() {

    const [alertMessage, setAlertMessage]:any = useState();
    const [password, setPassword]:any = useState("");
    const [confirmPassword, setConfirmPassword]:any = useState("");
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const queryParams = new URLSearchParams(window.location.search)
    const token = queryParams.get('token') ?? ''
    const domain = queryParams.get('domain') ?? ''

    const _submit = (e:any) => {
        e.preventDefault();
        setLoading(true);
        setAlertMessage('')
        const data = {
            password: password,
            confirmPassword: confirmPassword
        }

        userService.activateUser(token, domain, data).then((response)=>{
            setLoading(false);
            setSuccess(true)
        }).catch((error)=>{
            setLoading(false);
            console.log(error)
            setAlertMessage(error?.data ?? error)
            console.error(error)
        })
    }

    return (
        <div>
            <Titlebar/>
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >
                <div className="d-flex title align-items-center">
                    <img src={ process.env.PUBLIC_URL + "/logo192.png" } width={32} className="img-fluid me-3" alt="Synergy Home logo" />
                    <span style={{fontWeight: 600, fontSize: 20}}>Compliance channel</span>
                </div>

                {!success && <p>
                    {i18n.t('app:accountAlmostReady')}
                </p>}
                <p className="lead mt-3">
                    {success ? i18n.t('app:success')+'!' : i18n.t('app:createAccount')}
                </p>


                { alertMessage &&
                    <div className={`alert my-3 alert-danger`}>
                        {alertMessage.status === 401 ?
                            "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                            : alertMessage.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                            : alertMessage === "Error: Network Error" ? "Server not found. Please try again."
                            : alertMessage.message === "Network Error" ? "Server not found. Please try again."
                            : alertMessage.message || "There was an error processing your request. Please, try again."}</div>
                }
                <FluentProvider theme={webLightTheme}>
                    {!success ? <form name="form" onSubmit={_submit}>
                        <Input type='password' placeholder={i18n.t('app:enterPassword') || 'Email'} name="password" value={password} onChange={(e,v)=>{setPassword(v.value)}} required autoFocus autoComplete="username" className="required-hidden mb-3 w-100" />
                        <Input type='password' placeholder={i18n.t('app:confirmPassword') || 'Email'} name="confirmpassword" value={confirmPassword} onChange={(e,v)=>{setConfirmPassword(v.value)}} required autoComplete="username" className="required-hidden mb-3 w-100" />
                        
                        <div className="form-group mt-2 mb-0" style={{textAlign: 'right'}}>
                            <Button className='me-2' onClick={()=>{history.push('/login')}}>{i18n.t('app:back')}</Button>
                            <Button appearance='primary' disabled={loading} type="submit">{loading ? <Spinner size='tiny'/> : i18n.t('app:next')}</Button>
                        </div>
                    </form>
                    :
                    <div className='d-flex flex-column'>
                        <Text className='mx-auto mb-4'>{i18n.t('app:successActivation')}</Text>
                        <Button className='mx-auto' appearance='primary' onClick={()=>{history.push('/login')}}>{i18n.t('app:backToSignInPage')}</Button>
                    </div>
                    }
                </FluentProvider>
            </Modal>
        </div>
    );
}

export default ActivateAccount;