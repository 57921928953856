import './DetailsPanel.scss';
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, Dropdown, Persona, SelectTabData, SelectTabEvent, Tab, 
    TabList, 
    TabValue, 
    Tooltip, 
    makeStyles,
    shorthands,
    Option,
    Combobox,
    ComboboxProps,
    useId
} from '@fluentui/react-components';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import {
    Circle12Filled,
    InfoFilled,
    InfoRegular,
    bundleIcon,
} from "@fluentui/react-icons";
import { useEffect, useRef, useState } from 'react';
import i18n from "i18next";
import { Resizable } from 're-resizable';
import Moment from 'moment';
import { userService } from '../../_services/user.services';
import priorities from '../../_constants/priorities.json'

const InfoIcon = bundleIcon(InfoFilled, InfoRegular);

const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      ...shorthands.padding("50px", "0px"),
      rowGap: "30px",
    },
    tab: {
        height: '48px',
        minWidth: '48px',
        //paddingRight: 0,
        paddingLeft: '14px'
    }
  });

function DetailsPanel(props:any) {

    const styles = useStyles();
    const [selectedValue, setSelectedValue] = useState<TabValue>('dashboard');
    const [openDetailsPanel, setOpenDetailsPanel] = useState(props.openDetailsPanel);
    const [panelWidth, setPanelWidth] = useState(localStorage.detailsPanelWidth || "300px");
    const [item, setItem]:any = useState(null);
    const [users, setUsers] = useState([]);
    const [assignedUsers, setAssignedUsers] = useState([]);

    const comboboxInputRef = useRef<HTMLInputElement>(null);

    const onOptionSelect: ComboboxProps["onOptionSelect"] = (event, data:any) => {
        setAssignedUsers(data.selectedOptions);
        _updateAssignees(data.selectedOptions);
    };

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        const value = data.value;
        setSelectedValue(value);
        if (selectedValue === value) {
            props.setOpenDetailsPanel(!openDetailsPanel)
            window.localStorage.setItem('detailsPanelStatus', !openDetailsPanel ? 'OPEN' : 'CLOSED');
        }
    };

    const _getAssignedUsers = (users, assignedUsers:any) => {
        let assignedInline:any[] = [];
        let assignedOverflow:any[] = [];

        if (users && assignedUsers) {
          let assigned: any[] = users.filter((user)=>{
            return assignedUsers.includes(user.id)
          });
          if (assigned.length > 4) {
            assignedInline = assigned.slice(0,4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
            assignedOverflow = assigned.slice(4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          } else {
            assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          }
        }

        return (
          <AvatarGroup layout="stack" size={24} style={{height: '24px'}}>
            <div className='me-1'>
                {assignedInline.map((item, i) => (
                    <Tooltip key={i} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='medium' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
                    <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')}/>
                    </Tooltip>
                ))}
            </div>
            { assignedOverflow && assignedOverflow.length > 0 && <AvatarGroupPopover>
                {assignedOverflow.map((item, i) => (
                <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} key={i} />
            ))}
          </AvatarGroupPopover> }
        </AvatarGroup>
        )
    }

    const _updateStatus = (data) => {
        const requestOptions = {
            importance: item.importance,
            entityStatus:  data.optionValue,
            dueDate: item.dueDate,
            assignedUsers: item.assignedUsers,
            customPropertyValues: item.customPropertyValues
        }

        if (data.entityStatus === 'DELETE') {
            item.entityStatus = 'DELETE'
            props.updateItem(item.id, item)
            return;
        }
        userService.updateReport(item.id, requestOptions).then((response)=>{
            props.updateItem(item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    const _updateImportance = (data) => {
        const requestOptions = {
            importance:  data.optionValue === '---' ? null : data.optionValue,
            entityStatus:  item.entityStatus,
            dueDate: item.dueDate,
            assignedUsers: item.assignedUsers,
            customPropertyValues: item.customPropertyValues
        }
        userService.updateReport(item.id, requestOptions).then((response)=>{
            props.updateItem(item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    const _updateAssignees = (data) => {
        const requestOptions = {
            importance:  item.importance,
            entityStatus:  item.entityStatus,
            dueDate: item.dueDate,
            assignedUsers: data,
            customPropertyValues: item.customPropertyValues
        }
        userService.updateReport(item.id, requestOptions).then((response)=>{
            props.updateItem(item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    const _updateDueDate = (date:Date) => {
        console.log(date)
        const requestOptions = {
            importance:  item.importance,
            entityStatus:  item.entityStatus,
            dueDate: date.getTime(),
            assignedUsers: item.assignedUsers,
            customPropertyValues: item.customPropertyValues
        }
        userService.updateReport(item.id, requestOptions).then((response)=>{
            props.updateItem(item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    useEffect(()=>{
        setOpenDetailsPanel(props.openDetailsPanel)
        setItem(props.item)
        if (props.item) setAssignedUsers(props.item.assignedUsers)
    }, [props.openDetailsPanel, props.item])

    useEffect(()=>{
        setUsers(props.users.filter((user)=>{return user.state === 'ACTIVE'}).map((user)=>{return {
            ...user,
            key: user.id
        }}))
    },[props.users])

    return (
        <div className='d-flex h-100' style={{maxHeight: 'calc(100vh - 136px)'}}>
        {openDetailsPanel && <Resizable
          size={{ width: panelWidth, height: 'auto' }}
          onResizeStop={(e, direction, ref, d) => {
            let currentWidth = panelWidth.match(/\d+/)[0];
            let newWidth = +currentWidth + +d.width + "px"
            setPanelWidth(newWidth)
            localStorage.setItem("detailsPanelWidth", newWidth)
          }}
          enable={{ top:false, right:false, bottom:false, left:true, topRight:false, bottomRight:false, bottomLeft:false, topLeft:false }}
          minWidth={240}
          maxWidth={440}
          handleStyles= {{
            left: {
              "left": "0px",
              "width": "6px"
            }
          }}
          handleClasses={{"left": "resize-handle"}}
        >
            <div className='DetailsPanel px-3' style={{minWidth: '200px', overflow: 'auto'}}>
            <div className="text-left sticky-top" style={{background: '#f2f2f2', borderBottom: '1px solid #bfbfbf', padding: '13px 0px 14px 0px'}}>
            <p className="lead font-weight-bold m-0">{i18n.t('app:info')}</p>
            </div>
            {item && <ul className='mt-3 p-0'>
                {item.id && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:ID')}:</div>
                        <div className="username font-weight-bold" style={{fontWeight: 600}}>{ item.id || "" }</div>
                    </div>
                </li>}
                {item.subject && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:subject')}:</div>
                        <div className="username font-weight-bold">{ item.subject || "" }</div>
                    </div>
                </li>}
                {item.category && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:category')}:</div>
                        <div className="username font-weight-bold">{ item.category || "" }</div>
                    </div>
                </li>}
                {item.createdDateTime && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:createdDate')}:</div>
                        <div className="username font-weight-bold">{ Moment(item.createdDateTime).format('Y-MMM-D') || "" }</div>
                    </div>
                </li>}
                {item.modifiedDateTime && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:lastUpdated')}:</div>
                        <div className="username font-weight-bold">{ Moment(item.modifiedDateTime).format('Y-MMM-D') || "" }</div>
                    </div>
                </li>}
                {item.description && <li className="mb-2 d-flex align-items-center">
                    <div>
                        <div className="text-secondary mr-2">{i18n.t('app:details')}:</div>
                        <div className="username font-weight-bold">{ item.description || "" }</div>
                    </div>
                </li>}
                {<li className="mb-2 d-flex align-items-center">
                    <div className='w-100'>
                        <div className="text-secondary mr-2">{i18n.t('app:status')}:</div>
                        <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' value={i18n.t('app:'+item.entityStatus) || 'Open'} selectedOptions={[item.entityStatus]} onOptionSelect={(e,data:any)=>{_updateStatus(data)}}>
                        <Option value={'NEW'} text={i18n.t('app:NEW') || 'NEW'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['NEW'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:NEW')}</div></Option>
                        <Option value={'OPEN'} text={i18n.t('app:OPEN') || 'OPEN'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['OPEN'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:OPEN')}</div></Option>
                        <Option value={'CLOSE'} text={i18n.t('app:CLOSE') || 'CLOSE'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:CLOSE')}</div></Option>
                        <Option value={'SPAM'} text={i18n.t('app:SPAM') || 'SPAM'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['SPAM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:SPAM')}</div></Option>
                        <Option value={'DELETE'} text={i18n.t('app:DELETE') || 'DELETE'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['DELETE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:DELETE')}</div></Option>
                        </Dropdown>
                    </div>
                </li>}
                {<li className="mb-2 d-flex align-items-center">
                    <div className='w-100'>
                        <div className="text-secondary mr-2">{i18n.t('app:dueDate')}:</div>
                        <DatePicker value={item.dueDate ? new Date(item.dueDate) : undefined} initialPickerDate={item.dueDate ? new Date(item.dueDate) : new Date()} onSelectDate={(data:any)=>{_updateDueDate(data)}} className='mt-1 w-100'/>
                    </div>
                </li>}
                {<li className="mb-2 d-flex align-items-center">
                    <div className='w-100'>
                        <div className="text-secondary mr-2">{i18n.t('app:priority')}:</div>
                        <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' value={item.importance ? i18n.t('app:'+item.importance) || '' : '---'} selectedOptions={[item.importance]} onOptionSelect={(e,data:any)=>{_updateImportance(data)}}>
                            <Option value={'---'}>{'---'}</Option>
                            <Option value={'URGENT'} text={i18n.t('app:URGENT') || 'URGENT'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['URGENT'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:URGENT')}</div></Option>
                            <Option value={'HIGH'} text={i18n.t('app:HIGH') || 'HIGH'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['HIGH'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:HIGH')}</div></Option>
                            <Option value={'MEDIUM'} text={i18n.t('app:MEDIUM') || 'MEDIUM'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['MEDIUM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:MEDIUM')}</div></Option>
                        </Dropdown>
                    </div>
                </li>}

                {users && <li className="mb-2 d-flex align-items-center">
                    <div className='w-100'>
                        <div className="text-secondary mr-2">{i18n.t('app:assignedTo')}:</div>
                       {assignedUsers && assignedUsers.length > 0 && <div className="mt-1">{ _getAssignedUsers(props.users, assignedUsers) }</div>}
                        <Combobox
                            style={{minWidth: '100px'}}
                            className='w-100 mt-1'
                            multiselect={true}
                            placeholder={i18n.t('app:selectUsers')}
                            selectedOptions={assignedUsers}
                            onOptionSelect={onOptionSelect}
                            ref={comboboxInputRef}
                            {...props}
                        >
                            {users.map((option:any) => (
                                <Option key={option.id} value={option.id} text={option.name + ' ' + option.surname}>
                                    <Persona
                                        style={{overflow: 'hidden'}}
                                        avatar={{ color: "colorful", "aria-hidden": true }}
                                        name={option.name + ' ' + option.surname}
                                        secondaryText={option.email}
                                        title={option.name + ' ' + option.surname + ' ' + option.email}
                                    />
                                </Option>
                            ))}
                        </Combobox>
                    </div>
                </li>}

            </ul>}
        </div>
        </Resizable>}
        <div className="DetailsPanel" style={{minWidth: 48, width: 48, overflow: 'hidden'}}>
            <TabList vertical selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab className={styles.tab} icon={<InfoIcon />} value="dashboard" aria-label="Dashboard"></Tab>
            </TabList>
        </div>
        </div>

    );
}

export default DetailsPanel;