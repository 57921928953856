import './Sidebar.scss';
import { Avatar, Menu, MenuButton, MenuItem, MenuList, MenuPopover, MenuTrigger, Persona, SelectTabData, SelectTabEvent, Tab, 
    TabList, 
    TabValue, 
    makeStyles,
    shorthands
} from '@fluentui/react-components';
import {
    bundleIcon,
    DocumentFilled,
    DocumentRegular,
    SettingsFilled,
    SettingsRegular,
    HomeFilled,
    HomeRegular,
    PeopleFilled,
    PeopleRegular,
    BroadActivityFeedFilled,
    BroadActivityFeedRegular,
    SignOutRegular,
    CityFilled,
    CityRegular,
    BoardFilled,
    BoardRegular,
    MailInboxFilled,
    MailInboxRegular,
} from "@fluentui/react-icons";
import { useEffect, useState } from 'react';
import { history } from '../../_helpers';
import i18n from "i18next";
import { userService } from '../../_services/user.services';
import { DefaultButton, Icon } from '@fluentui/react';

const InboxIcon = bundleIcon(MailInboxFilled, MailInboxRegular);
const DocumentIcon = bundleIcon(DocumentFilled, DocumentRegular);
const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
const PeopleIcon = bundleIcon(PeopleFilled, PeopleRegular);
const ActivityIcon = bundleIcon(BroadActivityFeedFilled, BroadActivityFeedRegular);
const CompanyIcon = bundleIcon(CityFilled, CityRegular);
const BoardIcon = bundleIcon(BoardFilled, BoardRegular);

const useStyles = makeStyles({
    root: {
      alignItems: "flex-start",
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      ...shorthands.padding("50px", "0px"),
      rowGap: "30px",
    },
    tab: {
        height: '48px',
        minWidth: '48px',
        //paddingRight: 0,
        paddingLeft: '14px'
    }
  });

function Sidebar(props:any) {

    const styles = useStyles();
    const [selectedValue, setSelectedValue] = useState<TabValue>(props.page);
    const [sidebarWidth, setSidebarWidth] = useState(Number(window.localStorage.sidebarWidth) || 48);

    const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
        const value = data.value;
        setSelectedValue(value);

        if (props.type === 'PARTNER') {
            if (value === 'reports') {
                history.push('/partner/reports')
            } else if (value === 'companies') {
                history.push('/partner/companies')
            } else if (value === 'team') {
                history.push('/partner/team')
            } else if (value === 'settings') {
                history.push('/partner/settings')
            }
        } else {
            if (value === 'reports') {
                history.push('/company/reports')
            } else if (value === 'dashboard') {
                history.push('/company/dashboard')
            } else if (value === 'inbox') {
                history.push('/company/inbox')
            } else if (value === 'team') {
                history.push('/company/team')
            } else if (value === 'activity') {
                history.push('/company/activity')
            } else if (value === 'settings') {
                history.push('/company/settings')
            }
        }
    };

    const goBackToPartner = () => {
        localStorage.removeItem('token');
        userService.getUserData(true).then((response)=>{
            var user = response.data
            props.setUserData(user)
            history.push('/partner')
        }).catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        setSidebarWidth(props.sidebarWidth)
    }, [props.sidebarWidth])

    useEffect(()=>{
        setSelectedValue(props.page)
    }, [props.page])

    return (
        <div className="Sidebar" style={{minWidth: sidebarWidth, width: sidebarWidth, overflowY: 'auto', overflowX: 'hidden', maxHeight: 'calc(100vh - 136px)'}}>
            {localStorage.token && localStorage.PARTNER_TOKEN && window.location.pathname.includes('/company') && 
            <div style={{ width: '100%', borderBottom: '1px solid rgba(0, 0, 0, 0.1'}}>
                {sidebarWidth !== 48 ? <>
                    <DefaultButton style={{paddingLeft: '12px'}} className="d-flex align-items-center my-2 hover-button w-100" onClick={goBackToPartner}  >
                        <Icon className='ms-1 me-2 pe-1' iconName='Back'/>
                        <span className="title mb-0" style={{fontSize: '16px', fontWeight: 700}}>{i18n.t('app:companies')}</span>
                    </DefaultButton>
                    <div className='align-items-center py-2' style={{padding: '4px 12px 0px 12px'}}>
                        <div>
                        <div className='d-flex align-items-center ms-4 pb-3'>
                            <div className='me-2' style={{background: 'white', borderRadius: '24px', height: '24px', width: '24px'}}>
                                <Persona className='project-persona' name={props.userData?.organization.name} size='small' primaryText='' textAlignment='center' avatar={{color: 'colorful'}}/>
                            </div>
                            <span className="m-0 pt-1" style={{fontSize: '14px', fontWeight: 600}}>{props.userData?.organization.name}</span>
                        </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <DefaultButton iconProps={{iconName: 'Back'}} style={{paddingLeft: '12px'}} styles={{icon: {fontSize: '14px'}}} className="d-flex my-2 hover-button " onClick={goBackToPartner}  >
                    </DefaultButton>
                </>}
            </div>}
            {props.type === 'PARTNER' ? <TabList vertical selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab className={styles.tab} icon={<CompanyIcon />} value="companies" aria-label="Companies">{sidebarWidth === 48 ? '' : i18n.t('app:companies')}</Tab>
                <Tab className={styles.tab} icon={<DocumentIcon />} value="reports" aria-label="Reports">{sidebarWidth === 48 ? '' : i18n.t('app:allReports')}</Tab>
                <Tab className={styles.tab} icon={<PeopleIcon />} value="team" aria-label="Team">{sidebarWidth === 48 ? '' : i18n.t('app:team')}</Tab>
                <Tab className={styles.tab} icon={<SettingsIcon />} value="settings" aria-label="Settings">{sidebarWidth === 48 ? '' : i18n.t('app:settings')}</Tab>
            </TabList>
            :
            <TabList vertical selectedValue={selectedValue} onTabSelect={onTabSelect}>
                <Tab className={styles.tab} icon={<InboxIcon />} value="inbox" aria-label="Inbox">{sidebarWidth === 48 ? '' : i18n.t('app:inbox')}</Tab>
                <Tab className={styles.tab} icon={<DocumentIcon />} value="reports" aria-label="Reports">{sidebarWidth === 48 ? '' : i18n.t('app:allReports')}</Tab>
                <Tab className={styles.tab} icon={<BoardIcon />} value="dashboard" aria-label="Dashboard">{sidebarWidth === 48 ? '' : i18n.t('app:dashboard')}</Tab>
                <Tab className={styles.tab} icon={<PeopleIcon />} value="team" aria-label="Team">{sidebarWidth === 48 ? '' : i18n.t('app:team')}</Tab>
                <Tab className={styles.tab} icon={<ActivityIcon />} value="activity" aria-label="activity">{sidebarWidth === 48 ? '' : i18n.t('app:activity')}</Tab>
                <Tab className={styles.tab} icon={<SettingsIcon />} value="settings" aria-label="Settings">{sidebarWidth === 48 ? '' : i18n.t('app:settings')}</Tab>
            </TabList>}
            <Menu>
                <MenuTrigger disableButtonEnhancement>
                    <MenuButton className='p-0 mt-auto' menuIcon={null} appearance='transparent'>            
                        <div className='w-100 d-flex align-items-center mt-auto' style={{marginLeft: '6px', height: '48px', overflow: 'hidden'}}>
                            <Avatar name={props.userData ? props.userData.user.name + ' ' + props.userData.user.surname : ''} color='anchor'/>
                            {sidebarWidth !== 48 && <span className='ms-2'>{props.userData ? props.userData.user.name + ' ' + props.userData.user.surname : ''}</span>}
                        </div>
                    </MenuButton>
                </MenuTrigger>

                <MenuPopover>
                    <MenuList>
                        <MenuItem icon={<SignOutRegular/>} onClick={()=>{userService.logout()}}>{i18n.t('app:signOut')}</MenuItem>
                    </MenuList>
                </MenuPopover>
            </Menu>
        </div>
    );
}

export default Sidebar;