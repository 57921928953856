import { Modal} from '@fluentui/react';
import Titlebar from '../../_components/Titlebar/Titlebar';
import './ForgotPassword.scss';
import i18n from "i18next";
import { useState } from 'react';
import { Button, FluentProvider, Input, Spinner, Text, webLightTheme} from '@fluentui/react-components';
import { history } from '../../_helpers';
import { userService } from '../../_services/user.services';

function ForgotPassword() {

  const [alertMessage, setAlertMessage]:any = useState();
  const [username, setUsername]:any = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const _submit = (e:any) => {
    e.preventDefault();
    setLoading(true);
    setAlertMessage(null)

    const queryParams = new URLSearchParams(window.location.search)
    const company = queryParams.get("company") ?? "synergyos.com";

    userService.resetPassword(username, company).then((response) => {
        setLoading(false);
        setSuccess(true)
    }).catch((error)=>{
        console.error(error)
        setLoading(false);
        setAlertMessage(error?.data ?? error)
    })
  }

    return (
        <div>
            <Titlebar/>
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >
                <div className="d-flex title align-items-center">
                    <img src={ process.env.PUBLIC_URL + "/logo192.png" } width={32} className="img-fluid me-3" alt="Synergy Home logo" />
                    <span style={{fontWeight: 600, fontSize: 20}}>Compliance channel</span>
                </div>

                <p className="lead mt-3">
                    {success ? i18n.t('app:success')+'!' : i18n.t('app:forgotPassword')}
                </p>


                { alertMessage &&
                    <div className={`alert my-3 alert-danger`}>
                        {alertMessage.status === 401 ?
                            "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                            : alertMessage.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                            : alertMessage === "Error: Network Error" ? "Server not found. Please try again."
                            : alertMessage.message === "Network Error" ? "Server not found. Please try again."
                            : alertMessage.message || "There was an error processing your request. Please, try again."}</div>
                }
                <FluentProvider theme={webLightTheme}>
                    {!success ? <form name="form" onSubmit={_submit}>
                        <Input type="email" placeholder={i18n.t('app:enterEmail') || 'Email'} name="username" value={username} onChange={(e,v)=>{setUsername(v.value)}} required autoFocus autoComplete="username" className="required-hidden mb-3 w-100" />

                        <div className="form-group mt-2 mb-0" style={{textAlign: 'right'}}>
                            <Button className='me-2' onClick={()=>{history.push('/login')}}>{i18n.t('app:back')}</Button>
                            <Button appearance='primary' disabled={loading} type="submit">{loading ? <Spinner size='tiny'/> : i18n.t('app:next')}</Button>
                        </div>
                    </form>
                    :
                    <div className='d-flex flex-column'>
                        <Text className='mx-auto mb-4'>{i18n.t('app:successPassword')}</Text>
                        <Button className='mx-auto' appearance='primary' onClick={()=>{history.push('/login')}}>{i18n.t('app:backToSignInPage')}</Button>
                    </div>
                    }
                </FluentProvider>
            </Modal>
        </div>
    );
}

export default ForgotPassword;