import './Team.scss';
import { useEffect, useState } from 'react';
import { Selection, ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, Sticky, StickyPositionType, ScrollablePane, ScrollbarVisibility, IColumn } from '@fluentui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Link, 
  Persona,
  Spinner
} from '@fluentui/react-components';
import i18n from "i18next";
import Moment from 'moment';
import { MailRegular } from '@fluentui/react-icons';
import { userService } from '../../_services/user.services';
import { history } from '../../_helpers';

function Team(props:any) {

  const [items, setItems] = useState(props.users)
  const [alertDialog, setAlertDialog]:any = useState({opened: false})

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
    }
  });

  const _resendActivation = (item) => {
    userService.resendActivation(item.id).then((response)=>{
      setAlertDialog({
        opened: true,
        title: i18n.t('app:success'),
        description: i18n.t('app:emailSent')
      })
    }).catch((error)=>{
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorSendingActivationEmail')
      })
    })
  }

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, items): void => {
    queryParams.set('sort',`${column.fieldName},${column.isSortedDescending ? 'ASC' : 'DESC'}`)
    history.push('?'+queryParams.toString())
  }

  const columns:any = (items, sort) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    return [
      {
        key: 'icon',
        name: <div style={{paddingLeft: '6px'}}>#</div>,
        fieldName: 'icon',
        minWidth: 10,
        maxWidth: 10,
        isRowHeader: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          return (<Persona name={item.name + ' ' + item.surname} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>)
        }
      },
      {
        key: 'name',
        name: i18n.t('app:firstName'),
        fieldName: 'name',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'name' || !sort,
        isSortedDescending: sortBy === 'name' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <span title={ item.name }>{ item.name }</span>
          )
        }
      },
      {
        key: 'surname',
        name: i18n.t('app:lastName'),
        fieldName: 'surname',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'surname',
        isSortedDescending: sortBy === 'surname' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <span title={ item.surname }>{ item.surname }</span>
          )
        }
      },
      {
        key: 'email',
        name: i18n.t('app:email'),
        fieldName: 'email',
        minWidth: 250,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'email',
        isSortedDescending: sortBy === 'email' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <Link href={'mailto:'+item.email} title={ item.email }>{ item.email }</Link>
          )
        }
      },
      {
        key: 'lastLogin',
        name: i18n.t('app:lastLogin'),
        fieldName: 'lastLogin',
        minWidth: 150,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'lastLogin',
        isSortedDescending: sortBy === 'lastLogin' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <span title={ item.lastLogin ? Moment(item.lastLogin).format('Y-MMM-D hh:mm') : '' }>{ item.lastLogin ? Moment(item.lastLogin).format('Y-MMM-D hh:mm') : '' }</span>
          )
        }
      },
      {
        key: 'state',
        name: i18n.t('app:state'),
        fieldName: 'state',
        minWidth: 100,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'state',
        isSortedDescending: sortBy === 'state' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return ( <div className='d-flex align-items-center' style={{gap: '.75rem'}}>
            <span title={ item.state ? i18n.t('app:'+item.state) || '' : '' }>{ item.state ? i18n.t('app:'+item.state) : '' }</span>
            {item.state === 'PENDING' && <Button onClick={()=>_resendActivation(item)} icon={<MailRegular/>}></Button>}
            </div>
          )
        }
      }
    ]
  }

  useEffect(()=>{
    setItems(props.users)
  }, [props.users])

  let queryParams = new URLSearchParams(window.location.search)
  let sort = queryParams.get('sort')

  return (
    <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)', position: 'relative'}}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          className="reports"
          setKey="set" 
          items={items} 
          columns={columns(items, sort)}
          onRenderDetailsHeader={onRenderDetailsHeader}
          compact={true}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          enterModalSelectionOnTouch={true}
          isHeaderVisible={true}
          constrainMode={ConstrainMode.unconstrained}
          selection={_selection}
        />
      </ScrollablePane>
      { props.isLoading ? <Spinner className='m-auto mt-5' size='tiny'/>
      : items && items.length === 0 ? <div className='text-secondary mt-5 text-center w-100'>{i18n.t('app:noUsersFound')}</div>
      : null }
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}
export default Team;