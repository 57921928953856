import axios from 'axios'
import i18n from "i18next";
import uuid from 'uuid-random';
import { history } from '../_helpers/history';

const synergyApi = "https://whistleblowing-api."//process.env.REACT_APP_SYNERGY_API_URL
var serverDomain =  "eu-prod.synergyos.com";
var membershipApi = synergyApi + serverDomain + '/membership';
var governanceApi = synergyApi + serverDomain + '/governance';
var activityApi = synergyApi + serverDomain + '/activity-feed';
var sessionDuration = 30 //Same duration as the token
var canRefreshToken = true;
var isPartner = localStorage.getItem('PARTNER_TOKEN') ? true : false;

function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    return result;
}

function toTimestamp(strDate: any){
    var datum = Date.parse(strDate);
    return datum;
}

function getStoredData(name: string) {
    if (name === "token") {
        if (window.location.pathname.includes("/partner") || (window.location.pathname.includes("/login") && isPartner))
            return localStorage.getItem("PARTNER_TOKEN")
        return localStorage.getItem(name)
    }

    return localStorage.getItem(name)
}

function deletedStoredData(list) {
    for (let i = 0; i < list.length; i++) {
        let item = list[i];
        localStorage.removeItem(item)
    }
}

function getInstanceId() {
    var instanceId = localStorage.getItem('instanceId')
    
    if(!instanceId) {
        instanceId = "Origin-" + uuid()
        localStorage.setItem('instanceId', instanceId)
    }

    return instanceId;
}

function login(email, password, companyDomain: string, staySignedIn: boolean) {
    const requestOptions = {
        'application': 'Web',
        'domain': companyDomain,
        'email': email,
        'origin': getInstanceId(),
        'password': password,
        'staySignedIn': staySignedIn
    }

    return axios.post(membershipApi + '/public/auth/login', requestOptions, {})
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function verifyEmailCode(token:string, code:string, staySignedIn:boolean) {
    const headersOptions = {
        headers: { 'Authorization': token }
    };

    let requestOptions = {
        "application": "Web",
        "key": code,
        "origin": getInstanceId(),
        "staySignedIn": staySignedIn
    }

    return axios.post(membershipApi + '/auth/2fa/validate', requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleLoginResponse(error.response || error)
    });
}

function resendVerifyEmailCode(email, password, companyDomain) {
    const requestOptions = {
        "domain": companyDomain,
        "email": email,
        "password": password
    }

    return axios.post(membershipApi + '/public/auth/2fa/resend', requestOptions)
    .then(response => {
        return response;
    }).catch(error => {
        return error;
    });
}

async function afterLogin(response, staySignedIn){
    var token = response.data.accessToken;
    var daysToExpire = 9 //Used by wopi
    isPartner = response.data.partner;
    var expirationDate = addDays(new Date(), daysToExpire)
    var tokenttl = toTimestamp(expirationDate)
    if (staySignedIn) {
        localStorage.setItem('refreshKey', response.data.refreshToken)
    }

    var sessionExpirationDate = new Date();
    sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);


    if (isPartner) {
        localStorage.setItem('PARTNER_TOKEN', token)
        localStorage.setItem('PARTNER_TTL', tokenttl.toString())
    } else {
        localStorage.setItem('token', token)
        localStorage.setItem('tokenttl', tokenttl.toString())
    }

    localStorage.setItem('serverdomain', serverDomain)

    return response;
}

function refreshToken(firstTime) {
    var instanceId = getInstanceId();
    var refreshKey = localStorage.getItem('refreshKey');
    console.log("Can refresh token", canRefreshToken)
    console.log("Refresh key", refreshKey)

    const requestOptions = {
        refreshToken: refreshKey
    }
    
    if (canRefreshToken && refreshKey) {
        canRefreshToken = false;
        return axios.post(membershipApi + '/public/auth/refresh-token',  requestOptions)
        .then(response => {
            console.log('Token refreshed')
            var token = 'Bearer ' + response.data.accessToken;
            var daysToExpire = 9 //Used by wopi
            var expirationDate = addDays(new Date(), daysToExpire)
            var tokenttl = toTimestamp(expirationDate)
            localStorage.setItem('refreshKey', response.data.refreshToken)
            localStorage.setItem('instanceId', instanceId)

            var sessionExpirationDate = new Date();
            sessionExpirationDate.setDate(sessionExpirationDate.getDate() + sessionDuration);


            if (localStorage.PARTNER_TOKEN) {
                localStorage.setItem('PARTNER_TOKEN', token)
                localStorage.setItem('PARTNER_TTL', tokenttl.toString())
            } else {
                localStorage.setItem('token', token)
                localStorage.setItem('tokenttl', tokenttl.toString())
            }

            localStorage.setItem('serverdomain', serverDomain)

            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            if (firstTime) return getUserData();
            else return response;
        }).catch(error => {
            setTimeout(()=>{
                canRefreshToken = true;
            },10000)
            logout();
            if(history.location.pathname.indexOf("/appUpdate") === -1) {
                history.push('/login');
            }
            console.log('Error trying to refresh token.')
            throw error;
        });
    } else if (!refreshKey) {
        logout();
        if(history.location.pathname.indexOf("/appUpdate") === -1) {
            history.push('/login');
        }
        console.log('Refresh key not present.')
        return getUserData();
    } else {
        return getUserData();
    }
}

function logout() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("PARTNER_TOKEN") ? getStoredData("PARTNER_TOKEN") : getStoredData("token") }
    };

    if (getStoredData("token") || getStoredData("PARTNER_TOKEN")) axios.delete(membershipApi + '/auth/logout', headersOptions).catch((error)=>{
        console.error(error)
    })

    deletedStoredData(["token", "tokenttl", "refreshKey", "PARTNER_TOKEN", "PARTNER_TTL"])
    history.push('/login');
}

function getUserData(partner = false) {
    const headersOptions:any = {
        headers: { 'Authorization': partner ? localStorage.getItem("PARTNER_TOKEN") : getStoredData("token") }
    };

    return axios.get(membershipApi + '/auth/introspect-token', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        if(error.status === 404) {
            logout();
            if(history.location.pathname.indexOf("/appUpdate") === -1) {
                history.push('/login');
            }
            throw error.response;
        } else {
            throw handleResponse(error.response)
        }
    });
}

function getUserDataPartner() {
    const headersOptions:any = {
        headers: { 'Authorization': localStorage.getItem("token") }
    };

    return axios.get(membershipApi + '/auth/introspect-partner-token', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        if(error.status === 404) {
            logout();
            if(history.location.pathname.indexOf("/appUpdate") === -1) {
                history.push('/login');
            }
            throw error.response;
        } else {
            throw handleResponse(error.response)
        }
    });
}

function resetPassword(email, domain) {

    const data = {
        email: email,
        infrastructureDomain: serverDomain,
        organizationDomain: domain
    }

    return axios.post(membershipApi + '/public/users/reset-password', data)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function setPassword(token, domain, data) {
    const headersOptions:any = {
        headers: { 'Authorization': token }
    };

    return axios.post(synergyApi + domain + '/membership/users/set-password', data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}


function activateUser(token, domain, data) {
    const headersOptions:any = {
        headers: { 'Authorization': token },
    };

    return axios.post(synergyApi + domain + `/membership/auth/user/activate`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchReports(data, query) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.post(governanceApi + '/reports/search?size=2000' + query, data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getReportsStats() {
    const data = {
        fromCreatedDateTime: 0,
        toCreatedDateTime: new Date().getTime()
    }

    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
        params: data
    };



    return axios.get(governanceApi + `/reports/statistics`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getReports() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.get(governanceApi + '/reports?size=2000', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getReport(reportId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.get(governanceApi + `/reports/${reportId}`, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function submitAttachment(organizationCode, requestOptions) {
    const headersOptions:any = {
        contentType: 'multipart/form-data',
        maxContentLength: Infinity,
        maxBodyLength: Infinity
    };

    return axios.post(governanceApi + `/public/attachments?organizationCode=${organizationCode}`, requestOptions, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getAttachment(reportId, attachmentId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
        responseType: "blob"
    };

    return axios.get(governanceApi + `/reports/${reportId}/attachments/${attachmentId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getMessages(reportId, page) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(governanceApi + `/reports/${reportId}/messages?page=${page}&size=2000`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getInternalMessages(reportId, page) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(governanceApi + `/reports/${reportId}/messages?page=${page}&internal=true&size=2000`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getMessageAttachment(reportId, messageId, attachmentId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
        responseType: "blob"
    };

    return axios.get(governanceApi + `/reports/${reportId}/messages/${messageId}/attachments/${attachmentId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function submitMessage(reportId, requestOptions) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(governanceApi + `/reports/${reportId}/messages`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updateReport(reportId, requestOptions) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.put(governanceApi + `/reports/${reportId}`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getUsers() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(membershipApi + `/users`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function createUser(email, name, surname) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    const requestOptions:any = {
        email: email,
        name: name,
        surname: surname,
        infrastructureDomain: serverDomain
    }

    return axios.post(membershipApi + `/admin/users`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function editUser(userId, name, surname) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    const requestOptions:any = {
        name: name,
        surname: surname
    }

    return axios.put(membershipApi + `/admin/users/${userId}`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function resendActivation(userId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    const requestOptions:any = {
        infrastructureDomain: serverDomain,
    }

    return axios.post(membershipApi + `/admin/users/${userId}/resend-activation`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function disableUser(userId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.delete(membershipApi + `/admin/users/${userId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function restoreUser(userId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(membershipApi + `/admin/users/${userId}/restore`, {}, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getOrganization(id) {
    return axios.get(membershipApi + `/public/organizations/${id}`).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getPublicOrganization(code) {
    return axios.get(membershipApi + `/public/organizations?code=${code}`).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updateOrganization(code, data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.put(membershipApi + `/admin/organizations/${code}`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function addLanguage(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    const requestOptions = data;

    return axios.post(membershipApi + `/languages`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updateLanguage(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    const requestOptions = data;

    return axios.put(membershipApi + `/languages`, requestOptions, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updateLanguageSettings(code, data, languadeId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.put(membershipApi + `/admin/organizations/${code}`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function deleteLanguage(languadeId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.delete(membershipApi + `/languages/${languadeId}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getCategories() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(membershipApi + `/categories`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function addCategory(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(membershipApi + `/categories`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updateCategory(id, data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.put(membershipApi + `/categories/${id}`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function deleteCategory(id) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.delete(membershipApi + `/categories/${id}`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function updatePolicy(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.put(membershipApi + `/policies`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getActivity(query, data = {}) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.post(activityApi + '/activity/search?size=200' + query, data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getLanguages() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.get(membershipApi + '/public/languages/catalog', headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function updateLanguages(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };
    
    return axios.put(membershipApi + '/languages', data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function exchangeToken(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.post(membershipApi + '/partner/exchange-token', data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function searchReportsPartner(data, query) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") }
    };

    return axios.post(governanceApi + '/partner/reports/search?size=2000' + query, data, headersOptions)
    .then(response => {
        return response;
    }).catch(error => {
        throw handleResponse(error.response)
    });
}

function getCompanies() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(membershipApi + `/partner/organizations`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function newCompany(data) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(membershipApi + `/partner/register-company`, data, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function clearNotifications(reportId) {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(governanceApi + `/reports/${reportId}/messages/clear-notifications`, {}, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function getSuperCompanies() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.get(membershipApi + `/admin/organizations`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}

function registerCompany() {
    const headersOptions:any = {
        headers: { 'Authorization': getStoredData("token") },
    };

    return axios.post(membershipApi + `/admin/register-company`, headersOptions).then(response => {
        return response
    }).catch(error => {
        console.log(error)
        throw error;
    });
}


function handleLoginResponse(response) {
    throw response;
}

function handleResponse(response:any) {
    if(response) {
        if (response.status === 403) {
            logout();
            history.push('/login');
            throw response;
        } else if (response.status === 405) {
            alert(i18n.t('app:noPermission'))
            throw response;
        } else if (response.status === 503) {
            // Maintenance mode
            throw response;
        } else {
            throw response;
        }
    }
    
    if(!response) {
        return 'Error: Network Error';
    }
}

export const userService = {
    login,
    verifyEmailCode,
    resendVerifyEmailCode,
    logout,
    afterLogin,
    refreshToken,
    getUserData,
    resetPassword,
    setPassword,
    activateUser,
    searchReports,
    getReportsStats,
    getReports,
    getReport,
    updateReport,
    getMessages,
    getInternalMessages,
    getMessageAttachment,
    submitMessage,
    submitAttachment,
    getAttachment,
    getUsers,
    createUser,
    editUser,
    resendActivation,
    disableUser,
    restoreUser,
    getOrganization,
    getPublicOrganization,
    updateOrganization,
    addLanguage,
    updateLanguage,
    updateLanguageSettings,
    deleteLanguage,
    getCategories,
    addCategory,
    updateCategory,
    deleteCategory,
    updatePolicy,
    getActivity,
    getLanguages,
    updateLanguages,
    clearNotifications,
    //Partner
    exchangeToken,
    getUserDataPartner,
    searchReportsPartner,
    getCompanies,
    newCompany,
    //Super
    getSuperCompanies,
    registerCompany
}