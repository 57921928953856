import './Dashboard.scss';
import { useEffect, useState } from 'react';
import { Selection } from '@fluentui/react';
import { 
  Label,
} from '@fluentui/react-components';
import i18n from "i18next";
import Moment from 'moment';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  Tooltip as TooltipChart
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Line, Pie } from 'react-chartjs-2';

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  ArcElement,
  TooltipChart
);

function Dashboard(props:any) {
  
  const [items, setItems] = useState(props.items)
  //const [newItems, setNewItems] = useState([])
  const [dateStart, setDateStart] = useState<Date | null | undefined>(Moment(new Date()).subtract(6,'months').toDate())
  const [dateEnd, setDateEnd] = useState<Date | null | undefined>(new Date())

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
      props.setSelection(_selection.getSelection())
    },
  });

  useEffect(()=>{
    setItems(props.items)
    //(props.items.filter((item)=>{return item.entityStatus === 'NEW'}))
  }, [props.items])

  let filteredItems = items.filter((item) => {
    return item.createdDateTime >= dateStart!.getTime() && item.createdDateTime <= dateEnd!.getTime()
  })

  var startDateMoment = Moment(dateStart);
  var endDateMoment = Moment(dateEnd);

  var labels:any[] = [];
  var months:any[] = [];
  if (endDateMoment.isBefore(startDateMoment)) {
    // eslint-disable-next-line
    throw "End date must be greated than start date."
  }

  while (startDateMoment.isSameOrBefore(endDateMoment)) {
    months.push(startDateMoment.format("YYYY-MM"));
    startDateMoment.add(1, 'month').startOf('month');
  } 
  labels = months

  var newReportsList:any[] =  new Array(months.length).fill(0);
  var totalReportsList:any[] = new Array(months.length).fill(0);
  if (filteredItems && filteredItems.length > 0) {
    for (let j in months) {
      newReportsList[j] = filteredItems.filter((item)=>{return Moment(item.createdDateTime).format("YYYY-MM") === months[j]}).length
      totalReportsList[j] = filteredItems.filter((item)=>{return Moment(item.createdDateTime).format("YYYY-MM") === months[j] || Moment(item.createdDateTime).isSameOrBefore(months[j])}).length
    }
  }

  var statusList:any[] = []
  if (filteredItems && filteredItems.length > 0) {
    statusList = [
      filteredItems.filter((item=>{return item.entityStatus === 'NEW'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'OPEN'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'CLOSE'})).length,
      filteredItems.filter((item=>{return item.entityStatus === 'SPAM'})).length
    ]
  }

  var priorityList:any[] = []
  if (filteredItems && filteredItems.length > 0) {
    priorityList = [
      filteredItems.filter((item=>{return item.importance === 'URGENT'})).length,
      filteredItems.filter((item=>{return item.importance === 'HIGH'})).length,
      filteredItems.filter((item=>{return item.importance === 'MEDIUM'})).length,
      filteredItems.filter((item=>{return !item.importance})).length,
    ]
  }

  const options:any = {
    responsive: true,
    data: {
      labels,
      datasets: [
        {
          label: i18n.t('app:total'),
          data: totalReportsList,
          borderColor: '#0f6cbd',
          backgroundColor: '#0f6cbd',
        },
        {
          label: i18n.t('app:NEW'),
          data: newReportsList,
          borderColor: '#a6e9ed',
          backgroundColor: '#a6e9ed',
        },
      ],
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets.map((dataset, i) => ({
              text: `${dataset.label} (${datasets[1].data.reduce((partialSum, a) => partialSum + a, 0)})`,
              fillStyle: dataset.backgroundColor,
              borderStyle: dataset.borderColor,
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:reportsPerMonth'),
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: "month"
        }
      },
      y: {
        ticks: {
          beginAtZero: true,
        }
      }
    }
  };

  const optionsPie:any = {
    responsive: true,
    aspectRatio: 1.6,
    data: {
      labels: [i18n.t('app:NEW'), i18n.t('app:OPEN'), i18n.t('app:CLOSE'), i18n.t('app:SPAM'), i18n.t('app:DELETE')],
      datasets: [
        {
          label: i18n.t('app:reports'),
          data: statusList,
          backgroundColor: [
            '#a6e9ed',
            '#bdd99b',
            '#294903',
            'grey',
            'firebrick'
          ],
          borderColor: [
            '#a6e9ed',
            '#bdd99b',
            '#294903',
            'grey',
            'firebrick'
          ],
          borderWidth: 1,
        },
      ],
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              borderStyle: datasets[0].backgroundColor[i],
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:status') || ''
      }
    }
  };

  const optionsPie2:any = {
    responsive: true,
    aspectRatio: 1.6,
    data: {
      labels: [i18n.t('app:URGENT'), i18n.t('app:HIGH'), i18n.t('app:MEDIUM'), i18n.t('app:none')],
      datasets: [
        {
          label: i18n.t('app:reports'),
          data: priorityList,
          backgroundColor: [
            '#d69ca5',
            '#f4bfab',
            '#f9e2ae',
            'lightgrey'
          ],
          borderColor: [
            '#d69ca5',
            '#f4bfab',
            '#f9e2ae',
            'lightgrey'
          ],
          borderWidth: 1,
        },
      ],
    },
    plugins: {
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return datasets[0].data.map((data, i) => ({
              text: `${chart.data.labels[i]} (${data})`,
              fillStyle: datasets[0].backgroundColor[i],
              borderStyle: datasets[0].backgroundColor[i],
              index: i
            }))
          }
        },
        onClick: ()=>{}
      },
      title: {
        display: false,
        text: i18n.t('app:status') || ''
      }
    }
  };
  
  return (
    <div className='h-100 d-flex flex-column' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)'}}>
      <div className='d-flex mx-3 my-2 align-items-center'>
        <Label className='' weight='semibold'>{i18n.t('app:dashboard')}</Label>
        <DatePicker className='ms-auto' value={dateStart || undefined} onSelectDate={(date)=>{if (Moment(date).isBefore(Moment(dateEnd))) setDateStart(date)}}/>
        <DatePicker className='ms-3' value={dateEnd || undefined} onSelectDate={(date)=>{if (Moment(date).isAfter(Moment(dateStart))) setDateEnd(date)}}/>
      </div>
      <div className='d-flex w-100 mx-0' style={{height: 'calc(100% - 60px)', minHeight: '400px'}}>
        <div className='flex-grow-1 p-0 h-100' style={{maxWidth: '70%'}}>
          <div className='mx-3 h-100 d-flex justify-content-center flex-column' style={{border: '1px solid #f2f2f2', minHeight: '100%', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
            <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:reportsPerMonth')}</Label>
            <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
            <Line className='p-3' options={options} data={options.data} />
            </div>
          </div>
        </div>
        <div className='p-0 d-flex flex-column pe-3 h-100' style={{minWidth: '30%', maxWidth : '300px'}}>
          <div className='h-50 pb-2' style={{maxHeight: '50%'}}>
            <div className='h-100 d-flex flex-column' style={{border: '1px solid #f2f2f2', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
              <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:status')}</Label>
              <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
                <Pie className='p-3' options={optionsPie} data={optionsPie.data}/>
              </div>
            </div>
          </div>
          <div className='h-50 pt-2' style={{maxHeight: '50%'}}>
            <div className='h-100 d-flex flex-column' style={{border: '1px solid #f2f2f2', boxShadow: '0px 2px 4px rgba(0,0,0,0.1)'}}>
              <Label className='px-3 py-2' style={{minHeight: '32px', maxHeight: '32px'}} weight='semibold'>{i18n.t('app:priority')}</Label>
              <div className='flex-grow-1 w-100 d-flex align-items-center justify-content-center' style={{height: 'calc(100% - 32px)'}}>
                <Pie className='p-3' options={optionsPie2} data={optionsPie2.data}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;