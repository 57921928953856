import { Button, Divider, Input, Label, Link, Popover, PopoverSurface, PopoverTrigger, Tab, TabList, Image as Image2, SelectTabEvent, SelectTabData, Textarea, Spinner, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Tooltip, Option, Combobox, Checkbox, Overflow, useOverflowMenu, Menu, MenuButton, MenuList, MenuPopover, MenuTrigger, MenuItem, OverflowItemProps, useIsOverflowItemVisible } from '@fluentui/react-components';
import './Settings.scss';
import { AddRegular, ColorRegular, DeleteRegular, DocumentOnePageFilled, DocumentOnePageRegular, EditRegular, FormNewFilled, FormNewRegular, GlobeRegular, HomeFilled, HomeRegular, ImageAddFilled, ImageAddRegular, ShieldTaskFilled, ShieldTaskRegular, bundleIcon } from '@fluentui/react-icons';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { ColorPicker } from '@fluentui/react';
import * as DOMPurify from 'dompurify';
import { userService } from '../../_services/user.services';
import allLanguages from '../../_constants/languages.json'

const ImageAddIcon = bundleIcon(ImageAddFilled, ImageAddRegular);

function Settings(props: any) {

    const [currentTab, setCurrentTab]:any = useState('write')
    const [mainTab, setMainTab]:any = useState('main')
    const [language, setLanguage]:any = useState('home')
    const [logo, setLogo]:any = useState('');
    const [newLogo, setNewLogo]:any = useState('');
    const [color, setColor]:any = useState("#3d8bff");
    const [dueDateDays, setDueDateDays] = useState('');
    const [markdown, setMarkdown]:any = useState();
    const [policyMarkdown, setPolicyMarkdown]:any = useState();
    const [text, setText]:any = useState('');
    const [saving, setSaving] = useState(false);
    const [title, setTitle] = useState("");
    const [directLink, setDirectLink] = useState('https://whistleblower.synergyos.com/')
    const [alertDialog, setAlertDialog]:any = useState({opened: false})
    const [categories, setCategories]: any = useState(props.categories || [])
    const [categoryName, setCategoryName] = useState('')
    const [addingCategory, setAddingCategory] = useState(false)
    const [editingCategory, setEditingCategory] = useState('')
    const [editCategoryName, setEditCategoryName] = useState('')
    const [policiyURL, setPoliciyURL] = useState('')
    const [policyDescription, setPolicyDescription] = useState('')
    const [policyTab, setPolicyTab]:any = useState('write')
    const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);
    const [selectedLanguagesObject, setSelectedLanguagesObject]:any = useState([]);
    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [availableLanguages, setAvailableLanguages] = useState(props.languages || []);
    const [languages, setLanguages]:any[] = useState([]);

    const FormIcon = bundleIcon(FormNewFilled, FormNewRegular)
    const PageIcon = bundleIcon(DocumentOnePageFilled, DocumentOnePageRegular)
    const ShieldTaskIcon = bundleIcon(ShieldTaskFilled, ShieldTaskRegular)
    const HomeIcon = bundleIcon(HomeFilled, HomeRegular)

    function svgUrlToPng(svgUrl, callback) {
      const svgImage = document.createElement('img');
      svgImage.style.maxHeight = '200px';
      // imgPreview.style.position = 'absolute';
      // imgPreview.style.top = '-9999px';
      document.body.appendChild(svgImage);
      svgImage.onload = function () {
          const canvas = document.createElement('canvas');
          canvas.width = svgImage.clientWidth;
          canvas.height = svgImage.clientHeight;
          const canvasCtx = canvas.getContext('2d');
          canvasCtx?.drawImage(svgImage, 0, 0);
          const imgData = canvas.toDataURL('image/png');
          callback(imgData);
          // document.body.removeChild(imgPreview);
      };
      svgImage.src = svgUrl;
   }

    const onFileDrop = (event:any, id:string) => {
      event.stopPropagation();
      event.preventDefault();
    
      event.target.style.background = "transparent";
    
      if(event.dataTransfer.files.length === 1) {
        const files:File[] = Array.from(event.dataTransfer.files)
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            alert("Maximum attachment allowed size: 25 Mb.")
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(files[i]);
            reader.onloadend = function() {
              if ((reader.result + '' ?? '').startsWith('data:image/svg+xml')) {
                svgUrlToPng(reader.result, (imgData) => {
                  setNewLogo(imgData) 
                });  
              } else {
                setNewLogo(reader.result)
              }
            }
          }
        }
      }
    }
  
    const _showFilePicker = () => {
      var input = document.createElement('input');
      input.type = 'file';
      input.multiple = true
  
      input.onchange = e => {
        let event:any = e as Event;
        event.stopPropagation();
        event.preventDefault();
        const files:File[] = Array.from(event.target.files)
        if(files.length === 1) {
          for (let i in files) {
            if (files[i] && files[i].size >= 25000000) {
              alert("Maximum attachment allowed size: 25 Mb.")
            } else {
              let reader = new FileReader();
              reader.readAsDataURL(files[i]);
              reader.onloadend = function() {
                if ((reader.result + '' ?? '').startsWith('data:image/svg+xml')) {
                  svgUrlToPng(reader.result, (imgData) => {
                    setNewLogo(imgData) 
                  });  
                } else {
                  setNewLogo(reader.result)
                }
              }
            }
          }
        }
      }
      input.click();
    }

    const _getMarkdown = () => {
      var MarkdownIt = require('markdown-it');
      var emoji = require('markdown-it-emoji');
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(text));
      let markdown = { __html: result };
      setMarkdown(markdown);
    }

    const _getPolicyMarkdown = () => {
      var MarkdownIt = require('markdown-it');
      var emoji = require('markdown-it-emoji');
      var md = new MarkdownIt();
      md.use(emoji);
      var result = md.render(DOMPurify.sanitize(policyDescription));
      let markdown = { __html: result };
      setPolicyMarkdown(markdown);
    }

    const _onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      if (data.value === 'preview' && currentTab === 'write') {
        _getMarkdown();
      }
      setCurrentTab(data.value);
    };

    const _onMainTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      setMainTab(data.value);
    };

    const _onLanguageSelect = (event: SelectTabEvent, data: SelectTabData) => {
      setLanguage(data.value);
    }

    const _onPolicyTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      if (data.value === 'preview' && currentTab === 'write') {
        _getPolicyMarkdown();
      }
      setPolicyTab(data.value);
    };


    const _addCategory = (name) => {
      setAddingCategory(true)
      userService.addCategory({value: name}).then((response)=>{
        categories.push(response.data)
        let newCategories = categories.sort((a,b) => {return a.value.localeCompare(b.value)})
        setCategoryName('')
        setCategories(newCategories)
        setAddingCategory(false)
      }).catch((error)=>{
        console.error(error)
        setAddingCategory(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingCategories')
        })
      })
    }

    const _startEditingCategory = (item) => {
      setEditingCategory(item.id)
      setEditCategoryName(item.value)
    }

    const _stopEditingCategory = () => {
      setEditingCategory('')
      setEditCategoryName('')
    }

    const _updateCategory = (id) => {
      setAddingCategory(true)
      const name = editCategoryName
      const data = {
        value: name
      }
      userService.updateCategory(id, data).then(()=>{
        let newCategories = categories.slice(0)
        for (let i in newCategories) {
          if (newCategories[i].id === id) {
            newCategories[i].value = name
          }
        }
        setAddingCategory(false)
        setCategories(newCategories)
        setEditCategoryName('')
        setEditingCategory('')
      }).catch((error)=>{
        console.error(error)
        setAddingCategory(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingCategories')
        })
      })
    }
  
    const _deleteCategory = (id) => {
      userService.deleteCategory(id).then(()=>{
        let newCategories = categories.filter((item)=>{return item.id !== id})
        setCategories(newCategories)
        setAddingCategory(false)
      }).catch((error)=>{
        console.error(error)
        setAddingCategory(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingCategories')
        })
      })
    }

    const _updatePolicy = () => {
      setSaving(true);
      const data = {
        link: policiyURL || '',
        description: policyDescription || ''
      }

      userService.updatePolicy(data).then(()=>{
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:success'),
          description: i18n.t('app:policyUpdated')
        })
      }).catch((error)=>{
        console.error(error)
        setSaving(false)
        setAddingCategory(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingPolicy')
        })
      })
    }

    const _editLanguageMessage = (name, value) => {
      let languages = selectedLanguagesObject.slice(0);
      for (let i in languages) {
        if (languages[i].name === name) languages[i].automatedResponse = value
      }
      setSelectedLanguagesObject(languages)
    }

    const _editPreferredLanguage = (id, value) => {
      let languages = selectedLanguagesObject.slice(0);
      for (let i in languages) {
        languages[i].preferred = false
        if (languages[i].id === id) languages[i].preferred = value
      }
      setSelectedLanguagesObject(languages)
    }

    const _updateLanguages = () => {
      setSaving(true);
      const data = selectedLanguagesObject

      userService.updateLanguages(data).then(()=>{
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:success'),
          description: i18n.t('app:languagesUpdated')
        })
      }).catch((error)=>{
        console.error(error)
        setSaving(false)
        setAddingCategory(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingLanguages')
        })
      })
    }

    const _submit = () => {
      setSaving(true);

      const data = {
        //...languageObject,
        title: title,
        description: text,
        name: props.organization.name,
        image: newLogo ? newLogo.split(',')[1] : logo ? logo.split(',')[1] : '',
        color: color.str ?? color ?? '',
        dueDate: dueDateDays ? Number(dueDateDays) < 1 ? '1' : dueDateDays : '90'
      }

      userService.updateOrganization(props.userData.organization.id, data).then((response)=>{
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:success'),
          description: i18n.t('app:organizationUpdated')
        })
      }).catch((error)=>{
        console.error(error)
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingOrganization')
        })
      })
    }

    useEffect(()=>{
      if (props.organization) {
        setTitle(props.organization.title || '')
        setText(props.organization.description || '')
        setLogo(props.organization.image ? 'data:image/png;base64,' + props.organization.image : '')
        setNewLogo('')
        setColor(props.organization.color || '')
        setDueDateDays(props.organization.dueDate || '')
        setDirectLink('https://whistleblower.synergyos.com/'+props.organization.code)
        setCategories(props.categories.sort((a,b)=>{return a.value.localeCompare(b.value)}))
        setPoliciyURL(props.organization.policy?.link || '')
        setPolicyDescription(props.organization.policy?.description || '')
        setSelectedLanguagesObject(props.organization.languages.sort((a,b)=>{return a.name.localeCompare(b.name)}))
        let languages = props.organization.languages.map((item)=>{return allLanguages[item.name.toLowerCase()]?.nativeName || ''})
        setSelectedLanguages(languages)
        setSelectedLanguage(languages.join(', '))
      }
    }, [props.organization, props.categories, props.languages])
    
    return (
      <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)'}}>
        <div className='' style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 10}}>
          <TabList selectedValue={mainTab} onTabSelect={_onMainTabSelect} className='ps-1'>
            <Tab icon={<PageIcon/>} value="main" aria-label="Main">{i18n.t('app:mainPage')}</Tab>
            <Tab icon={<FormIcon/>} value="form" aria-label="Categories">{i18n.t('app:categories')}</Tab>
            <Tab icon={<ShieldTaskIcon/>} value="policy" aria-label="Policy">{i18n.t('app:policy')}</Tab>
            <Tab icon={<GlobeRegular/>} value="languages" aria-label="Languages">{i18n.t('app:languages')}</Tab>
          </TabList>
          <Divider className='mt-'/>
        </div>

        {mainTab === 'main' && <div className='m-3'>
          <div>
            <Label className='me-2' weight='semibold'>{i18n.t('app:directLink')}:</Label>
            <Link href={directLink}>{directLink}</Link>
          </div>
          <Divider className='my-3'/>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:title')}:</Label>
              <div className='mt-2'>
                <Input value={title} onChange={(e,data)=>{setTitle(data.value)}} className='w-100'/>
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:pageContent')}:</Label>
              <TabList className='mb-2' selectedValue={currentTab} onTabSelect={_onTabSelect}>
                <Tab value={'write'}>{i18n.t('app:write')}</Tab>
                <Tab value={'preview'}>{i18n.t('app:preview')}</Tab>
              </TabList>
              <div className='mt-2'>
                <div className='flex-grow-1 markdown-frame' style={{minHeight: '279px', maxHeight: '270px', overflowY: 'auto', display: currentTab === 'preview' ? 'block' : 'none', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                  <div className='markdown-body' dangerouslySetInnerHTML={markdown}>
                  </div>
                </div>
                <Textarea value={text} onChange={(e,data)=>{setText(data.value)}} className='form-text w-100' style={{display: currentTab === 'preview' ? 'none' : 'block'}}/>
              </div>
            </div>
            <div className='mt-3'>
              <div className='d-flex align-items-center'>
                <Label className='me-2' weight='semibold'>{i18n.t('app:logo')}:</Label>
                {logo && !newLogo && <Button onClick={()=>{_showFilePicker()}} className='ms-auto' style={{right: 0, position: 'relative'}} icon={<EditRegular/>}/>}
                {newLogo && <Button onClick={()=>{setNewLogo("")}} className='ms-auto' style={{right: 0, position: 'relative'}} icon={<DeleteRegular/>}/>}
              </div>
              <div
                id='attachment-area'
                className='attachment-area mt-2'
                onDragEnter={onDragEnter} 
                onDragLeave={onDragLeave}
                onDragOver={onDragOver} 
                onDrop={(e) => onFileDrop(e, 'attachment-area')}
              >
                {newLogo ? <Image2 className='m-auto p-3' src={newLogo} style={{maxWidth: '300px', maxHeight: '200px'}}/>
                :
                logo ? <Image2 className='m-auto p-3' src={logo} style={{maxWidth: '300px', maxHeight: '200px'}}/>
                :
                <ImageAddIcon className='m-auto mb-1' style={{fontSize: '30px'}}/>}
                {!newLogo && !logo && <div className='text-center m-auto mt-1'>
                  <span className='me-1 file-selector' onClick={()=>{_showFilePicker()}}>{i18n.t('app:selectFile')}</span>
                  <span >{i18n.t('app:orDragAndDrop')}</span>
                </div>}
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:primaryColor')}:</Label>
              <div className='d-flex mt-2'>
                <Popover>
                  <PopoverTrigger disableButtonEnhancement>
                    <Button className='me-2' icon={<ColorRegular/>} style={{backgroundColor: color.str ?? color}}></Button>
                  </PopoverTrigger>
                  <PopoverSurface>
                    <ColorPicker color={color} onChange={(e,c)=>setColor(c)} alphaType='none' showPreview={false}/>
                  </PopoverSurface>
                </Popover>
                <Input className='' value={color.str ?? color} onChange={(e,data)=>setColor(data.value)}/>
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:dueDateDays')}:</Label>
              <div className='d-flex mt-2'>
                <Input className='' value={dueDateDays} type='number' onChange={(e,data)=>setDueDateDays(data.value)}/>
              </div>
            </div>
            <div className='mt-3 w-100 d-flex'>
              <Button disabled={saving} className='ms-auto' appearance='primary' onClick={_submit}>{saving ? <Spinner size='tiny'/> : i18n.t('app:save')}</Button>
            </div>
          </div>}

          {mainTab === 'form' && <div className='m-3'>
          <div className=''>
            <Label className='me-2' weight='semibold'>{i18n.t('app:categories')}:</Label>
            <div className='w-100 mt-2'>
              <form onSubmit={(e)=>{e.preventDefault(); !addingCategory && _addCategory(categoryName)}} className='d-flex align-items-center mt-1 w-100' style={{gap: '.75rem'}}>
                <Input className='flex-grow-1' value={categoryName} onChange={(e,data)=>{setCategoryName(data.value)}}/>
                <Button icon={<AddRegular/>} disabled={!categoryName || addingCategory} type='submit'>{i18n.t('app:addCategory')}</Button>
              </form>
              <Divider className='my-3'/>
              {categories.map((item,index) => {
                return (editingCategory !== item.id ? 
                  <div key={item.id} className='d-flex align-items-center my-3 w-100' style={{gap: '.75rem'}}>
                    <div style={{border: '1px solid #e0e0e0', borderRadius: '4px', padding: '5px 10px'}} className='flex-grow-1'>{categories[index].value}</div>
                    <Tooltip content={i18n.t('app:editCategory') || ''} relationship="label">
                      <Button icon={<EditRegular/>} onClick={()=>_startEditingCategory(item)}></Button>
                    </Tooltip>
                    <Tooltip content={i18n.t('app:removeCategory') || ''} relationship="label">
                      <Button icon={<DeleteRegular/>} onClick={()=>_deleteCategory(item.id)}></Button>
                    </Tooltip>
                  </div>
                  :
                  <form onSubmit={(e)=>{e.preventDefault(); _updateCategory(item.id)}} key={item.id} className='align-items-center my-3 w-100' style={{border: '1px solid #e0e0e0', borderRadius: '4px', padding: '5px 10px'}}>
                    <Input autoFocus required className='d-flex w-100 mt-1' value={editCategoryName} onChange={(e,data)=>{setEditCategoryName(data.value)}}/>
                    <div className='d-flex align-items-center mt-2' style={{gap: '.75rem'}}>
                      <Button className='ms-auto' onClick={_stopEditingCategory}>{i18n.t('app:cancel')}</Button>
                      <Button type='submit' appearance='primary'>{i18n.t('app:save')}</Button>
                    </div>
                  </form>
                )
              })}
              <div className='mt-3 w-100 d-flex'>
                <Button disabled={saving} className='ms-auto' appearance='primary' onClick={_submit}>{saving ? <Spinner size='tiny'/> : i18n.t('app:save')}</Button>
              </div>
            </div>
          </div>
        </div>}

        {mainTab === 'policy' && <div className='m-3'>
          <div className='mt-3'>
            <Label className='me-2' weight='semibold'>{i18n.t('app:policyLink')}:</Label>
            <div className='mt-2'>
              <Input value={policiyURL} onChange={(e,data)=>{setPoliciyURL(data.value)}} className='w-100'/>
            </div>
          </div>
          <div className='mt-3'>
            <Label className='me-2' weight='semibold'>{i18n.t('app:policyDescription')}:</Label>
            <TabList className='mb-2' selectedValue={policyTab} onTabSelect={_onPolicyTabSelect}>
              <Tab value={'write'}>{i18n.t('app:write')}</Tab>
              <Tab value={'preview'}>{i18n.t('app:preview')}</Tab>
            </TabList>
            <div className='mt-2'>
              <div className='flex-grow-1 markdown-frame' style={{minHeight: '279px', maxHeight: '270px', overflowY: 'auto', display: policyTab === 'preview' ? 'block' : 'none', backgroundColor: 'white', borderRadius: '4px', border: '1px solid #d1d1d1', padding: '6px 12px'}}>
                <div className='markdown-body' dangerouslySetInnerHTML={policyMarkdown}>
                </div>
              </div>
              <Textarea value={policyDescription} onChange={(e,data)=>{setPolicyDescription(data.value)}} className='form-text w-100' style={{display: policyTab === 'preview' ? 'none' : 'block'}}/>
            </div>
          </div>
          <div className='mt-3 w-100 d-flex'>
            <Button disabled={saving} className='ms-auto' appearance='primary' onClick={_updatePolicy}>{saving ? <Spinner size='tiny'/> : i18n.t('app:save')}</Button>
          </div>
        </div>}

        {mainTab === 'languages' && <div className='m-3'>
          <div className=''>
            <Label className='me-2' weight='semibold'>{i18n.t('app:languages')}:</Label>
            <div className='w-100 mt-2'>
              <Combobox 
                //className='w-100'
                multiselect={true}
                value={selectedLanguage}
                onChange={(e:any)=>{setSelectedLanguage(e.target.value)}}
                onOptionSelect={(e, data)=>{
                  let languages = availableLanguages.filter((item)=>{return data.selectedOptions.includes(allLanguages[item.name.toLowerCase()]?.nativeName)})
                  for (let j in languages) {
                    for (let i in selectedLanguagesObject) {
                      if (selectedLanguagesObject[i].name === languages[j].name) {
                        languages[j].automatedResponse = selectedLanguagesObject[i].automatedResponse || ''
                        languages[j].preferred = selectedLanguagesObject[i].preferred ?? false
                      }
                    }
                  }
                  setSelectedLanguagesObject(languages)
                  setSelectedLanguages(data.selectedOptions);
                  setSelectedLanguage('');
                }}
                selectedOptions={selectedLanguages}
                onFocus={()=>{setSelectedLanguage("")}}
                onBlur={()=>{setSelectedLanguage(selectedLanguages.join(', '))}}
              >
                {availableLanguages.map((option:any) => (
                  <Option key={option.name}>{allLanguages[option.name.toLowerCase()]?.nativeName}</Option>
                ))}
              </Combobox>
              <Divider className='my-3'/>
              {selectedLanguagesObject.map((item) => {
                return (
                <div key={item.name} className='align-items-center my-3 w-100' style={{border: '1px solid #e0e0e0', borderRadius: '4px', padding: '5px 12px'}}>
                  <div className='d-flex align-items-center'>
                    <Label style={{color: '#0f6cbd'}} weight='semibold'>{allLanguages[item.name.toLowerCase()]?.nativeName}</Label>
                    <Checkbox checked={item.preferred ?? false} onChange={(e,data)=>{_editPreferredLanguage(item.id, data.checked)}} className='ms-auto' style={{position: 'relative', right: '-6px'}} labelPosition='before' label={i18n.t('app:preferredLanguage')}/>
                  </div>
                  <Divider className='my-2'/>
                  <Label weight='semibold'>{i18n.t('app:automatedResponseMessage')}:</Label>
                  <Textarea className='d-flex w-100 my-2' value={item.automatedResponse || ''} onChange={(e,data)=>_editLanguageMessage(item.name, data.value)}/>
                </div>
                )
              })}
            </div>
          </div>
          <div className='mt-3 w-100 d-flex'>
            <Button disabled={saving} className='ms-auto' appearance='primary' onClick={_updateLanguages}>{saving ? <Spinner size='tiny'/> : i18n.t('app:save')}</Button>
          </div>
        </div>}

        <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      </div>
    );
}

const onDragEnter = (event: any) => {
  event.target.style.background = "#C7E0F4";
}

const onDragLeave = (event: any) => {
  event.target.style.background = "transparent";
}

const onDragOver = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
}

const OverflowMenuItem: any = (props) => {
  const { item } = props;
  const isVisible = useIsOverflowItemVisible(item.id);

  if (isVisible) {
    return null;
  }

  // As an union between button props and div props may be conflicting, casting is required
  return <MenuItem>{allLanguages[item.name.toLowerCase()]?.nativeName}</MenuItem>;
};

const OverflowMenu: React.FC<{ languages: any[] }> = ({ languages }) => {
  const { ref, overflowCount, isOverflowing } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <MenuButton ref={ref}>+{overflowCount} items</MenuButton>
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {languages.map((item) => {
            return <OverflowMenuItem key={item.id} item={item} />;
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};

export default Settings;