import { useEffect, useState } from 'react';
import { Selection, ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, Sticky, StickyPositionType, IColumn, ScrollablePane, ScrollbarVisibility, Icon } from '@fluentui/react';
import './Reports.scss';
import { 
    AvatarGroup, 
    AvatarGroupItem, 
    AvatarGroupPopover, 
    Link, 
    Persona,
    Spinner,
    Tooltip, 
} from '@fluentui/react-components';
import i18n from "i18next";
import { history } from '../../_helpers';
import Moment from 'moment';
import priorities from '../../_constants/priorities.json'
import { Circle12Filled } from '@fluentui/react-icons';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';

function Reports(props:any) {
  
  const [items, setItems] = useState(props.items)

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
      props.setSelection(_selection.getSelection())
    },
  });

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, items): void => {
    queryParams.set('sort',`${column.fieldName},${column.isSortedDescending ? 'ASC' : 'DESC'}`)
    history.push('?'+queryParams.toString())
  }

  const columns:any = (items, sort) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    let columns = [{
      key: 'sequentialNumber',
      name: '#',
      fieldName: 'sequentialNumber',
      minWidth: 24,
      maxWidth: 24,
      isRowHeader: true,
      data: 'string',
      isSorted: sortBy === 'sequentialNumber',
      isSortedDescending: sortBy === 'sequentialNumber' ? sortOrder === 'DESC' : undefined,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (item.sequentialNumber)
      }
    },
    {
      key: 'icon',
      name: <div><Icon className='me-2' {...getFileTypeIconProps({ extension: 'markdownasd', size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '40px', width: '24px', height: '40px'}}/></div>,
      fieldName: 'icon',
      minWidth: 30,
      maxWidth: 30,
      isRowHeader: true,
      data: 'string',
      onRender: (item: any) => {
        return <>
          <Icon className='me-2' {...getFileTypeIconProps({ extension: 'md', size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
          {item.newMessages > 0 && <div className='notification-circle my-auto' style={{position: 'absolute', right: '6px', bottom: '4px'}}><span className='m-auto'>{(item.newMessages || '')}</span></div>}
        </>
      }
    },
    {
      key: 'subject',
      name: i18n.t('app:subject'),
      fieldName: 'subject',
      minWidth: 100,
      isRowHeader: true,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onRender: (item: any) => {
        return (<span title={ item.subject }>{ item.subject }</span>)
      }
    },
    {
      key: 'entityStatus',
      name: i18n.t('app:status'),
      fieldName: 'entityStatus',
      isSorted: sortBy === 'entityStatus',
      isSortedDescending: sortBy === 'entityStatus' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <div title={ item.entityStatus ? i18n.t('app:'+item.entityStatus) || '' : '' }>{ item.entityStatus ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.entityStatus], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.entityStatus)}</div> : '' }</div>
        )
      }
    },
    {
      key: 'importance',
      name: i18n.t('app:priority'),
      fieldName: 'importance',
      isSorted: sortBy === 'importance',
      isSortedDescending: sortBy === 'importance' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <div title={ item.importance ? i18n.t('app:'+item.importance) || '' : '' }>{ item.importance ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.importance], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.importance)}</div> : '' }</div>
        )
      }
    },
    {
      key: 'assignedTo',
      name: i18n.t('app:assignedTo'),
      fieldName: 'assignedTo',
      isSorted: sortBy === 'assignedTo',
      isSortedDescending: sortBy === 'assignedTo' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      //onColumnClick: _onColumnClick,
      onRender: (item: any) => {
        let assignedInline:any[] = [];
        let assignedOverflow:any[] = [];

        if (item && item.assignedUsers) {
          let assigned: any[] = props.users.filter((user)=>{
            return item.assignedUsers.includes(user.id)
          });
          if (assigned.length > 4) {
            assignedInline = assigned.slice(0,4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
            assignedOverflow = assigned.slice(4).sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          } else {
            assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
          }
        }

        return (
          <AvatarGroup layout="stack" size={20} style={{height: '20px'}}>
          {assignedInline.map((item, i) => (
            <Tooltip key={i} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='small' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
              <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')}/>
            </Tooltip>
          ))}
          { assignedOverflow && assignedOverflow.length > 0 && <AvatarGroupPopover>
            {assignedOverflow.map((item, i) => (
              <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')} key={i} />
            ))}
          </AvatarGroupPopover> }
        </AvatarGroup>
        )
      },
    },
    {
      key: 'dueDate',
      name: i18n.t('app:dueDate'),
      fieldName: 'dueDate',
      isSorted: sortBy === 'dueDate',
      isSortedDescending: sortBy === 'dueDate' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ item.dueDate ? Moment(item.dueDate).format('Y-MMM-D') : '' }>{ item.dueDate ? Moment(item.dueDate).format('Y-MMM-D') : '' }</span>
        )
      }
    },
    {
      key: 'modifiedDateTime',
      name: i18n.t('app:lastUpdated'),
      fieldName: 'modifiedDateTime',
      isSorted: sortBy === 'modifiedDateTime',
      isSortedDescending: sortBy === 'modifiedDateTime' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ Moment(item.modifiedDateTime).format('Y-MMM-D') }>{ Moment(item.modifiedDateTime).format('Y-MMM-D') }</span>
        )
      }
    },
    {
      key: 'createdDateTime',
      name: i18n.t('app:creationDate'),
      fieldName: 'createdDateTime',
      isSorted: sortBy === 'createdDateTime' || !sort,
      isSortedDescending: sortBy === 'createdDateTime' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ Moment(item.createdDateTime).format('Y-MMM-D') }>{ Moment(item.createdDateTime).format('Y-MMM-D') }</span>
        )
      }
    },
    ]
    let hasCategory = false;
    for (let i in items) {
      if (items[i].category && items[i].category.length > 0) {
        hasCategory = true;
      }
    }
    if (hasCategory) columns.splice(5, 0, {
      key: 'category',
      name: i18n.t('app:category') || '',
      fieldName: 'category',
      isSorted: sortBy === 'category',
      isSortedDescending: sortBy === 'category' ? sortOrder === 'DESC' : undefined,
      minWidth: 100,
      isResizable: true,
      sortAscendingAriaLabel: 'Sorted A to Z',
      sortDescendingAriaLabel: 'Sorted Z to A',
      data: 'string',
      isPadded: true,
      //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
      onRender: (item: any) => {
        return (
          <span title={ item.category }>{ item.category }</span>
        )
      }
    })
    return columns
  }

  const _invokeItem = (item:any) => {
    history.push('/company/reports/'+item.id)
  }

  useEffect(()=>{
    setItems(props.items)
  }, [props.items])

  const urgentImportance = items.filter((item)=>{return item ? item.importance === 'URGENT' : null})
  const highImportance = items.filter((item)=>{return item ? item.importance === 'HIGH' : null})
  const mediumImportance = items.filter((item)=>{return item ? item.importance === 'MEDIUM' : null})
  let queryParams = new URLSearchParams(window.location.search)
  let sort = queryParams.get('sort')

  return (
    <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)', position: 'relative'}}>
      <div className='d-flex' style={{flexWrap: 'nowrap', overflow: 'auto', overflowX: 'scroll'}}>
        <div className='dashboard-card mx-2 my-2'>
          <p>{i18n.t('app:mediumImportance')}</p>
          <div className='dashboard-number orange-circle'>{ props.isLoading ? <Spinner className='m-auto' size='tiny'/> : <span>{mediumImportance ? mediumImportance.length : ''}</span>}</div>
          <Link onClick={()=>{history.push('/company/reports?priority=MEDIUM')}}>{i18n.t('app:viewList')}</Link>
        </div>
        <div className='dashboard-card mx-2 my-2'>
          <p>{i18n.t('app:highImportance')}</p>
          <div className='dashboard-number red-circle'>{ props.isLoading ? <Spinner className='m-auto' size='tiny'/> : <span>{highImportance ? highImportance.length : ''}</span>}</div>
          <Link onClick={()=>{history.push('/company/reports?priority=HIGH')}}>{i18n.t('app:viewList')}</Link>
        </div>
        <div className='dashboard-card mx-2 my-2'>
          <div className='d-flex align-items-center' style={{marginBottom: '14px'}}>
          <Icon className='me-2' style={{color: 'firebrick', marginTop: '2px'}} iconName='Info'/><span style={{color: 'firebrick'}}>{i18n.t('app:urgentImportance')}</span>
          </div>
          <div className='dashboard-number darkred-circle'>{ props.isLoading ? <Spinner className='m-auto' size='tiny'/> : <span>{urgentImportance ? urgentImportance.length : ''}</span>}</div>
          <Link className='mt-auto' onClick={()=>{history.push('/company/reports?priority=URGENT')}}>{i18n.t('app:viewList')}</Link>
        </div>
        <div className='dashboard-card mx-2 my-2'>
          <p>{i18n.t('app:totalReports')}</p>
          <div className='dashboard-number green-circle'>{ props.isLoading ? <Spinner className='m-auto' size='tiny'/> : <span>{items && items.length > 0 ? items.length : items && !props.isLoading ? items.length : ''}</span>}</div>
          <Link className='mt-auto' onClick={()=>{history.push('/company/reports')}}>{i18n.t('app:viewAllReports')}</Link>
        </div>
      </div>
      <div style={{overflow: 'auto', height: 'calc(100% - 204px)', position: 'relative'}}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          className="reports"
          setKey="set" 
          items={[...items]} 
          columns={columns(items, sort)}
          onRenderDetailsHeader={onRenderDetailsHeader}
          compact={true}
          selectionMode={SelectionMode.multiple}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          enterModalSelectionOnTouch={true}
          isHeaderVisible={true}
          constrainMode={ConstrainMode.unconstrained}
          selection={_selection}
          onItemInvoked={_invokeItem}
          onRenderMissingItem={()=>{props.loadMore(); return undefined}}
        />
      </ScrollablePane>
      { props.isLoading ? <Spinner className='m-auto mt-5' size='tiny'/>
      : items && items.length === 0 ? <div className='text-secondary mt-5 text-center w-100'>{i18n.t('app:noResultsFound')}</div>
      : null }
      </div>

    </div>
  );
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}

export default Reports;