import { Button, Divider, Input, Label, Link, Tab, TabList, SelectTabEvent, SelectTabData, Spinner, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import './Settings.scss';
import { DocumentOnePageFilled, DocumentOnePageRegular, FormNewFilled, FormNewRegular, bundleIcon } from '@fluentui/react-icons';
import i18n from 'i18next';
import { useEffect, useState } from 'react';
import { userService } from '../../../_services/user.services';

function Settings(props: any) {

    const [mainTab, setMainTab]:any = useState('company')
    const [color, setColor]:any = useState("#3d8bff");
    const [dueDateDays, setDueDateDays] = useState('');
    const [text, setText]:any = useState('');
    const [saving, setSaving] = useState(false);
    const [title, setTitle] = useState("");
    const [directLink, setDirectLink] = useState('https://whistleblower.synergyos.com/')
    const [alertDialog, setAlertDialog]:any = useState({opened: false})

    const FormIcon = bundleIcon(FormNewFilled, FormNewRegular)
    const PageIcon = bundleIcon(DocumentOnePageFilled, DocumentOnePageRegular)

    const _onMainTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      setMainTab(data.value);
    };

    const _submit = () => {
      setSaving(true);

      const data = {
        description: text,
        name: title,
        color: color.str ?? color ?? '',
        dueDate: dueDateDays ? Number(dueDateDays) < 1 ? '1' : dueDateDays : '90'
      }

      userService.updateOrganization(props.userData.organization.id, data).then((response)=>{
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:success'),
          description: i18n.t('app:organizationUpdated')
        })
      }).catch((error)=>{
        console.error(error)
        setSaving(false)
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorUpdatingOrganization')
        })
      })
    }

    useEffect(()=>{
      if (props.organization) {
        setTitle(props.organization.name || '')
        setText(props.organization.description || '')
        setColor(props.organization.color || '')
        setDueDateDays(props.organization.dueDate || '')
        setDirectLink('https://whistleblower.synergyos.com/'+props.organization.code)
      }
    }, [props.organization, props.categories, props.languages])

    return (
      <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)'}}>
        <div className='' style={{position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 10}}>
          <TabList selectedValue={mainTab} onTabSelect={_onMainTabSelect} className='ps-1'>
            <Tab icon={<PageIcon/>} value="company" aria-label="Company information">{i18n.t('app:companyInformation')}</Tab>
            <Tab icon={<FormIcon/>} value="security" aria-label="Security">{i18n.t('app:security')}</Tab>
          </TabList>
          <Divider className='mt-'/>
        </div>

        {mainTab === 'company' && <div className='m-3'>
          <div>
            <Label className='me-2' weight='semibold'>{i18n.t('app:directLink')}:</Label>
            <Link href={directLink}>{directLink}</Link>
          </div>
          <Divider className='my-3'/>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:companyName')}:</Label>
              <div className='mt-2'>
                <Input value={title} onChange={(e,data)=>{setTitle(data.value)}} className='w-100'/>
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:email')}:</Label>
              <div className='mt-2'>
                <Input className='w-100'/>
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:phoneNumber')}:</Label>
              <div className='mt-2'>
                <Input className='w-100'/>
              </div>
            </div>
            <div className='mt-3'>
              <Label className='me-2' weight='semibold'>{i18n.t('app:defaultLanguage')}:</Label>
              <div className='mt-2'>
                <Input className='w-100'/>
              </div>
            </div>
            <div className='mt-3 w-100 d-flex'>
              <Button disabled={saving} className='ms-auto' appearance='primary' onClick={_submit}>{saving ? <Spinner size='tiny'/> : i18n.t('app:save')}</Button>
            </div>
        </div>}

        {mainTab === 'security' && <div className='m-3'>

        </div>}

        <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
      </div>
    );
}

export default Settings;