import { useEffect, useState } from 'react';
import Moment from 'moment';
import './Report.scss';
import { AvatarGroup, AvatarGroupItem, Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Input, Persona, SelectTabData, SelectTabEvent, Spinner, Tab, TabList, Textarea, Tooltip } from '@fluentui/react-components';
import { ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, Icon, IconButton, SelectionMode, Sticky, StickyPositionType } from '@fluentui/react';
import i18n from "i18next";
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { BroadActivityFeedFilled, BroadActivityFeedRegular, bundleIcon, ChatFilled, ChatRegular, Circle12Filled, CommentNoteFilled, CommentNoteRegular } from '@fluentui/react-icons';
import { userService } from '../../_services/user.services';
import priorities from '../../_constants/priorities.json'
import { getActivityDetails } from '../../_helpers/activityFunctions';

var loading = true;

function Report(props:any) {

  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem]:any = useState(null);
  const [showReply, setShowReply] = useState(false);
  const [itemAttachments, setItemAttachments]:any[] = useState([]);
  const [attachments, setAttachments]:any[] = useState([]);
  const [savingAttachment, setSavingAttachment] = useState(false);
  const [message, setMessage] = useState("");
  const [currentTab, setCurrentTab]:any = useState('messages')
  const [alertDialog, setAlertDialog]:any = useState({opened: false})
  const [messages, setMessages]:any[] = useState([]);
  const [internalMessages, setInternalMessages]:any[] = useState([]);
  const [activity, setActivity]:any[] = useState([]);
  const [loadingMessages, setLoadingMessages]:any[] = useState(true);
  const [loadingInternalMessages, setLoadingInternalMessages]:any[] = useState(true);
  const [loadingActivity, setLoadingActivity]:any[] = useState(true);
  const [canLoadMoreMessages, setCanLoadMoreMessages]:any[] = useState(false);
  const [canLoadMoreInternalMessages, setCanLoadMoreInternalMessages]:any[] = useState(false);
  const [canLoadMoreActivity, setCanLoadMoreActivity]:any[] = useState(false);
  const [page, setPage]:any[] = useState(0);
  const [internalPage, setInternalPage]:any[] = useState(0);
  const [activityPage, setActivityPage]:any[] = useState(0);
  const [sendingMessage, setSendingMessage] = useState(false);

  const ChatIcon = bundleIcon(ChatFilled, ChatRegular);
  const CommentNoteIcon = bundleIcon(CommentNoteFilled, CommentNoteRegular);
  const ActivityIcon = bundleIcon(BroadActivityFeedFilled, BroadActivityFeedRegular);

  var activityMessages = require('../../locales/en/activity-messages.json')
  if (i18n.language === 'es') {
    activityMessages = require('../../locales/es/activity-messages.json')
  }

  const _getUserByID = (id: string, users = []) => {
    if (id && users) {
      return users.find((user:any)=>{return user.id === id}) || {};
    } else return {}
  }

  const _getContactName = (id: string, users = []) => {
    if (id) {
      if (id === props.userData?.user.id) return props.userData.user.name + ' ' + props.userData.user.surname;
      else if (props.userData && props.userData.organization.id === id) return props.userData.organization.name;
      else if (users) {
        let user:any = users.find((user:any)=>{return user.id === id})
        return user ? user.name + ' ' + user.surname : 'User';
      }
      else return 'User'
    } else if (item.author) {
      return item.author.name + ' ' + item.author.surname
    } else return i18n.t('app:anonymous')
  }

  const _onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    const value = data.value;
    setCurrentTab(value);
    setShowReply(false);
    setMessage("");
    setAttachments([])
  };

  const _sendMessage = () => {
    if (message && message.length > 0) {
      setSendingMessage(true)

      let promises:any[] = [];
      let attachmentIds:string[] = [];
      let hasError = false;
      for (let i in attachments) {
        const dropFormData = new FormData();
        dropFormData.append('attachment', attachments[i].file, attachments[i].file.name);
        promises.push(userService.submitAttachment(props.userData.organization.code, dropFormData).then((response)=>{
          attachmentIds.push(response.data.id)
          // eslint-disable-next-line
        }).catch((error)=>{
          console.error(error)
          setSendingMessage(false)
          hasError = true
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorSubmittingAttachment')
          })
        }))
      }

      Promise.all(promises).then(()=>{
        if (!hasError) {
          const data = {
            content: message,
            internal: false,
            attachmentIds: attachmentIds
          }
          userService.submitMessage(item.id, data).then((response) => {
            setMessage("");
            setAttachments([]);
            setSendingMessage(false)
            setShowReply(false)
            let newMessages = messages;
            newMessages.push(response.data)
            setMessages(newMessages)
          }).catch((error)=>{
            console.error(error)
            setSendingMessage(false)
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorSubmittingMessage')
            })
          })
        }
      })
    }
  }

  const _sendInternalMessage = () => {
    if (message && message.length > 0) {
      setSendingMessage(true)

      let promises:any[] = [];
      let attachmentIds:string[] = [];
      let hasError = false;
      for (let i in attachments) {
        const dropFormData = new FormData();
        dropFormData.append('attachment', attachments[i].file, attachments[i].file.name);
        promises.push(userService.submitAttachment(props.userData.organization.code, dropFormData).then((response)=>{
          attachmentIds.push(response.data.id)
          // eslint-disable-next-line
        }).catch((error)=>{
          console.error(error)
          setSendingMessage(false)
          hasError = true
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorSubmittingAttachment')
          })
        }))
      }

      Promise.all(promises).then(()=>{
        if (!hasError) {
          const data = {
            content: message,
            internal: true,
            attachmentIds: attachmentIds
          }
          userService.submitMessage(item.id, data).then((response) => {
            setMessage("");
            setAttachments([]);
            setSendingMessage(false)
            setShowReply(false)
            let newMessages = internalMessages;
            newMessages.push(response.data)
            setInternalMessages(newMessages)
          }).catch((error)=>{
            console.error(error)
            setSendingMessage(false)
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorSubmittingMessage')
            })
          })
        }
      })
    }
  }

  const _saveAttachments = (files:any[], id:string) => {
    for (let i in files) {
      if (files[i] && files[i].size >= 10000000) {
        alert("Maximum attachment allowed size: 10 Mb.")
      } else {
        const dropFormData = new FormData();
        const fileExtension = files[i].name.split('.').pop();
        const inlineTypes = ['jpg', 'jpeg', 'png', 'svg' , 'jfif', 'pjpeg', 'pjp', 'gif', 'webp'];
        dropFormData.append('attachment', files[i], files[i].name);
        dropFormData.append('inline', inlineTypes.includes(fileExtension) ? 'true' : 'false');
        setSavingAttachment(true)
        let newAttachments = attachments.slice(0)
        newAttachments.push({name: files[i].name , file: files[i]})
        setSavingAttachment(false)
        setAttachments(newAttachments)
      }
    }
  }

  const _showFilePicker = () => {
    var input = document.createElement('input');
    input.type = 'file';
    input.multiple = true

    input.onchange = e => {
      let event:any = e as Event;
      event.stopPropagation();
      event.preventDefault();
      const files:File[] = Array.from(event.target.files)
      if(files.length > 0) {
        for (let i in files) {
          if (files[i] && files[i].size >= 25000000) {
            alert("Maximum attachment allowed size: 25 Mb.")
          } else {
            const dropFormData = new FormData();
            dropFormData.append('attachment', files[i], files[i].name);
            dropFormData.append('inline', 'false');
            setSavingAttachment(true)
            let newAttachments = attachments.slice(0)
            newAttachments.push({name: files[i].name , file: files[i]})
            setSavingAttachment(false)
            setAttachments(newAttachments)
          }
        }
      }
    }

    input.click();
  }

  const onFileDrop = (event:any, id:string) => {
    event.stopPropagation();
    event.preventDefault();
  
    event.target.style.background = "transparent";
  
    if(event.dataTransfer.files.length > 0) {
      const files:File[] = Array.from(event.dataTransfer.files)
      _saveAttachments(files, id)
    }
  }
  
  const onPaste = (e:any, id:string) => {
    if (!e.clipboardData.files.length) {
      return;
    } else {
      e.preventDefault()
    }
    const files:File[] =  Array.from(e.clipboardData.files)
    _saveAttachments(files, id)
  }

  const _downloadAttachment = (attachment:any) => {
    userService.getAttachment(item.id, attachment.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = attachment.name;
  
      document.body.appendChild(anchorElem);
      anchorElem.click();
  
      document.body.removeChild(anchorElem);
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
    })
  }

  const _playAttachment = (attachment:any) => {
    let attachments:any[] = itemAttachments.slice(0)
    for (let i in attachments) {
      if (attachments[i].id === attachment.id) attachments[i].downloading = true
    }
    setItemAttachments(attachments)
    userService.getAttachment(item.id, attachment.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      attachment.file = url
      let attachments:any[] = itemAttachments.slice(0)
      for (let i in attachments) {
        if (attachments[i].id === attachment.id) {
          attachments[i].file = url
          attachments[i].downloading = false
        }
      }
      setItemAttachments(attachments)
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
      let attachments:any[] = itemAttachments.slice(0)
      for (let i in attachments) {
        if (attachments[i].id === attachment.id) {
          attachments[i].downloading = false
        }
      }
      setItemAttachments(attachments)
    })
  }

  const _downloadMessageAttachment = (message:any, attachment:any) => {
    userService.getMessageAttachment(item.id, message.id, attachment.id).then((response)=>{
      var url = URL.createObjectURL(response.data);
      var anchorElem = document.createElement("a");
      anchorElem.href = url;
      anchorElem.download = attachment.name;
  
      document.body.appendChild(anchorElem);
      anchorElem.click();
  
      document.body.removeChild(anchorElem);
    }).catch((error) => {
      console.error(error)
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorDownloadingAttachment')
      })
    })
  }

  const loadMessages = (reportId, page) => {
    setLoadingMessages(true)
    loading = true
    userService.getMessages(reportId, page).then((response) => {
      if (response.data.last) setCanLoadMoreMessages(false);
      else setCanLoadMoreMessages(true);
      setMessages(page === 0 ? response.data.content : [...messages, ...response.data.content])
      setPage(page+1)
      setLoadingMessages(false)
      loading = false
      if (page === 0) clearNotifications()
    }).catch((error) => {
      console.error(error)
      setLoadingMessages(false)
      loading = false
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorFetchingMessages')
      })
    })
  }

  const loadInternalMessages = (reportId, page) => {
    setLoadingInternalMessages(true)
    loading = true
    userService.getInternalMessages(reportId, page).then((response) => {
      if (response.data.last) setCanLoadMoreInternalMessages(false);
      else setCanLoadMoreInternalMessages(true);
      setInternalMessages(page === 0 ? response.data.content : [...internalMessages, ...response.data.content])
      setInternalPage(page+1)
      setLoadingInternalMessages(false)
      loading = false
    }).catch((error) => {
      console.error(error)
      setLoadingInternalMessages(false)
      loading = false
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorFetchingMessages')
      })
    })
  }

  const loadActivity = (reportId, page) => {
    setLoadingActivity(true)
    loading = true
    const query = '&page='+page
    const data = {
      ids: [reportId]
    }
    userService.getActivity(query, data).then((response) => {
      if (response.data.last) setCanLoadMoreActivity(false);
      else setCanLoadMoreActivity(true);
      setActivity(page === 0 ? response.data.last ? response.data.content : [...response.data.content, null] : response.data.last ? [...activity.filter((item)=>{return item}), ...response.data.content] : [...activity.filter((item)=>{return item}), ...response.data.content, null] )
      setActivityPage(page+1)
      setLoadingActivity(false)
      loading = false
    }).catch((error) => {
      console.error(error)
      setLoadingActivity(false)
      loading = false
      setAlertDialog({
        opened: true,
        title: i18n.t('app:error'),
        description: i18n.t('app:errorLoadingActivity')
      })
    })
  }

  const _getAssignedUsers = (users, assignedUsers:any) => {
    let assignedInline:any[] = [];

    if (users && assignedUsers) {
      let assigned: any[] = users.filter((user)=>{
        return assignedUsers.includes(user.id)
      });
      assignedInline = assigned.sort((a,b)=>{return (a.name + '' + a.surname).localeCompare(b.name + '' + b.surname)})
    }

    return (
      <AvatarGroup layout="stack" size={20} style={{height: '20px'}}>
        <div className='me-1'>
            {assignedInline.map((item, i) => (
                <Tooltip key={i} content={<Persona secondaryText={item.email} className='mt-1' textAlignment='center' size='medium' name={(item.name || '') + ' ' + (item.surname || '')} avatar={{color: 'colorful'}} />} relationship="label">
                <AvatarGroupItem name={(item.name || '') + ' ' + (item.surname || '')}/>
                </Tooltip>
            ))}
        </div>
    </AvatarGroup>
    )
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 50;
    console.log(bottom)
    if (bottom) {
      if (!loading && currentTab === 'messages' && canLoadMoreMessages) loadMessages(props.item.id, page)
      else if (!loading && currentTab === 'notes' && canLoadMoreInternalMessages) loadInternalMessages(props.item.id, internalPage)
    }
  }

  const columns:any = (items, sort, users) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    return [
      {
        key: 'date',
        name: i18n.t('app:date'),
        fieldName: 'date',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'date' || !sort,
        isSortedDescending: sortBy === 'date' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <span title={ item.date ? Moment(item.date).format('Y-MMM-D hh:mm') : '' }>{ item.date ? Moment(item.date).format('Y-MMM-D hh:mm') : '' }</span>
          )
        }
      },
      {
        key: 'user',
        name: i18n.t('app:user'),
        fieldName: 'user',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'user',
        isSortedDescending: sortBy === 'user' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          let user:any = _getUserByID(item.userId, users)
          return (<>
            <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
            <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
          </>

          )
        }
      },
      {
        key: 'activity',
        name: i18n.t('app:activity'),
        fieldName: 'activity',
        minWidth: 300,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'activity',
        isSortedDescending: sortBy === 'activity' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return <div>{getActivityDetails(activityMessages, item, users)}</div>
        }
      }
    ]
  }

  const clearNotifications = () => {
    userService.clearNotifications(props.item.id).then((response)=>{

    })
}

  useEffect(()=>{
    if (props.item) {
      setItem({...props.item})
      setItemAttachments(props.item.attachments ?? [])
    }
  }, [props.item])

  useEffect(() =>{
    setIsLoading(props.isLoading)
    if (props.item) {
      setItem({...props.item})
      loadMessages(props.item.id, 0)
      loadInternalMessages(props.item.id, 0)
      loadActivity(props.item.id, 0)
      setItemAttachments(props.item.attachments ?? [])
    }
    // eslint-disable-next-line
  }, [props.isLoading])

  const labelWidth = i18n.language === 'es' ? '150px' : '100px' 

  return (
    <div className='d-flex flex-column w-100 h-100' style={{maxHeight: 'calc(100vh - 136px)', overflow: 'auto'}} onScroll={handleScroll}>
      {isLoading ? <Spinner className='m-auto' size='extra-small'/> 
      : 
      !item ? <></>
      :
      <div className='mx-auto w-100 h-100' style={{maxWidth: '800px'}}>
        <div className='main-title-section mx-3 d-flex align-items-center' style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
            <span className='me-2' style={{fontWeight: '600', fontSize: 18}}>{item.subject}</span><span style={{color: 'grey', fontSize: 18}}>{' #' + item.id.split('-').pop()}</span>
        </div>
        <div className='flex-grow-1 d-flex flex-column'>
          <div className='mx-3' style={{background: 'white', borderBottom: '1px solid rgb(237, 235, 233)'}}>
            <div className='d-flex align-items-center my-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:createdDate')}:</span>
                <span>{Moment(item.createdDateTime).format('Y-MM-DD')}</span>
              </div>
            </div>
            <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:lastUpdated')}: </span>
                <span>{Moment(item.modifiedDateTime).format('Y-MM-DD')}</span>
              </div>
            </div>
            {item.author ? <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center mb-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:reportedBy')}: </span>
                <Tooltip 
                  content={
                    <div className='d-flex align-items-center'>
                      <Persona className="me-2" name={item.author.name + ' ' + item.author.surname} size='small' primaryText="" avatar={{ color: "colorful", style: { margin: 0 } }} />
                      <div>
                        <span>{item.author.name + ' ' + item.author.surname}</span>
                        <div>
                          <a style={{ color: '#0f548c' }} href={'mailto:' + item.author.email}>{item.author.email}</a>
                        </div>
                      </div>
                    </div>}
                  relationship={'label'}
                >
                  <Persona name={item.author.name + ' ' + item.author.surname} size='extra-small' avatar={{ color: "colorful" }} />
                </Tooltip>
              </div>
            </div>
            :
            <div className='d-flex align-items-center'>
              <div className='d-flex align-items-center mb-3' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:reportedBy')}: </span>
                <span>{i18n.t('app:anonymous')}</span>
              </div>
            </div>
            }
            {item.category && <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:category')}: </span>
                <span>{item.category}</span>
              </div>
            </div>}
            {item.assignedUsers && item.assignedUsers.length > 0 && <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:assignedTo')}: </span>
                <div className='d-flex'>{_getAssignedUsers(props.users, item.assignedUsers)}</div>
              </div>
            </div>}
            {item.importance && <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:priority')}: </span>
                <div title={ item.importance ? i18n.t('app:'+item.importance) || '' : '' }>{ item.importance ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.importance], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.importance)}</div> : '' }</div>
              </div>
            </div>}
            <div className='d-flex align-items-center mb-3'>
              <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:status')}: </span>
                <div title={ item.entityStatus ? i18n.t('app:'+item.entityStatus) || '' : '' }>{ item.entityStatus ? <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[item.entityStatus], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+item.entityStatus)}</div> : '' }</div>
              </div>
            </div>
            <div className='pb-3' style={{minHeight: '40px'}}>
              <div className='d-flex' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'nowrap'}}>
                <span className='me-2' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:details')}:</span>
                <div className='' style={{overflowWrap: 'normal', whiteSpace: 'break-spaces'}}>
                  <span>{item.description ? item.description.trim() : ''}</span>
                </div>
              </div>
            </div>
            {itemAttachments && itemAttachments.length > 0 && <div className='pb-3'>
            <div className='d-flex align-items-center' style={{whiteSpace: 'nowrap', overflow:'hidden', textOverflow: 'ellipsis', flexWrap: 'wrap'}}>
              <span className='' style={{fontWeight: 600, minWidth: labelWidth}}>{i18n.t('app:attachments')}:</span>
            </div>
            {itemAttachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
              <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
              <span className='me-2' title={item.name}>{item.name}</span>
              { item.name.split('.').pop() === 'wav' ? <div className='ms-auto d-flex' style={{height: '30px', minWidth: '50px'}}>
              {item.downloading ? <Spinner size='tiny' style={{height: '30px', width: '30px'}}/> : !item.file ? <Tooltip content={i18n.t('app:play') || ''} relationship={'label'}><div><IconButton className='' iconProps={{iconName: 'Play'}} style={{height: '30px', width: '30px'}} onClick={(e)=>{
                _playAttachment(item)
                e.stopPropagation();
              }}/></div></Tooltip> : null}
              {item.file && <audio className='h-100 py-1' controls>
                <source src={item.file} type="audio/wav"/>
              </audio>}
              <Tooltip content={i18n.t('app:download') || ''} relationship={'label'}>
                <div>
                  <IconButton className='' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                    _downloadAttachment(item)
                    e.stopPropagation();
                  }}/>
                </div>
              </Tooltip>
              </div>
              :
              <IconButton className='ms-auto' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                _downloadAttachment(item)
                e.stopPropagation();
              }}/>
              }
            </div>})}
          </div>}
          </div>

          
          <div className='ps-1'>
            <TabList selectedValue={currentTab} onTabSelect={_onTabSelect} >
              <Tab icon={<ChatIcon/>} value="messages" aria-label="Messages">{i18n.t('app:messages')}</Tab>
              <Tab icon={<CommentNoteIcon/>} value="notes" aria-label="Notes">{i18n.t('app:internalNotes')}</Tab>
              <Tab icon={<ActivityIcon/>} value="activity" aria-label="Activity">{i18n.t('app:activity')}</Tab>
            </TabList>
          </div>

          {item && currentTab === 'messages' && <div>
            <div className='messages-box'>
                {messages.map((message: any) => (
                  <div key={message.id} id={message.id} className='message-wrapper d-flex px-3'>
                    <div className='vertical-hr'></div>
                    {message.createdByUserId !== props.userData.user.id ? <Persona className="me-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId, props.users)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                    <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                      <div className = "message-top d-flex w-100" style={{backgroundColor: message.createdByUserId === props.userData.user.id ? '#C7E0F4' : ''}}>
                        <div className='author d-flex align-items-center'>
                          <span className='me-1'>{_getContactName(message.createdByUserId, props.users)}</span>
                          <span>{' on ' + Moment(message.createdDateTime).format('Y-MMM-D')}</span>
                        </div>
                      </div>
                      <div className='message-content'>
                        {message.attachments && message.attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                          {message.attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
                            <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                            <span className='me-2' title={item.name}>{item.name}</span>
                            <IconButton className='ms-auto' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                              _downloadMessageAttachment(message, item)
                              e.stopPropagation();
                            }}/>
                          </div>})}
                        </div>}
                        <div className='mt-2' style={{whiteSpace: 'pre-wrap'}} >
                          {message.content || ''}
                        </div>
                      </div>
                      <div className = "message-bottom d-flex w-100">
                        
                      </div>
                    </div>
                    {message.createdByUserId === props.userData.user.id ? <Persona className="ms-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId, props.users)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                  </div>
                ))}
            </div>
            {loadingMessages && <Spinner className='m-auto' size='extra-small'/>}
            {messages.length > 0 && <hr className='mx-3' style={{borderColor: 'rgb(237, 235, 233)', margin: 0, opacity: 1}}/>}
            {<div className='message-wrapper d-flex px-3'>
              <div style={{width: '36px'}}/>
              {!showReply ?
              <Input className='flex-grow-1' placeholder={i18n.t('app:writeReply')+'...'} onClick={()=>{setShowReply(true)}} style={{marginTop: '2px'}}/>
              :
              <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                <div className = "message-top d-flex py-0 align-items-center" style={{backgroundColor: '#C7E0F4', height: '33px'}}>
                  <span className='author ms-1'>
                    {i18n.t('app:reply')}
                  </span>
                  <IconButton iconProps={{iconName: 'Attach'}} onClick={()=>{_showFilePicker()}} className='ms-auto normal-button-opacity' style={{height: '27px', color: 'rgb(16, 110, 190)'}}/>
                </div>
                <div className='message-content'>
                  {attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                    {attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}} onClick={()=>{_downloadAttachment(item)}}>
                      <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.')[item.name.split('.').length -1], size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                      <span className='me-2' title={item.name}>{item.name}</span>
                      <IconButton iconProps={{iconName: 'Cancel'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                        e.stopPropagation();
                        let newAttachments = attachments.filter((a:any)=>{return a.id !== item.id})
                        setAttachments(newAttachments)
                      }}/>
                    </div>})}
                  </div>}
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <div className='flex-grow-1' style={{display: 'flex'}}>
                      <Textarea
                        id="response-textbox"
                        onDragEnter={onDragEnter} 
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver} 
                        onDrop={(e) => onFileDrop(e, 'response-textbox')}
                        onPaste={(e)=> onPaste(e, "response-textbox")}
                        className='flex-grow-1 w-100'
                        placeholder={i18n.t('app:writeReply')+'...'}
                        value={message}
                        textarea={{style:{minHeight: '100px'}}}
                        onChange={(e,data)=>{setMessage(data.value)}}
                        resize={'vertical'}
                        style={{borderColor: 'rgb(230,230,230)'}}
                        autoFocus
                      />
                    </div>
                  </div>
                  {savingAttachment && <p className='m-0'><span className='me-1' style={{color: 'rgb(66, 66, 66)'}}>{i18n.t('app:uploadingAttachment')}...</span></p>}
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <Button disabled={sendingMessage} className='ms-auto cancel-button' onClick={()=>{setShowReply(false);setAttachments([]);setMessage("")}}>{i18n.t('app:cancel')}</Button>
                    <Button disabled={sendingMessage || !message} appearance='primary'  className='ms-3' onClick={()=>{_sendMessage()}}>{sendingMessage ? <Spinner size='tiny'/> : i18n.t('app:send')}</Button>
                  </div>
                </div>
              </div>}
              <Persona className="ms-2" style={{marginTop: '3px'}} name={(props.userData?.user.name || '') + ' ' + (props.userData?.user.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            </div>}
          </div>}

          {item && currentTab === 'notes' && <div>
            <div className='messages-box'>
              {internalMessages.map((message: any) => (
                <div key={message.id} id={message.id} className='message-wrapper d-flex px-3'>
                  <div className='vertical-hr'></div>
                  {message.createdByUserId !== props.userData.user.id ? <Persona className="me-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId, props.users)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                  <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                    <div className = "message-top d-flex w-100" style={{backgroundColor: message.createdByUserId === props.userData.user.id ? '#C7E0F4' : ''}}>
                      <div className='author d-flex align-items-center'>
                        <span className='me-1'>{_getContactName(message.createdByUserId, props.users)}</span>
                        <span>{' on ' + Moment(message.createdDateTime).format('Y-MMM-D')}</span>
                      </div>
                    </div>
                    <div className='message-content'>
                      {message.attachments && message.attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                        {message.attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}}>
                          <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.').pop(), size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                          <span className='me-2' title={item.name}>{item.name}</span>
                          <IconButton className='ms-auto' iconProps={{iconName: 'Download'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                            _downloadMessageAttachment(message, item)
                            e.stopPropagation();
                          }}/>
                        </div>})}
                      </div>}
                      <div className='mt-2' style={{whiteSpace: 'pre-wrap'}} >
                        {message.content || ''}
                      </div>
                    </div>
                    <div className = "message-bottom d-flex w-100">
                      
                    </div>
                  </div>
                  {message.createdByUserId === props.userData.user.id ? <Persona className="ms-2" style={{marginTop: '3px'}} name={_getContactName(message.createdByUserId, props.users)} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/> : <div style={{width: '36px'}}/>}
                </div>
              ))}
              </div>
              {loadingInternalMessages && <Spinner className='m-auto' size='extra-small'/>}
              {internalMessages.length > 0 && <hr className='mx-3' style={{borderColor: 'rgb(237, 235, 233)', margin: 0, opacity: 1}}/>}
              {<div className='message-wrapper d-flex px-3'>
              <div style={{width: '36px'}}/>
              {!showReply ?
              <Input className='flex-grow-1' placeholder={i18n.t('app:writeReply')+'...'} onClick={()=>{setShowReply(true)}} style={{marginTop: '2px'}}/>
              :
              <div className='message flex-grow-1' style={{maxWidth: 'calc(100% - 72px)'}}>
                <div className = "message-top d-flex py-0 align-items-center" style={{backgroundColor: '#C7E0F4', height: '33px'}}>
                  <span className='author ms-1'>
                    {i18n.t('app:reply')}
                  </span>
                  <IconButton iconProps={{iconName: 'Attach'}} onClick={()=>{_showFilePicker()}} className='ms-auto normal-button-opacity' style={{height: '27px', color: 'rgb(16, 110, 190)'}}/>
                </div>
                <div className='message-content'>
                  {attachments.length > 0 && <div className='d-flex w-100' style={{overflowX: 'auto'}}>
                    {attachments.map((item:any) => {return <div key={item.id} className='attachment pe-0' style={{borderRadius: '4px'}} onClick={()=>{_downloadAttachment(item)}}>
                      <Icon className='me-2' {...getFileTypeIconProps({ extension: item.name.split('.')[item.name.split('.').length -1], size: 24, imageFileType: 'svg' }) } style={{minWidth: '24px', minHeight: '24px', width: '24px', height: '24px'}}/>
                      <span className='me-2' title={item.name}>{item.name}</span>
                      <IconButton iconProps={{iconName: 'Cancel'}} style={{height: '29px', width: '29px'}} onClick={(e)=>{
                        e.stopPropagation();
                        let newAttachments = attachments.filter((a:any)=>{return a.id !== item.id})
                        setAttachments(newAttachments)
                      }}/>
                    </div>})}
                  </div>}
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <div className='flex-grow-1' style={{display: 'flex'}}>
                      <Textarea
                        id="response-textbox"
                        onDragEnter={onDragEnter} 
                        onDragLeave={onDragLeave}
                        onDragOver={onDragOver} 
                        onDrop={(e) => onFileDrop(e, 'response-textbox')}
                        onPaste={(e)=> onPaste(e, "response-textbox")}
                        className='flex-grow-1 w-100'
                        placeholder={i18n.t('app:writeReply')+'...'}
                        value={message}
                        textarea={{style:{minHeight: '100px'}}}
                        onChange={(e,data)=>{setMessage(data.value)}}
                        resize={'vertical'}
                        style={{borderColor: 'rgb(230,230,230)'}}
                        autoFocus
                      />
                    </div>
                  </div>
                  {savingAttachment && <p className='m-0'><span className='me-1' style={{color: 'rgb(66, 66, 66)'}}>{i18n.t('app:uploadingAttachment')}...</span></p>}
                  <div className='d-flex align-items-center w-100 mt-2'>
                    <Button disabled={sendingMessage} className='ms-auto cancel-button' onClick={()=>{setShowReply(false);setAttachments([]);setMessage("")}}>{i18n.t('app:cancel')}</Button>
                    <Button disabled={sendingMessage || !message} appearance='primary'  className='ms-3' onClick={()=>{_sendInternalMessage()}}>{sendingMessage ? <Spinner size='tiny'/> : i18n.t('app:send')}</Button>
                  </div>
                </div>
              </div>}
              <Persona className="ms-2" style={{marginTop: '3px'}} name={(props.userData?.user.name || '') + ' ' + (props.userData?.user.surname || '')} size='small' primaryText='' avatar={{ color: "colorful", style: {margin: 0}}}/>
            </div>}
          </div>}

          {item && currentTab === 'activity' && <div className='flex-grow-1 px-3' style={{minHeight: '100px'}}>
            <DetailsList
              setKey="set" 
              items={activity} 
              columns={columns(activity, '', props.users)}
              onRenderDetailsHeader={onRenderDetailsHeader}
              compact={true}
              selectionMode={SelectionMode.none}
              layoutMode={DetailsListLayoutMode.fixedColumns}
              enterModalSelectionOnTouch={true}
              isHeaderVisible={true}
              constrainMode={ConstrainMode.unconstrained}
              onRenderMissingItem={()=>{console.log(!loading, !loadingActivity, canLoadMoreActivity); if (!loading && !loadingActivity && canLoadMoreActivity) loadActivity(props.item.id, activityPage); return undefined}}
            />
          </div>}

        </div>
      </div> }
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

const onDragEnter = (event: any) => {
  event.target.style.background = "#C7E0F4";
}

const onDragLeave = (event: any) => {
  event.target.style.background = "transparent";
}

const onDragOver = (event: any) => {
  event.stopPropagation();
  event.preventDefault();
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}
export default Report;