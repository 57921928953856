import './Main.scss';
import Sidebar from '../../../_components/Sidebar/Sidebar';
import Dashboard from '../Dashboard/Dashboard';
import Reports from '../Reports/Reports';
import Header from '../../../_components/Header/Header';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Report from '../../Report/Report';
import { history } from '../../../_helpers';
import Team from '../Team/Team';
import Activity from '../../Activity/Activity';
import { userService } from '../../../_services/user.services';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import i18n from "i18next";
import Companies from '../Companies/Companies';
import Settings from '../Settings/Settings';

var loading = true;

function MainPartner(props:any) {

  const [sidebarWidth, setSidebarWidth] = useState(Number(window.localStorage.sidebarWidth) || 48);
  const [openDetailsPanel, setOpenDetailsPanel] = useState(localStorage.detailsPanelStatus === 'OPEN');
  const [breadcrumb, setBreadcrumb]:any = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [item, setItem]:any = useState(null);
  const params = useParams();
  const [page, setPage] = useState(0);
  const [items, setItems]:any = useState([]);
  const [organization, setOrganization]:any = useState(null);
  const [categories, setCategories]:any = useState([]);
  const [languages, setLanguages]:any = useState([]);
  const [alertDialog, setAlertDialog]:any = useState({opened: false});
  const [selection, setSelection] = useState([]);
  const [activity, setActivity]:any = useState([]);

  const [users, setUsers] = useState([]);
  localStorage.removeItem('COMPANY_TOKEN')

  const _getContent = (hidden = false) => {
    if (props.userData) {
      //if (!hidden) {
        setItem(null)
        setItems([])
        setUsers([])
        setActivity([])
      //}
      setIsLoading(true)
      if (props.page === 'reports' && params && params.id ) {
        userService.getReport(params.id ).then((response) => {
          setPage(0)
          setItem(response.data)
          userService.getUsers().then((response) => {
            setUsers(response.data)
            setIsLoading(false)
          }).catch((error) => {
            console.error(error)
            setIsLoading(false)
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorLoadingUsers')
            })
          })
        }).catch((error) => {
          console.error(error)
          setIsLoading(false)
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingReport')
          })
        })
      } else if (props.page === 'reports' || props.page === 'dashboard') {
        loading = true;
        const queryParams = new URLSearchParams(window.location.search)
        const data:any = {};
        const priority = queryParams.get('priority');
        const status = queryParams.get('status');
        const sort = queryParams.get('sort');
        if (priority && priority !== '---') data.importance = priority;
        if (status  && status !== '---') data.entityStatus = status;
        let query = '&page='+0
        if (sort) query += '&sort='+sort;
        userService.searchReportsPartner(data, query).then((response) => {
          setPage(1)
          setItems(response.data.last ? response.data.content : [...response.data.content, null])
          userService.getUsers().then((response) => {
            setUsers(response.data)
            setIsLoading(false)
            loading = false
          }).catch((error) => {
            console.error(error)
            setIsLoading(false)
            loading = false
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorLoadingUsers')
            })
          })
        }).catch((error) => {
          console.error(error)
          setIsLoading(false)
          loading = false
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingReports')
          })
        })
      } else if (props.page === 'team') {
        const queryParams = new URLSearchParams(window.location.search)
        const sort = queryParams.get('sort');
        userService.getUsers().then((response) => {
          let sortBy = ''
          let sortOrder = ''
          if (sort) {
            const sortsplit = sort.split(',')
            sortBy = sortsplit[0]
            sortOrder = sortsplit[1]
            setUsers(response.data.sort((a,b)=> { 
              if (sortBy === 'lastLogin') return sortOrder === 'ASC' ?  a.lastLogin > b.lastLogin : b.lastLogin > a.lastLogin 
              else return sortOrder === 'ASC' ?  (a[sortBy] || '').localeCompare(b[sortBy]) : (b[sortBy] || '').localeCompare(a[sortBy]) 
            }))
          } else {
            setUsers(response.data.sort((a,b)=> { return a['name'].localeCompare(b['name']) }))
          }
          setIsLoading(false)
        }).catch((error) => {
          console.error(error)
          setIsLoading(false)
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingUsers')
          })
        })
      } else if (props.page === 'companies') {
        loading = true;
        userService.getCompanies().then((response) => {
          setItems(response.data)
          userService.getUsers().then((response) => {
            setUsers(response.data)
            setIsLoading(false)
            loading = false
          }).catch((error) => {
            console.error(error)
            setIsLoading(false)
            loading = false
            setAlertDialog({
              opened: true,
              title: i18n.t('app:error'),
              description: i18n.t('app:errorLoadingCompanies')
            })
          })
        }).catch((error) => {
          console.error(error)
          setIsLoading(false)
          loading = false
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingReports')
          })
        })
      } else if(props.page === 'settings') {
        userService.getPublicOrganization(props.userData.organization.code).then((response)=>{
          const organization = response.data;
          // userService.getCategories().then((response)=>{
          //  const categories = response.data
            userService.getLanguages().then((response)=>{
              setOrganization(organization)
              setCategories(categories)
              setLanguages(response.data)//response.data.map((item)=>{return {key: item.name, value: allLanguages[item.name.toLowerCase()]?.nativeName, name: allLanguages[item.name.toLowerCase()]?.nativeName || ''} }))
              setIsLoading(false)
            }).catch((error)=>{
              console.error(error)
              setIsLoading(false)
              setAlertDialog({
                opened: true,
                title: i18n.t('app:error'),
                description: i18n.t('app:errorLoadingOrganization')
              })
            })
          // }).catch((error)=>{
          //   console.error(error)
          //   setIsLoading(false)
          //   setAlertDialog({
          //     opened: true,
          //     title: i18n.t('app:error'),
          //     description: i18n.t('app:errorLoadingOrganization')
          //   })
          // })
        }).catch((error)=>{
          console.error(error)
          setIsLoading(false)
          setAlertDialog({
            opened: true,
            title: i18n.t('app:error'),
            description: i18n.t('app:errorLoadingOrganization')
          })
        })
      } else {
        setIsLoading(false)
      }
    }
  }

  const _loadMoreReports = () => {
    if ((props.page === 'reports' || props.page === 'dashboard') && !loading) {
      loading = true;
      const queryParams = new URLSearchParams(window.location.search)
      const data:any = {};
      const priority = queryParams.get('priority');
      const sort = queryParams.get('sort');
      if (priority) data.importance = priority;
      let query = '&page='+page
      if (sort) query += '&sort='+sort;
      userService.searchReportsPartner(data, query).then((response) => {
        setPage(page+1)
        setItems(response.data.last ? [...items.filter((item)=>{return item}), ...response.data.content] : [...items.filter((item)=>{return item}), ...response.data.content, null])
        setIsLoading(false)
        loading = false
      }).catch((error) => {
        console.error(error)
        setIsLoading(false)
        loading = false
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorLoadingReports')
        })
      })
    }
  }

  const _loadMoreActivity = () => {
    if ((props.page === 'activity') && !loading) {
      loading = true;
      const queryParams = new URLSearchParams(window.location.search)
      const data:any = {};
      const operationType = queryParams.get('operationType');
      const modelType = queryParams.get('modelType');
      const field = queryParams.get('field');
      const initialDate = queryParams.get('initialDate');
      const finalDate = queryParams.get('finalDate');
      const sort = queryParams.get('sort');
      if (operationType && operationType !== '---') data.operationTypes = [operationType];
      if (modelType  && modelType !== '---') data.modelTypes = [modelType];
      if (field) data.fields = [field];
      if (initialDate) data.initialDate = initialDate;
      if (finalDate) data.finalDate = finalDate;
      let query = '&page='+page
      if (sort) query += '&sort='+sort;
      userService.getActivity(query).then((response) => {
        setActivity(response.data.last ? [...activity.filter((item)=>{return item}), ...response.data.content] : [...activity.filter((item)=>{return item}), ...response.data.content, null])
        setPage(page+1)
        setIsLoading(false)
        loading = false
      }).catch((error) => {
        console.error(error)
        setIsLoading(false)
        loading = false
        setAlertDialog({
          opened: true,
          title: i18n.t('app:error'),
          description: i18n.t('app:errorLoadingActivity')
        })
      })
    }
  }

  const _updateItem = (id, data) => {
    let newItems = items;
    for (let i in newItems) {
      if (newItems[i].id === id) {
        newItems[i] = data
      }
    }
    if (item && item.id === id) {setItem(data)}
    setItems([...newItems])
  }

  useEffect(()=>{
    setBreadcrumb(props.page === 'dashboard' ? 
    [{key: 'dashboard', text: i18n.t('app:dashboard')}] 
    : props.page === 'reports' && params && params.id ? 
    [{key: 'reports', text: i18n.t('app:reports'), onClick: ()=>{history.push('/company/reports')}}, {key: params.id, text: i18n.t('app:report')}]
    : props.page === 'reports' ? 
    [{key: 'reports', text: i18n.t('app:reports')}] 
    : props.page === 'companies' ? 
    [{key: 'companies', text: i18n.t('app:companies')}] 
    : props.page === 'team' ? 
    [{key: 'team', text: i18n.t('app:team')}] 
    : props.page === 'activity' ? 
    [{key: 'activity', text: i18n.t('app:activity')}] 
    : props.page === 'settings' ? 
    [{key: 'settings', text: i18n.t('app:settings')}] 
    : [])
    _getContent()
    if (window.location.pathname.includes('/partner')) localStorage.removeItem('token')
    // eslint-disable-next-line
  },[props.page, params, props.userData])

  return (
    <div className='d-flex flex-column h-100'>
      <Header isLoading={isLoading} getContent={_getContent} setSidebarWidth={setSidebarWidth} sidebarWidth={sidebarWidth} openDetailsPanel={openDetailsPanel} setOpenDetailsPanel={setOpenDetailsPanel} page={props.page} breadcrumb={breadcrumb} item={item} updateItem={_updateItem} selection={selection} type="PARTNER"/>
      <div className="flex-grow-1 d-flex">
        <Sidebar userData={props.userData} setUserData={props.setUserData} sidebarWidth={sidebarWidth} page={props.page} type="PARTNER"/>
        <div className='flex-grow-1' style={{overflowX: 'auto'}}>
            {
              props.page === 'dashboard' ?
                <Dashboard userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} users={users} items={items} setItems={setItems} setItem={setItem} loadMore={_loadMoreReports} setSelection={setSelection}/>
              : props.page === 'team' ?
                <Team userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} users={users} setItems={setItems} setItem={setItem}/>
              : props.page === 'companies' ?
                <Companies userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} users={users} items={items} setItems={setItems} setItem={setItem} loadMore={_loadMoreReports} setSelection={setSelection} selection={selection}/>
              : props.page === 'activity' ?
                <Activity userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} activity={activity} setItems={setItems} setItem={setItem} users={users} loadMore={_loadMoreActivity}/>
              : props.page === 'settings' ?
                <Settings userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} organization={organization} categories={categories} languages={languages}/>
              : props.page === 'reports' && params && params.id ?
                <Report userData={props.userData} setUserData={props.setUserData} itemId={params.id} item={item} isLoading={isLoading} users={users} setItem={setItem}/>
              : props.page === 'reports' ?
                <Reports userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} users={users} items={items} setItems={setItems} setItem={setItem} loadMore={_loadMoreReports} setSelection={setSelection} selection={selection}/>
              : <Reports userData={props.userData} setUserData={props.setUserData} isLoading={isLoading} users={users} items={items} setItems={setItems} setItem={setItem} loadMore={_loadMoreReports} setSelection={setSelection} selection={selection}/>
            }
        </div>
      </div>
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

export default MainPartner;