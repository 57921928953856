import { Breadcrumb, IIconProps, IconButton, SpinnerSize } from '@fluentui/react';
import Titlebar from '../Titlebar/Titlebar';
import './Header.scss';
import { useEffect, useState } from 'react';
import i18n from "i18next";
import { history } from '../../_helpers';
import {
    bundleIcon,
    DocumentFilled,
    DocumentRegular,
    SettingsFilled,
    SettingsRegular,
    PeopleFilled,
    PeopleRegular,
    BroadActivityFeedFilled,
    BroadActivityFeedRegular,
    EditRegular,
    AddRegular,
    DeleteRegular,
    PersonAvailableRegular,
    Circle12Filled,
    Circle12Regular,
    FilterFilled,
    FilterRegular,
    CityFilled,
    CityRegular,
    MailReadFilled,
    MailReadRegular,
    MailInboxFilled,
    MailInboxRegular,
    BoardFilled,
    BoardRegular,
    ArrowExportLtrRegular,
    PanelLeft28Regular,
    PanelRight28Regular,
} from "@fluentui/react-icons";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger, Dropdown, Input, Label, Menu, MenuButton, MenuItemRadio, MenuList, MenuPopover, MenuTrigger, Overflow, OverflowItem, Popover, PopoverSurface, PopoverTrigger, Spinner, Option } from '@fluentui/react-components';
import { DatePicker } from "@fluentui/react-datepicker-compat";
import { userService } from '../../_services/user.services';
import { Spinner as OldSpinner } from '@fluentui/react' ;
import priorities from '../../_constants/priorities.json'
import Moment from 'moment';

function Header(props:any) {

    const [sidebarWidth, setSidebarWidth] = useState(Number(window.localStorage.sidebarWidth) || 48);
    const [openDetailsPanel, setOpenDetailsPanel] = useState(props.openDetailsPanel);
    const [newUserDialog, setNewUserDialog] = useState(false);
    const [editUserDialog, setEditUserDialog] = useState(false);
    const [disableUserDialog, setDisableUserDialog] = useState(false);
    const [enableUserDialog, setEnableUserDialog] = useState(false);
    const [newCompanyDialog, setNewCompanyDialog] = useState(false);
    const [editCompanyDialog, setEditCompanyDialog] = useState(false);
    const [currentUserId, setCurrentUserId] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyDomain, setCompanyDomain] = useState('');
    const [submiting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleNavigationClick = () => {
        let value = sidebarWidth === 48 ? 200 : 48;
        props.setSidebarWidth(value);
        window.localStorage.setItem('sidebarWidth', value.toString());
    }

    const handleDetailsClick = () => {
        let value = !openDetailsPanel;
        props.setOpenDetailsPanel(value);
        window.localStorage.setItem('detailsPanelStatus', value ? 'OPEN' : 'CLOSED');
    }

    const backIcon: IIconProps = { iconName: 'Back' };
    const forwardIcon: IIconProps = { iconName: 'Forward' };
    const refreshIcon: IIconProps = { iconName: 'Refresh' };
    const DocumentIcon = bundleIcon(DocumentFilled, DocumentRegular);
    const SettingsIcon = bundleIcon(SettingsFilled, SettingsRegular);
    const PeopleIcon = bundleIcon(PeopleFilled, PeopleRegular);
    const ActivityIcon = bundleIcon(BroadActivityFeedFilled, BroadActivityFeedRegular);
    const FilterIcon = bundleIcon(FilterFilled, FilterRegular);
    const CompanyIcon = bundleIcon(CityFilled, CityRegular);
    const MailReadIcon = bundleIcon(MailReadFilled, MailReadRegular);
    const InboxIcon = bundleIcon(MailInboxFilled, MailInboxRegular);
    const BoardIcon = bundleIcon(BoardFilled, BoardRegular);

    const _updateStatus = (data) => {
        const requestOptions = {
            importance: props.item.importance,
            entityStatus:  data.checkedItems[0],
            dueDate: props.item.dueDate,
            assignedUsers: props.item.assignedUsers,
            customPropertyValues: props.item.customPropertyValues
        }
        if (requestOptions.entityStatus === 'DELETE') {
            let item = props.item
            item.entityStatus = 'DELETE'
            props.updateItem(props.item.id, item)
            console.log('delete')
            return;
        }
        userService.updateReport(props.item.id, requestOptions).then((response)=>{
            props.updateItem(props.item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    const _updateImportance = (data) => {
        const requestOptions = {
            importance:  data.checkedItems[0] === '' ? null : data.checkedItems[0],
            entityStatus:  props.item.entityStatus,
            dueDate: props.item.dueDate,
            assignedUsers: props.item.assignedUsers,
            customPropertyValues: props.item.customPropertyValues
        }
        userService.updateReport(props.item.id, requestOptions).then((response)=>{
            props.updateItem(props.item.id, response.data)
        }).catch((error)=>{
            console.error(error)
        })
    }

    const _newUser = () => {
        setNewUserDialog(true)
        setEmail('')
        setName('')
        setSurname('')
        setErrorMessage('')
    }

    const _editUser = () => {
        setEditUserDialog(true)
        setEmail(props.item.email)
        setName(props.item.name)
        setSurname(props.item.surname)
        setErrorMessage('')
        setCurrentUserId(props.item.id)
    }

    const _disableUser = () => {
        setDisableUserDialog(true)
        setEmail(props.item.email)
        setName(props.item.name)
        setSurname(props.item.surname)
        setErrorMessage('')
        setCurrentUserId(props.item.id)
    }

    const _enableUser = () => {
        setEnableUserDialog(true)
        setEmail(props.item.email)
        setName(props.item.name)
        setSurname(props.item.surname)
        setErrorMessage('')
        setCurrentUserId(props.item.id)
    }

    const _newCompany = () => {
        setNewCompanyDialog(true)
        setErrorMessage('')
    }

    /*const _editCompany = () => {
        setEditCompanyDialog(true)
        setErrorMessage('')
    }*/

    const _submitNewUser = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        userService.createUser(email, name, surname).then((response)=>{
            setSubmitting(false)
            setNewUserDialog(false)
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorCreatingUser') || 'Error')
        })
    }

    const _submitEditUser = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        userService.editUser(currentUserId, name, surname).then((response)=>{
            setSubmitting(false)
            setEditUserDialog(false)
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorCreatingUser') || 'Error')
        })
    }

    const _submitDisableUser = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        userService.disableUser(currentUserId).then((response)=>{
            setSubmitting(false)
            setDisableUserDialog(false)
            setCurrentUserId('')
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorDisablingUser') || 'Error')
        })
    }

    const _submitEnableUser = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        userService.restoreUser(currentUserId).then((response)=>{
            setSubmitting(false)
            setEnableUserDialog(false)
            setCurrentUserId('')
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorDisablingUser') || 'Error')
        })
    }

    const _submitNewCompany = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        const data = { 
            organization : {
                domain: companyDomain,
                deploymentDomain: 'compliance.synergyos.com',
                name: companyName
            }
        }
        userService.newCompany(data).then((response)=>{
            setSubmitting(false)
            setNewCompanyDialog(false)
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorCreatingCompany') || 'Error')
        })
    }

    const _submitEditCompany = (e) => {
        e.preventDefault();
        setSubmitting(true)
        setErrorMessage('')
        userService.createUser(email, name, surname).then((response)=>{
            setSubmitting(false)
            setNewUserDialog(false)
            props.getContent(true)
        }).catch((error) => {
            console.error(error)
            setSubmitting(false)
            setErrorMessage(i18n.t('app:errorCreatingUser') || 'Error')
        })
    }

    const clearNotifications = () => {
        userService.clearNotifications(props.item.id).then((response)=>{
            let item = props.item
            item.newMessages = 0;
            props.updateItem(props.item.id, item)
        })
    }

    const _getAssignedUsers = (users, assignedUsers:any) => {    
        if (users && assignedUsers) {
          let assigned: any[] = users.filter((user)=>{
            return assignedUsers.includes(user.id)
          });
          assigned = assigned.map((item) => {
            return item.email
          })
          return assigned.join(', ')
        }
    
        return ''
    }

    const _getUserByID = (users = [], id: string) => {
        if (id && users) {
            if (props.userData && props.userData.organization.id === id) return props.userData.organization.name;
            let user:any = users.find((user:any)=>{return user.id === id})
            if (user) {
                return user.name + ' ' + user.surname
            } else return ''
        } else return ''
    }

    const exportExcel = () => {

        let items:any[] = []

        if (props.itemId) {
            const item = props.item;
            items = [item]
        } else {
            items = props.items
        }

        let n = 0
        let content:any[] = [];

        items.forEach((item:any) => {
            userService.getMessages(item.id, 0).then((response) => {
                userService.getInternalMessages(item.id, 0).then((res) => {
                    content.push({
                        subject: item.subject,
                        dueDate: new Date(item.dueDate),
                        modifiedDateTime: new Date(item.modifiedDateTime),
                        createdDateTime: new Date(item.createdDateTime),
                        reportedBy: item.author ?? i18n.t('app:anonymous'),
                        status: i18n.t('app:'+item.entityStatus),
                        priority: i18n.t('app:'+item.importance),
                        assignedUsers: _getAssignedUsers(props.users, item.assignedUsers),
                        description: item.description,
                        messages: response.data.content.map((message) => {return _getUserByID(props.users,message.createdByUserId) + ' [' + Moment(item.createdDateTime).format('YY-MM-DD') + ']\n' + message.content}).join('\n'),
                        internalNotes: res.data.content.map((message) => {return _getUserByID(props.users,message.createdByUserId) + ' [' + Moment(item.createdDateTime).format('YY-MM-DD') + ']\n' + message.content}).join('\n')
                    })
                    n+=1;

                    if (n === items.length) {
                        var ws_name = props.itemId ? i18n.t('app:report') + ' - '+item.subject : i18n.t('app:reports') + ' - ' + Moment().format('YY-MM-DD') ;
                        ws_name = ws_name.substring(0, Math.min(ws_name.length, 31));
                        const XLSX = require('sheetjs-style');
                        var ws = XLSX.utils.json_to_sheet(content)//XLSX.utils.aoa_to_sheet(ws_data);
                        var wb = XLSX.utils.book_new();
                        XLSX.utils.book_append_sheet(wb, ws, ws_name);
                        XLSX.utils.sheet_add_aoa(ws, [[i18n.t('app:subject'), i18n.t('app:dueDate'), i18n.t('app:modifiedDate'), i18n.t('app:createdDate'), i18n.t('app:reportedBy'), i18n.t('app:status'), i18n.t('app:priority'), i18n.t('app:assignedTo'), i18n.t('app:details'), i18n.t('app:messages'), i18n.t('app:internalNotes')]], { origin: "A1" });
                        const max_width_subject = content.reduce((w, r) => Math.max(w, r.subject.length), 10);
                        const max_width_status = content.reduce((w, r) => Math.max(w, r.status.length), 10);
                        const max_width_priority = content.reduce((w, r) => Math.max(w, r.priority.length), 10);
                        ws["!cols"] = [ { wch: max_width_subject }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, {wch: max_width_status}, { wch: max_width_priority }, { wch: 50 }, { wch: 50 }, { wch: 50 }, { wch: 50 }];
                        const headerStyle = {
                            font: {
                            name: 'Arial',
                            bold: true,
                            color: {
                                rgb: 'FFFFFFFF'
                            }
                            },
                            fill: {
                            bgColor: {
                                rgb: 'FF0F6CBD'
                            },
                            fgColor: {
                                rgb: 'FF0F6CBD'
                            }
                            }
                        }
                        ws["A1"].s = headerStyle;ws["B1"].s = headerStyle;ws["C1"].s = headerStyle;ws["D1"].s = headerStyle;ws["E1"].s = headerStyle;ws["F1"].s = headerStyle;ws["G1"].s = headerStyle;ws["H1"].s = headerStyle;ws["I1"].s = headerStyle;ws["J1"].s = headerStyle;ws["K1"].s = headerStyle;
                        XLSX.writeFile(wb, ws_name+".xlsx", { compression: true });
                    }
                })
            })
        })
    }
    
    useEffect(()=>{
        setSidebarWidth(props.sidebarWidth)
        setOpenDetailsPanel(props.openDetailsPanel)
    }, [props.sidebarWidth, props.openDetailsPanel])

    return (
        <>
            <Titlebar/>
            <div className="Header">
                <div className="d-flex flex-row" style={{height: '48px'}}>
                    <div className="history-actions">
                        <IconButton iconProps={backIcon} title={i18n.t('app:back') || 'Back'} ariaLabel="Back" onClick={ ()=>{history.back()} }  />
                        <IconButton iconProps={forwardIcon} title={i18n.t('app:forward') || 'Forward'} ariaLabel="Forward" onClick={ ()=>{history.forward()} } />
                    </div>
                    <div className="refresh-action">
                        {!props.isLoading ?
                        <IconButton iconProps={refreshIcon} title={i18n.t('app:refresh') || 'Refresh'} ariaLabel="Refresh" onClick={()=>{props.getContent()}} />
                        :
                        <div className="ms-Spinner-wrap">
                            <OldSpinner size={SpinnerSize.small} />
                        </div>
                        }
                    </div>
                    <div className="breadcrumb flex-grow-1 me-3" style={{minWidth:'60px'}}>
                        <div className="d-flex w-100 m-0 p-0">
                        <div className="breadcrumb-icon ms-2">
                            {props.page === 'inbox' ? <InboxIcon/> : props.page === 'dashboard' ? <BoardIcon/> : props.page === 'reports' ? <DocumentIcon/> : props.page === 'team' ? <PeopleIcon/> : props.page === 'activity' ? <ActivityIcon/> : props.page === 'companies' ? <CompanyIcon/> : <SettingsIcon/>}
                        </div>

                        <div className="col m-0 p-0" style={{minWidth:'30px'}}>
                            <Breadcrumb
                                items={ props.breadcrumb }
                                ariaLabel="Breadcrumb"
                                overflowAriaLabel="More items"
                                overflowIndex={ 0 }
                                //onRenderItem={ this._onRenderBreadcrumbItem }
                            />
                        </div>
                    </div>
                </div>
                </div>
                <div className="d-flex flex-row" style={{height: '48px'}}>
                    <Button className='sidepanel-button' icon={<PanelLeft28Regular style={{color: '#242424'}}/>} appearance='subtle' style={{width: '40px', minWidth: '40px'}} onClick={handleNavigationClick}/>
                    {(props.page === 'reports' || props.page === 'dashboard') && props.type === 'PARTNER' && <Overflow>
                        <div className='w-100'>
                            {props.page === 'reports' && <OverflowItem key={'3'} id={'3'}>
                                <Popover>
                                    <PopoverTrigger disableButtonEnhancement>
                                    <Button className='toolbar-button' icon={<FilterIcon/>}>{i18n.t('app:filters')}</Button>
                                    </PopoverTrigger>

                                    <PopoverSurface>
                                        <Filters {...props}/>
                                    </PopoverSurface>
                                </Popover>
                            </OverflowItem>}
                        </div>
                    </Overflow>}
                    {(props.page === 'reports' || props.page === 'dashboard' || props.page === 'inbox') && props.type !== 'PARTNER' &&  <Overflow>
                        <div className='w-100'>
                            <OverflowItem key={'1'} id={'1'}>
                                <Menu checkedValues={{ status: [ props.item ? props.item.entityStatus : ''] }} onCheckedValueChange={(e,data:any)=>{_updateStatus(data)}}>
                                    <MenuTrigger disableButtonEnhancement>
                                    <MenuButton disabled={!props.item} className='toolbar-button' icon={props.item && props.item.entityStatus ? <Circle12Filled style={{color: props.item && props.item.entityStatus ? priorities[props.item.entityStatus] : priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/> : <Circle12Regular/>}>{props.item ? i18n.t('app:'+props.item.entityStatus) : i18n.t('app:status')}</MenuButton>
                                    </MenuTrigger>

                                    <MenuPopover>
                                    <MenuList>
                                        <MenuItemRadio value={'NEW'} name={'status'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['NEW'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:NEW')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'OPEN'} name={'status'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['OPEN'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:OPEN')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'CLOSE'} name={'status'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:CLOSE')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'SPAM'} name={'status'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['SPAM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:SPAM')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'DELETE'} name={'status'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['DELETE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:DELETE')}</div></MenuItemRadio>
                                    </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </OverflowItem>
                            <OverflowItem key={'2'} id={'2'}>
                                <Menu checkedValues={{ importance: [ props.item ? props.item.importance : ''] }} onCheckedValueChange={(e,data:any)=>{_updateImportance(data)}}>
                                    <MenuTrigger disableButtonEnhancement>
                                    <MenuButton disabled={!props.item} className='toolbar-button' icon={ props.item && props.item.importance ? <Circle12Filled style={{color: props.item && props.item.importance ? priorities[props.item.importance]  : priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/> : <Circle12Regular/>}>{props.item && props.item.importance ? i18n.t('app:'+props.item.importance) : i18n.t('app:priority')}</MenuButton>
                                    </MenuTrigger>

                                    <MenuPopover>
                                    <MenuList>
                                        <MenuItemRadio value={''} name={'importance'}>{'---'}</MenuItemRadio>
                                        <MenuItemRadio value={'URGENT'} name={'importance'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['URGENT'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:URGENT')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'HIGH'} name={'importance'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['HIGH'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:HIGH')}</div></MenuItemRadio>
                                        <MenuItemRadio value={'MEDIUM'} name={'importance'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['MEDIUM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:MEDIUM')}</div></MenuItemRadio>
                                    </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </OverflowItem>
                            <OverflowItem key={'4'} id={'4'}>
                                <Button onClick={clearNotifications} disabled={!props.item || (props.item && !props.item.newMessages)} className='toolbar-button' icon={<MailReadIcon/>}>{i18n.t('app:markAsRead')}</Button>
                            </OverflowItem>
                            <OverflowItem key={'5'} id={'5'}>
                                <Button onClick={exportExcel} disabled={!props.item && !props.items} className='toolbar-button' icon={<ArrowExportLtrRegular/>}>{i18n.t(props.itemId ? 'app:exportReport' : 'app:exportReports')}</Button>
                            </OverflowItem>
                            {props.page === 'reports' && <OverflowItem key={'3'} id={'3'}>
                                <Popover>
                                    <PopoverTrigger disableButtonEnhancement>
                                    <Button className='toolbar-button' icon={<FilterIcon/>}>{i18n.t('app:filters')}</Button>
                                    </PopoverTrigger>

                                    <PopoverSurface>
                                        <Filters/>
                                    </PopoverSurface>
                                </Popover>
                            </OverflowItem>}
                        </div>
                    </Overflow>
                    }
                    {props.page === 'companies' && <Overflow>
                        <div className='w-100'>
                            <OverflowItem key={'1'} id={'1'}>
                                <Button onClick={_newCompany} className='toolbar-button' icon={<AddRegular/>} appearance='subtle'>{i18n.t('app:newCompany')}</Button>
                            </OverflowItem>
                        </div>
                    </Overflow>}
                    {props.page === 'team' && <Overflow>
                        <div className='w-100'>
                            <OverflowItem key={'1'} id={'1'}>
                                <Button onClick={_newUser} className='toolbar-button' icon={<AddRegular/>} appearance='subtle'>{i18n.t('app:newUser')}</Button>
                            </OverflowItem>
                            <OverflowItem key={'2'} id={'2'}>
                                <Button onClick={_editUser}  disabled={!props.item} className='toolbar-button' icon={<EditRegular/>} appearance='subtle'>{i18n.t('app:editUser')}</Button>
                            </OverflowItem>
                            <OverflowItem key={'3'} id={'3'}>
                                <Button onClick={props.item && props.item.state === 'REMOVED' ? _enableUser : _disableUser}  disabled={!props.item} className='toolbar-button' icon={props.item && props.item.state === 'REMOVED' ? <PersonAvailableRegular/> : <DeleteRegular/>} appearance='subtle'>{props.item && props.item.state === 'REMOVED' ? i18n.t('app:enableUser') : i18n.t('app:disableUser')}</Button>
                            </OverflowItem>
                        </div>
                    </Overflow>}
                    {props.page === 'activity' && <Overflow>
                        <div className='w-100'>
                            <OverflowItem key={'1'} id={'1'}>
                                <Popover>
                                    <PopoverTrigger disableButtonEnhancement>
                                    <Button className='toolbar-button' icon={<FilterIcon/>}>{i18n.t('app:filters')}</Button>
                                    </PopoverTrigger>
                                    <PopoverSurface>
                                        <ActivityFilters/>
                                    </PopoverSurface>
                                </Popover>
                            </OverflowItem>
                        </div>
                    </Overflow>}
                    {props.page !== 'settings' && props.page !== 'team' && props.page !== 'activity' && props.type !== 'PARTNER' && <Button className='sidepanel-button ms-auto me-1' icon={<PanelRight28Regular style={{color: '#242424'}}/>} appearance='subtle' style={{width: '40px', minWidth: '40px'}} onClick={handleDetailsClick}/>}
                </div>
            </div>

            <Dialog open={newUserDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:newUser')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitNewUser}>
                            <div className='mt-2'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:email')}:</Label>
                                <div className='mt-2'>
                                    <Input required type='email' value={email} onChange={(e,data)=>{setEmail(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:firstName')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={name} onChange={(e,data)=>{setName(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:lastName')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={surname} onChange={(e,data)=>{setSurname(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setNewUserDialog(false)}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:save')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={editUserDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:editUser')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitEditUser}>
                            <div className='mt-2'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:email')}:</Label>
                                <div className='mt-2'>
                                    <span>{email}</span>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:firstName')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={name} onChange={(e,data)=>{setName(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:lastName')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={surname} onChange={(e,data)=>{setSurname(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setEditUserDialog(false)}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:save')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={disableUserDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:disableUser')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitDisableUser}>
                            <span>{i18n.t('app:confirmDisableUser')}</span>
                            <div className='mt-2'>
                            <span style={{fontWeight: 600}}>{name + ' ' + surname + ' ' + email}</span>
                            </div>

                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setDisableUserDialog(false); setCurrentUserId('')}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:confirm')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={enableUserDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:enableUser')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitEnableUser}>
                            <span>{i18n.t('app:confirmEnableUser')}</span>
                            <div className='mt-2'>
                            <span style={{fontWeight: 600}}>{name + ' ' + surname + ' ' + email}</span>
                            </div>

                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setEnableUserDialog(false); setCurrentUserId('')}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:confirm')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={newCompanyDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:newCompany')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitNewCompany}>
                            <div className='mt-2'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:companyDomain')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={companyDomain} onChange={(e,data)=>{setCompanyDomain(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:companyName')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={companyName} onChange={(e,data)=>{setCompanyName(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setNewCompanyDialog(false)}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:save')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

            <Dialog open={editCompanyDialog}>
                <DialogSurface>
                <DialogBody>
                    <DialogTitle>{i18n.t('app:editCompany')}</DialogTitle>
                    <DialogContent>
                        <form onSubmit={_submitEditCompany}>
                            <div className='mt-2'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:companyName')}:</Label>
                                <div className='mt-2'>
                                    <Input required type='email' value={email} onChange={(e,data)=>{setEmail(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:country')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={name} onChange={(e,data)=>{setName(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:numberEmployees')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={surname} onChange={(e,data)=>{setSurname(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            <div className='mt-3'>
                                <Label className='me-2' weight='semibold'>{i18n.t('app:advisor')}:</Label>
                                <div className='mt-2'>
                                    <Input required value={surname} onChange={(e,data)=>{setSurname(data.value)}} className='w-100'/>
                                </div>
                            </div>
                            { errorMessage && errorMessage.length > 0 &&
                                <div className='alert my-3 alert-danger'>{errorMessage}</div>
                            }
                            <DialogActions className='mt-3'>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} onClick={()=>{setEditCompanyDialog(false)}} className='ms-auto'>{i18n.t('app:cancel')}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button disabled={submiting} type='submit' className='ms-2' appearance="primary">{submiting ? <Spinner size="tiny" /> : i18n.t('app:save')}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </form>
                    </DialogContent>
                </DialogBody>
                </DialogSurface>
            </Dialog>

        </>

    );
}

function Filters(props:any) {

    const queryParams = new URLSearchParams(window.location.search)

    const [priority, setPriority]:any = useState(queryParams.get('priority') || '---');
    const [status, setStatus]:any = useState(queryParams.get('status') || '---');
    const [companies, setCompanies]:any = useState(queryParams.get('companies')?.split(',') || []);

    const _save = () => {

        if (status && status !== '---') {
            queryParams.set('status',status)
        } else queryParams.delete('status')
        if (priority && priority !== '---') { 
            queryParams.set('priority',priority)
        } else queryParams.delete('priority')
        if (companies && companies.length > 0) { 
            queryParams.set('companies',companies)
        } else queryParams.delete('companies')

        history.push('?'+queryParams.toString())
    }

    const _clear = () => {
        setStatus('---')
        setPriority('---')
        setCompanies([])
        history.push('')
    }

    return (
        <div style={{maxWidth: '300px'}}>
            <Label weight='semibold'>{i18n.t('app:status')}</Label>
            <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' value={i18n.t('app:'+status) || '-'} selectedOptions={[status]} onOptionSelect={(e,data:any)=>{setStatus(data.optionValue)}}>
                <Option value={'---'} text={'---'}><div className='d-flex align-items-center'><Circle12Regular style={{color: priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/>{'---'}</div></Option>
                <Option value={'NEW'} text={i18n.t('app:NEW') || 'NEW'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['NEW'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:NEW')}</div></Option>
                <Option value={'OPEN'} text={i18n.t('app:OPEN') || 'OPEN'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['OPEN'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:OPEN')}</div></Option>
                <Option value={'CLOSE'} text={i18n.t('app:CLOSE') || 'CLOSE'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:CLOSE')}</div></Option>
                <Option value={'SPAM'} text={i18n.t('app:SPAM') || 'SPAM'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['SPAM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:SPAM')}</div></Option>
                <Option value={'DELETE'} text={i18n.t('app:DELETE') || 'DELETE'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['DELETE'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:DELETE')}</div></Option>
            </Dropdown>
            <Label className='mt-3' weight='semibold'>{i18n.t('app:priority')}</Label>
            <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' value={i18n.t('app:'+priority) || '-'} selectedOptions={[priority]} onOptionSelect={(e,data:any)=>{setPriority(data.optionValue)}}>
                <Option value={'---'} text={'---'}><div className='d-flex align-items-center'><Circle12Regular style={{color: priorities['CLOSE'], marginTop: '2px', marginRight: '8px'}}/>{'---'}</div></Option>
                <Option value={'URGENT'} text={i18n.t('app:URGENT') || 'URGENT'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['URGENT'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:URGENT')}</div></Option>
                <Option value={'HIGH'} text={i18n.t('app:HIGH') || 'HIGH'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['HIGH'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:HIGH')}</div></Option>
                <Option value={'MEDIUM'} text={i18n.t('app:MEDIUM') || 'MEDIUM'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['MEDIUM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:MEDIUM')}</div></Option>
            </Dropdown>
            { props.type === 'PARTNER' && <>
            <Label className='mt-3' weight='semibold'>{i18n.t('app:companies')}</Label>
            <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' multiselect selectedOptions={companies} value={companies.join(',')} onOptionSelect={(e,data:any)=>{companies.includes(data.optionValue) ? setCompanies(companies.filter((item)=>{return item !== data.optionValue})) : setCompanies([...companies, data.optionValue])}}>
                <Option value={'URGENT'} text={i18n.t('app:URGENT') || 'URGENT'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['URGENT'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:URGENT')}</div></Option>
                <Option value={'HIGH'} text={i18n.t('app:HIGH') || 'HIGH'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['HIGH'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:HIGH')}</div></Option>
                <Option value={'MEDIUM'} text={i18n.t('app:MEDIUM') || 'MEDIUM'}><div className='d-flex align-items-center'><Circle12Filled style={{color: priorities['MEDIUM'], marginTop: '2px', marginRight: '8px'}}/>{i18n.t('app:MEDIUM')}</div></Option>
            </Dropdown>
            </>

            }
            <div className='mt-3 w-100 d-flex'>
            <Button className='ms-auto' onClick={_clear}>{i18n.t('app:clear')}</Button>
            <Button className='ms-3' appearance='primary' onClick={_save}>{i18n.t('app:save')}</Button>
            </div>
        </div>
    )
}

function ActivityFilters(props:any) {

    var activityMessages = require('../../locales/en/activity-messages.json')
    if (i18n.language === 'es') {
      activityMessages = require('../../locales/es/activity-messages.json')
    }

    const queryParams = new URLSearchParams(window.location.search)

    const [activity, setActivity]:any = useState('---');
    const [startDate, setStartDate] = useState<Date | null | undefined>(null);
    const [endDate, setEndDate] = useState<Date | null | undefined>(null);

    const _save = () => {

        if (activity && activity !== '---') {
            queryParams.set('operationType',activityMessages[activity].operationType)
            queryParams.set('modelType',activityMessages[activity].modelType)
            if (activityMessages[activity].field) queryParams.set('field',activityMessages[activity].field)
            else queryParams.delete('field')
        } else {
            queryParams.delete('operationType')
            queryParams.delete('modelType')
            queryParams.delete('field')
        }
        if (startDate) {
            console.log(startDate)
            queryParams.set('initialDate',startDate.getTime().toString())
        } else queryParams.delete('initialDate')
        if (endDate) {
            queryParams.set('finalDate',endDate.getTime().toString())
        } else queryParams.delete('finalDate')

        history.push('?'+queryParams.toString())
    }

    const _clear = () => {
        setActivity('---')
        setStartDate(null)
        setEndDate(null)
        history.push('')
    }

    return (
        <div style={{maxWidth: '300px'}}>
            <Label weight='semibold'>{i18n.t('app:activity')}</Label>
            <Dropdown style={{minWidth: '100px'}} className='mt-1 w-100' value={activityMessages[activity]?.text || '---'} selectedOptions={[activity]} onOptionSelect={(e,data:any)=>{setActivity(data.optionValue)}}>
                <Option value={'---'} text={'---'}>---</Option>
                <Option value={'CATEGORY_CREATION'} text={activityMessages['CATEGORY_CREATION'].text}>{activityMessages['CATEGORY_CREATION'].text}</Option>
                <Option value={'CATEGORY_UPDATE'} text={activityMessages['CATEGORY_UPDATE'].text}>{activityMessages['CATEGORY_UPDATE'].text}</Option>
                <Option value={'CATEGORY_DELETE'} text={activityMessages['CATEGORY_DELETE'].text}>{activityMessages['CATEGORY_DELETE'].text}</Option>
                <Option value={'ORGANIZATION_UPDATE'} text={activityMessages['ORGANIZATION_UPDATE'].text}>{activityMessages['ORGANIZATION_UPDATE'].text}</Option>
                <Option value={'POLICY_UPDATE'} text={activityMessages['POLICY_UPDATE'].text}>{activityMessages['POLICY_UPDATE'].text}</Option>
                <Option value={'REPORT_CREATE'} text={activityMessages['REPORT_CREATE'].text}>{activityMessages['REPORT_CREATE'].text}</Option>
                <Option value={'REPORT_UPDATE'} text={activityMessages['REPORT_UPDATE'].text}>{activityMessages['REPORT_UPDATE'].text}</Option>
                <Option value={'REPORT_UPDATE_DUEDATE'} text={activityMessages['REPORT_UPDATE_DUEDATE'].text}>{activityMessages['REPORT_UPDATE_DUEDATE'].text}</Option>
                <Option value={'REPORT_UPDATE_STATUS'} text={activityMessages['REPORT_UPDATE_STATUS'].text}>{activityMessages['REPORT_UPDATE_STATUS'].text}</Option>
                <Option value={'REPORT_UPDATE_PRIORITY'} text={activityMessages['REPORT_UPDATE_PRIORITY'].text}>{activityMessages['REPORT_UPDATE_PRIORITY'].text}</Option>
                <Option value={'REPORT_UPDATE_USERS'} text={activityMessages['REPORT_UPDATE_USERS'].text}>{activityMessages['REPORT_UPDATE_USERS'].text}</Option>
                <Option value={'REPORT_CREATE_MESSAGE'} text={activityMessages['REPORT_CREATE_MESSAGE'].text}>{activityMessages['REPORT_CREATE_MESSAGE'].text}</Option>
                <Option value={'REPORT_CREATE_INTERNAL_MESSAGE'} text={activityMessages['REPORT_CREATE_INTERNAL_MESSAGE'].text}>{activityMessages['REPORT_CREATE_INTERNAL_MESSAGE'].text}</Option>
                <Option value={'USER_CREATE'} text={activityMessages['USER_CREATE'].text}>{activityMessages['USER_CREATE'].text}</Option>
                <Option value={'USER_UPDATE'} text={activityMessages['USER_UPDATE'].text}>{activityMessages['USER_UPDATE'].text}</Option>
                <Option value={'USER_DELETE'} text={activityMessages['USER_DELETE'].text}>{activityMessages['USER_DELETE'].text}</Option>
                <Option value={'USER_RESTORE'} text={activityMessages['USER_RESTORE'].text}>{activityMessages['USER_RESTORE'].text}</Option>
                <Option value={'USER_SIGNIN'} text={activityMessages['USER_SIGNIN'].text}>{activityMessages['USER_SIGNIN'].text}</Option>
            </Dropdown>
            <Label className='mt-3' weight='semibold'>{i18n.t('app:initialDate')}</Label>
            <div>
                <DatePicker className='w-100' value={startDate || undefined} onSelectDate={setStartDate}/>
            </div>
            <Label className='mt-3' weight='semibold'>{i18n.t('app:finalDate')}</Label>
            <div>
                <DatePicker className='w-100' value={endDate || undefined} onSelectDate={setEndDate}/>
            </div>
            <div className='mt-3 w-100 d-flex'>
            <Button className='ms-auto' onClick={_clear}>{i18n.t('app:clear')}</Button>
            <Button className='ms-3' appearance='primary' onClick={_save}>{i18n.t('app:save')}</Button>
            </div>
        </div>
    )
}

export default Header;