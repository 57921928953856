import { Circle12Filled } from "@fluentui/react-icons";
import priorities from '../_constants/priorities.json'
import i18n from "i18next";
import Moment from 'moment';
import { Persona } from "@fluentui/react-components";

const _getUserByID = (id: string, users = []) => {
  if (id && users) {
    return users.find((user:any)=>{return user.id === id}) || {};
  } else return {}
}

export function getActivityDetails(activityMessages, activity, users) {
    if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'REPORT') {
      return (
        activity.changes.map((change, i)=>{
          if (change.field === 'entityStatus') {
            return <div key={i} className="d-flex">
              {i18n.t('app:updatedStatusFrom')}
              <div className="mx-1" title={ i18n.t('app:'+change.oldValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.oldValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.oldValues[0])}</div>
              </div>
              {i18n.t('app:to')}
              <div className="mx-1" title={ i18n.t('app:'+change.newValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.newValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.newValues[0])}</div>
              </div>
            </div>
          } else if (change.field === 'importance') {
            if (change.oldValues && change.newValues) {
              return <div key={i} className="d-flex">
              {i18n.t('app:updatedPriorityFrom')}
              <div className="mx-1" title={ i18n.t('app:'+change.oldValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.oldValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.oldValues[0])}</div>
              </div>
              {i18n.t('app:to')}
              <div className="mx-1" title={ i18n.t('app:'+change.newValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.newValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.newValues[0])}</div>
              </div>
            </div>
            } else if (change.oldValues) {
              return <div key={i} className="d-flex">
              {i18n.t('app:removedPriority')}
              <div className="mx-1" title={ i18n.t('app:'+change.oldValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.oldValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.oldValues[0])}</div>
              </div>
            </div>
            } else if (change.newValues) {
              return <div key={i} className="d-flex">
              {i18n.t('app:updatedPriorityTo')}
              <div className="mx-1" title={ i18n.t('app:'+change.newValues[0]) || ''  }>
                <div className='d-flex align-items-center'><Circle12Filled style={{color: priorities[change.newValues[0]], marginTop: '2px', marginRight: '2px'}}/>{i18n.t('app:'+change.newValues[0])}</div>
              </div>
            </div>
            } else return <></>
          } else if (change.field === 'dueDate') {
            const date:any = Number(change.newValues[0]);
            return <div key={i} className="d-flex">
              {i18n.t('app:updatedDueDateTo')}
              <span className="mx-1">{Moment(date).format('YYYY-MMM-DD')}</span>
            </div>
          } else if (change.field === 'assignedUsers') {
            const oldValues = change.oldValues ?? [];
            const newValues = change.newValues ?? [];
            let addedUserIds = newValues.filter((userId)=>{
              return !oldValues.includes(userId)
            })
            let removedUserIds = oldValues.filter((userId)=>{
              return !newValues.includes(userId)
            })
            if (removedUserIds && removedUserIds.length > 0) {
              let user:any = _getUserByID(removedUserIds[0], users)
              return <div key={i} className="d-flex">
              <div className="d-flex align-items-center">
                <span className="me-2">{i18n.t('app:removedUser')}</span>
                <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
                <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
              </div>
            </div>
            } else if (addedUserIds && addedUserIds.length > 0) {
              let user:any = _getUserByID(addedUserIds[0], users)
              return <div key={i} className="d-flex">
              <div className="d-flex align-items-center">
                <span className="me-2">{i18n.t('app:assignedUser')}</span>
                <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
                <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
              </div>
            </div>
            } else return <></>
          } else if (activity.changes.length === 1 && change.field === 'modifiedDateTime') {
            return (
              <span key={i} title={ activityMessages[activity.operationType] ?? activity.operationType }>{ activityMessages[activity.operationType] ?? activity.operationType }</span>
            )
          } else return <></>
        })
      )
    } else if (activity.operationType === 'CREATE' && activity.targets && activity.targets[0] && activity.targets[0].modelType === 'MESSAGE') {
      return <div className="d-flex">
        {i18n.t('app:commentedOn')}
        <span className="mx-1">{activity.message === 'external' ?  i18n.t('app:messages') : i18n.t('app:internalNotes')}</span>
      </div>
    } else if (activity.operationType === 'CREATE' && activity.targets && activity.targets[0] && activity.targets[0].modelType === 'USER') {
      let user:any = _getUserByID(activity.targets[0].id, users)
      return <div className="d-flex align-items-center">
        {i18n.t('app:createdUser')}
        <div className="d-flex align-items-center mx-2">
          <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
          <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
        </div>
      </div>
    } else if (activity.operationType === 'CREATE' && activity.targets && activity.targets[0].modelType === 'REPORT') {
      return <div>
        {i18n.t('app:createdReport')}
      </div>
    } else if (activity.operationType === 'CREATE' && activity.targets && activity.targets[0].modelType === 'CATEGORY') {
      return <div>
        {i18n.t('app:createdCategory')}<span className="mx-1">{activity.targets[0].name}</span>
      </div>
    } else if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'CATEGORY') {
      return <div>
        {i18n.t('app:updatedCategoryFrom')}
        <span className="mx-1">{activity.changes[0].oldValues[0]}</span>
        {i18n.t('app:to')}
        <span className="mx-1">{activity.changes[0].newValues[0]}</span>
      </div>
    } else if (activity.operationType === 'DELETE' && activity.targets && activity.targets[0].modelType === 'CATEGORY') {
      return <div>
        {i18n.t('app:deletedCategory')}<span className="mx-1">{activity.targets[0].name}</span>
      </div>
    } else if (activity.operationType === 'DELETE' && activity.targets && activity.targets[0].modelType === 'USER') {
      let user:any = _getUserByID(activity.targets[0].id, users)
      return <div className="d-flex align-items-center">
        <span className="me-2">{i18n.t('app:disabledUser')}</span>
        <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
        <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
      </div>
    } else if (activity.operationType === 'RESTORE' && activity.targets && activity.targets[0].modelType === 'USER') {
      let user:any = _getUserByID(activity.targets[0].id, users)
      return <div className="d-flex align-items-center">
        <span className="me-2">{i18n.t('app:restoredUser')}</span>
        <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
        <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
      </div>
    } else if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'USER') {
      let user:any = _getUserByID(activity.targets[0].id, users)
      return <div className="d-flex align-items-center">
        <span className="me-2">{i18n.t('app:updatedUser')}</span>
        <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
        <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
      </div>
    } else if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'POLICY') {
      return <span className="">{i18n.t('app:updatedPolicy')}</span>
    } else if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'ORGANIZATION') {
      return <span className="">{i18n.t('app:updatedSettings')}</span>
    } else if (activity.operationType === 'UPDATE' && activity.targets && activity.targets[0].modelType === 'LANGUAGE') {
      return <span className="">{i18n.t('app:updatedLanguages')}</span>
    } else {
      return (
        <span title={ activityMessages[activity.operationType] ?? activity.operationType }>{ activityMessages[activity.operationType] ?? activity.operationType }</span>
      )
    }
}