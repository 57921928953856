import { Checkbox, DefaultButton, MessageBar, Modal, Text } from '@fluentui/react';
import Titlebar from '../../_components/Titlebar/Titlebar';
import './Login.scss';
import i18n from "i18next";
import { useState } from 'react';
import { Button, FluentProvider, Input, Spinner, webLightTheme, Link} from '@fluentui/react-components';
import { history } from '../../_helpers';
import { userService } from '../../_services/user.services';
import { EyeRegular } from '@fluentui/react-icons';

function Login(props:any) {

  const [sso, setSSO] = useState(false);
  const [alertMessage, setAlertMessage]:any = useState();
  const [email, setEmail]:any = useState("");
  const [password, setPassword]:any = useState("");
  const [staySignedIn, setStaySignedIn] = useState(true);
  const [loggingIn, setLoggingIn] = useState(false);
  const [signingAAD] = useState(false);
  const [signingSSO] = useState(false);
  const [waitingForRedirect, setWaitingForRedirect] = useState(false);
  const [serverDomainChosen, setServerDomainChosen] = useState(false);
  const [companyDomain, setCompanyDomain] = useState('')
  const [gettingServerDomain, setGettingServerDomain] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [twoFactor, setTwoFactor] = useState(false)
  const [warning, setWarning] = useState('')
  const [token, setToken] = useState('')
  const [code, setCode] = useState('')

    const _submit = (e:any) => {
        e.preventDefault();
        setLoggingIn(true);
        setAlertMessage(null)
        userService.login(email, password, companyDomain, staySignedIn).then((response) => {
            if (response.status === 201) {
                if (response.data['2fa']) {
                    setToken(response.data['2faToken'])
                    setTwoFactor(true)
                    setLoggingIn(false);
                    setWarning('');
                    setAlertMessage(null)
                } else {
                    userService.afterLogin(response,staySignedIn).then(()=>{
                        props.getUserData()
                    }).catch(reject => {
                        setAlertMessage("Unexpected error")
                        setLoggingIn(false);
                        console.log(reject)
                    })
                }
            }
        }).catch((error)=>{
            console.error(error)
            setLoggingIn(false);
            setAlertMessage(error?.data ?? error)
        })
    }

    const _submitCode = (e:any) => {
        e.preventDefault();
        setLoggingIn(true);
        setAlertMessage(null)
        userService.verifyEmailCode(token, code, staySignedIn).then((response) => {
            if (response.status === 201) {
                userService.afterLogin(response,staySignedIn).then(()=>{
                    props.getUserData()
                }).catch(reject => {
                    setWarning("invalidCode")
                    setLoggingIn(false);
                    console.log(reject)
                })

            }
        }).catch((error)=>{
            console.error(error)
            setLoggingIn(false);
            setWarning("invalidCode")
        })
    }

    const _loginAAD = (e:any) => {

    }

    var resend = () => {
        userService.login(email, password, companyDomain, staySignedIn).then((response)=>{
            if (response.status === 201) {
                setWarning("emailSent")
                setToken(response.data['2faToken'])
                setTwoFactor(true)
                setLoggingIn(false);
                setAlertMessage(null)
            } else {
                setWarning("cantResend")
            }
        })
      }

    const getServerDomain = (e?) => {
        if (e) e.preventDefault();
        setGettingServerDomain(true)
        setGettingServerDomain(false)
        setServerDomainChosen(true)
    }

    return (
        <div>
        <Titlebar/>
        { !serverDomainChosen ?
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >

                <Text variant={'xLarge'} className="lead">
                    {i18n.t('app:companyDomain')}
                </Text>

                { alertMessage &&
                    <div className={`alert my-3 alert-danger`}>
                        {alertMessage.status === 401 ?
                            "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                            : alertMessage.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                            : alertMessage === "Error: Network Error" ? "Server not found. Please try again."
                            : alertMessage.message === "Network Error" ? "Server not found. Please try again."
                            : alertMessage.message || alertMessage.message.data.message || "There was an error processing your request. Please, try again."}</div>
                }
                <FluentProvider theme={webLightTheme}>
                    <form name="form" onSubmit={getServerDomain} className="mt-2">
                        <Input type="text" placeholder={i18n.t('app:domainInput') || ''} name="companyDomain" value={companyDomain} onChange={(e,data)=>{setCompanyDomain(data.value)}} required autoFocus autoComplete="companyDomain" className="required-hidden mb-4 w-100" />
                        <div>
                            <Link style={{color: '#115ea3'}} href='https://about.synergyos.com/support'>
                                {i18n.t('app:cantAccessAccount')}
                            </Link>
                        </div>

                        <div className="d-flex form-group mt-3 mb-0">
                            <Button className='ms-auto' appearance='primary' disabled={gettingServerDomain} type="submit">{i18n.t('app:next')}</Button>
                            {gettingServerDomain &&
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" alt="Loading..." className="ml-2 "/>
                            }
                        </div>
                    </form>
                </FluentProvider>
            </Modal>
        : twoFactor ?
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >
                <FluentProvider theme={webLightTheme}>
                <div className="d-flex title align-items-center">
                    <img src={ process.env.PUBLIC_URL + "/logo192.png" } width={32} className="img-fluid me-3" alt="Synergy Home logo" />
                    <span style={{fontWeight: 600, fontSize: 20}}>Compliance channel</span>
                </div>

                <p className="lead m-0 mt-4">
                    {i18n.t('app:companyDomain')}
                </p>
                <Link href={'https://' + companyDomain} target="_blank" rel="noreferrer">{companyDomain}</Link>

                <p className="lead mt-4">
                    {i18n.t('app:enterCode')}
                </p>


                {   warning === "invalidCode" ?
                    <div className={`alert my-3 alert-danger`}>
                        {i18n.t('app:expiredCode')}
                    </div>
                    : warning === "cantResend" ?
                    <div className={`alert my-3 alert-danger`}>
                        {i18n.t('app:codeCantSend')}
                    </div>
                    : warning === "emailSent" ?
                    <div className={`alert my-3 alert-success`}>
                        {i18n.t('app:codeSent')}
                    </div>
                    : null
                }
                <form name="form" onSubmit={_submitCode}>
                    <Input placeholder={i18n.t('app:code') || 'Code'} value={code} onChange={(e,v)=>{setCode(v.value)}} required autoFocus className="required-hidden mb-3 w-100" />
                    <Link style={{color: '#115ea3'}} className="fake-url" onClick={resend}>
                        {i18n.t('app:resendEmail')}
                    </Link>

                    <div className="form-group mt-4 mb-0" style={{textAlign: 'right'}}>
                        {(window.location.host === 'workflow.dev.synergyos.com' || window.location.host === 'workflow.app.synergyos.com') && <DefaultButton className='me-2 cancel-button' text={i18n.t('app:back') || 'Back'}/>}
                        <Button className='me-2' onClick={()=>{setTwoFactor(false);setCode('')}}>{i18n.t('app:back')}</Button>
                        <Button appearance='primary' disabled={loggingIn} type="submit">{loggingIn ? <Spinner size='tiny'/> : i18n.t('app:send')}</Button>
                    </div>
                </form>
                </FluentProvider>
            </Modal>
        : !sso ?
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >
                <FluentProvider theme={webLightTheme}>
                <div className="d-flex title align-items-center">
                    <img src={ process.env.PUBLIC_URL + "/logo192.png" } width={32} className="img-fluid me-3" alt="Synergy Home logo" />
                    <span style={{fontWeight: 600, fontSize: 20}}>Compliance channel</span>
                </div>

                <p className="lead m-0 mt-4">
                    {i18n.t('app:companyDomain')}
                </p>
                <Link href={'https://' + companyDomain} target="_blank" rel="noreferrer">{companyDomain}</Link>

                <p className="lead mt-4">
                    {i18n.t('app:signIn')}
                </p>


                { alertMessage &&
                    <div className={`alert my-3 alert-danger`}>
                        {alertMessage.status === 401 ?
                            "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                            : alertMessage.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                            : alertMessage === "Error: Network Error" ? "Server not found. Please try again."
                            : alertMessage.message === "Network Error" ? "Server not found. Please try again."
                            : alertMessage.message || alertMessage.message.data.message || "There was an error processing your request. Please, try again."}</div>
                }
                <form name="form" onSubmit={_submit}>
                    <Input type="email" placeholder={i18n.t('app:email') || 'Email'} name="username" value={email} onChange={(e,v)=>{setEmail(v.value)}} required autoFocus autoComplete="username" className="required-hidden mb-3 w-100" />
                    <Input type={showPassword ? "text" : "password"} placeholder={i18n.t('app:password') || 'Password'} name="password" value={password} onChange={(e,v)=>{setPassword(v.value)}} required className="required-hidden mb-3 w-100 pe-0" contentAfter={<Button onClick={()=>{setShowPassword(!showPassword)}} appearance='subtle' icon={<EyeRegular/>}></Button>} />

                    <Checkbox
                        className='mt-2 mb-2'
                        label={i18n.t('app:staySignedIn') || 'Stay signed in'}
                        checked={staySignedIn}
                        onChange={()=>setStaySignedIn(!staySignedIn)}
                    />

                    <Link style={{color: '#115ea3'}} onClick={(()=>{history.push('/forgot-password?company='+companyDomain)})}>
                        {i18n.t('app:forgotPassword')}
                    </Link>

                    <div className="form-group mt-4 mb-0" style={{textAlign: 'right'}}>
                        {(window.location.host === 'workflow.dev.synergyos.com' || window.location.host === 'workflow.app.synergyos.com') && <DefaultButton className='me-2 cancel-button' text={i18n.t('app:back') || 'Back'}/>}
                        <Button className='me-2' onClick={()=>{setServerDomainChosen(false)}}>{i18n.t('app:back')}</Button>
                        <Button appearance='primary' disabled={loggingIn} type="submit">{loggingIn ? <Spinner size='tiny'/> : i18n.t('app:signIn')}</Button>
                    </div>
                </form>
                </FluentProvider>
            </Modal>
        :
            <Modal
                isOpen={true}
                isBlocking={true}
                className="modal-wrapper"
                isDarkOverlay={false}
                isModeless={true}
                containerClassName="form-modal p-5"
                scrollableContentClassName="scrollable-modal-content"
            >
                
                <div className="d-flex title align-items-center">
                    <img src={ process.env.PUBLIC_URL + "/logo192.png" } width={32} className="img-fluid me-3" alt="Synergy Home logo" />
                    <span style={{fontWeight: 600, fontSize: 20}}>Compliance channel</span>
                </div>

                <p className="lead mt-3 mb-5">
                    {i18n.t('app:signIn')}
                </p>


                { alertMessage && alertMessage.message &&
                    <div className={`alert my-3 ${alertMessage.type}`}>
                        {alertMessage.message.status === 401 ?
                            "Your account or password is incorrect. If you don't remember your password, contact your account administrator."
                            : alertMessage.message.status === 503 ? "Synergy is currently unavailable due to planned maintenance."
                            : alertMessage.message === "Error: Network Error" ? "Server not found. Please try again."
                            : alertMessage.message.message === "Network Error" ? "Server not found. Please try again."
                            : alertMessage.message.message || alertMessage.message.data.message || "There was an error processing your request. Please, try again."}</div>
                }

                <form name="form" onSubmit={_submit}>
                    <div className="text-center mb-0">
                        <DefaultButton onClick={_loginAAD} disabled={signingAAD || signingSSO} style={{border: '1px solid #bfbfbf', height: '32px', padding: '18px 50px'}}>
                            { signingAAD || signingSSO ? 
                            <>
                                <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="  style={{marginRight: '15px'}} alt="Loading..." />
                                <span style={{fontWeight: 600, color: '#5e5e5e'}}>{i18n.t('app:signInAAD')}</span>
                            </>
                            : <>
                                <img src={ process.env.PUBLIC_URL + "/img/ms-icon.svg" } style={{marginRight: '10px'}} alt="Microsoft logo" />
                                <span style={{fontWeight: 600, color: '#5e5e5e'}}>{i18n.t('app:signInAAD')}</span>
                            </>
                            }
                        </DefaultButton>
                    </div>
                    <Checkbox
                        className='mt-5'
                        label={i18n.t('app:staySignedIn') || 'Stay signed in'}
                        checked={staySignedIn}
                        onChange={()=>setStaySignedIn(!staySignedIn)}
                    />
                    <Link style={{color: '#115ea3'}} onClick={()=>{setSSO(false)}} className='mt-4 mb-0 pb-0'>Click here to sign in with your Synergy credentials instead</Link>
                    {waitingForRedirect && <MessageBar className='mt-2 d-flex align-items-center' onDismiss={()=>{setWaitingForRedirect(false)}}>
                        {i18n.t('app:waitingForBrowser')}
                    </MessageBar>}
                    {(window.location.host === 'workflow.dev.synergyos.com' || window.location.host === 'workflow.app.synergyos.com') && <div className="form-group text-right mt-4 mb-0">
                    <DefaultButton className='me-2 cancel-button' text={i18n.t('app:back') || 'Back'}/>
                    </div>}

                </form>
            </Modal>
        }
        </div>
    );
}

export default Login;