import './Activity.scss';
import { useEffect, useState } from 'react';
import { Selection, ConstrainMode, DetailsList, DetailsListLayoutMode, IDetailsHeaderProps, IRenderFunction, SelectionMode, Sticky, StickyPositionType, ScrollablePane, ScrollbarVisibility, IColumn } from '@fluentui/react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Link, 
  Persona,
  Spinner
} from '@fluentui/react-components';
import i18n from "i18next";
import Moment from 'moment';
import { history } from '../../_helpers';
import { getActivityDetails } from '../../_helpers/activityFunctions';

function Activity(props:any) {

  const [items, setItems] = useState(props.activity)
  const [alertDialog, setAlertDialog]:any = useState({opened: false})

  var activityMessages = require('../../locales/en/activity-messages.json')

  if (i18n.language === 'es') {
    activityMessages = require('../../locales/es/activity-messages.json')
  }

  const _selection: Selection = new Selection({
    onSelectionChanged: () => {
      let item: any = _selection.getSelection().length === 1 ? _selection.getSelection()[_selection.getSelection().length - 1] : null
      props.setItem(item)
    }
  });

  const _onColumnClick = (ev: React.MouseEvent<HTMLElement>, column: IColumn, items): void => {
    const queryParams = new URLSearchParams(window.location.search)
    queryParams.set('sort',`${column.fieldName},${column.isSortedDescending ? 'ASC' : 'DESC'}`)
    history.push('?'+queryParams.toString())
  }

  const _getUserByID = (id: string, users = []) => {
    if (id && users) {
      return users.find((user:any)=>{return user.id === id}) || {};
    } else return {}
  }

  const columns:any = (items, sort, users) => {
    let sortBy = ''
    let sortOrder = ''
    if (sort) {
      const sortsplit = sort.split(',')
      sortBy = sortsplit[0]
      sortOrder = sortsplit[1]
    }
    return [
      {
        key: 'date',
        name: i18n.t('app:date'),
        fieldName: 'date',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'date' || !sort,
        isSortedDescending: sortBy === 'date' ? sortOrder === 'DESC' : !sort ? 'ASC' : undefined,
        data: 'string',
        isPadded: true,
        onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return (
            <span title={ item.date ? Moment(item.date).format('Y-MMM-D hh:mm') : '' }>{ item.date ? Moment(item.date).format('Y-MMM-D hh:mm') : '' }</span>
          )
        }
      },
      {
        key: 'user',
        name: i18n.t('app:user'),
        fieldName: 'user',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'user',
        isSortedDescending: sortBy === 'user' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          let user:any = _getUserByID(item.userId, users)
          return (<>
            <Persona className='py-1' name={(user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '')} size='extra-small' primaryText='' avatar={{ color: "colorful" }}/>
            <span title={ (user.name || '') + ' ' + (user.surname || '') }>{ (user.name || i18n.t('app:anonymous')) + ' ' + (user.surname || '') }</span>
          </>

          )
        }
      },
      {
        key: 'activity',
        name: i18n.t('app:activity'),
        fieldName: 'activity',
        minWidth: 300,
        isRowHeader: true,
        isResizable: true,
        sortAscendingAriaLabel: 'Sorted A to Z',
        sortDescendingAriaLabel: 'Sorted Z to A',
        isSorted: sortBy === 'activity',
        isSortedDescending: sortBy === 'activity' ? sortOrder === 'DESC' : undefined,
        data: 'string',
        isPadded: true,
        //onColumnClick: (e,c)=>{_onColumnClick(e,c,items)},
        onRender: (item: any) => {
          return <div>{getActivityDetails(activityMessages, item, users)}</div>
        }
      },
      {
        key: 'targets',
        name: i18n.t('app:item'),
        fieldName: 'targets',
        minWidth: 150,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true,
        onRender: (item: any) => {
          if ((item.targets && item.targets[0] && item.targets[0].modelType === 'MESSAGE' )|| (item.targets && item.targets[0] && item.targets[0].modelType === 'REPORT')) {
            let reportName = i18n.t('app:report') + ' #' + (item.source?.version ?? item.targets[0].version)
            return (
              <Link onClick={(e)=>{e.preventDefault();history.push('/company/reports/'+(item.source?.id ?? item.targets[0].id))}} href={'/company/reports/'+(item.source?.id ?? item.targets[0].id)} >{ reportName }</Link>
            )
          } else {
            return <></>
          }
        }
      }
    ]
  }

  useEffect(()=>{
    setItems(props.activity)
  }, [props.activity])

  let queryParams = new URLSearchParams(window.location.search)
  let sort = queryParams.get('sort')

  return (
    <div className='h-100' style={{overflow: 'auto', maxHeight: 'calc(100vh - 136px)', position: 'relative'}}>
      <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
        <DetailsList
          setKey="set" 
          items={items} 
          columns={columns(items, sort, props.users)}
          onRenderDetailsHeader={onRenderDetailsHeader}
          compact={true}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.fixedColumns}
          enterModalSelectionOnTouch={true}
          isHeaderVisible={true}
          constrainMode={ConstrainMode.unconstrained}
          onRenderMissingItem={()=>{props.loadMore(); return undefined}}
        />
      </ScrollablePane>
      { props.isLoading ? <Spinner className='m-auto mt-5' size='tiny'/>
      : items && items.length === 0 ? <div className='text-secondary mt-5 text-center w-100'>{i18n.t('app:noActivityFound')}</div>
      : null }
      <Dialog open={alertDialog?.opened}>
        <DialogSurface>
          <DialogBody>
            <DialogTitle>{alertDialog.title}</DialogTitle>
            <DialogContent>
              {alertDialog.description}
            </DialogContent>
            <DialogActions>
              <DialogTrigger disableButtonEnhancement>
                <Button onClick={()=>{setAlertDialog({opened: false})}} appearance="primary">{i18n.t('app:ok')}</Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </div>
  );
}

function onRenderDetailsHeader(props: any, defaultRender?: IRenderFunction<IDetailsHeaderProps>): JSX.Element {
  return (
    <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced={true}>
      {defaultRender!({
        ...props
      })}
    </Sticky>
  );
}
export default Activity;